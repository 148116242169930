import BusinessIcon from "@material-ui/icons/Business";
import CreateMerchantProduct from "views/MerchantProduct/CreateMerchantProduct";
import DeactivateMerchantProduct from "views/MerchantProduct/DeactivateMerchantProduct";
import DeleteMerchantProduct from "views/MerchantProduct/DeleteMerchantProduct";
import EditMerchantProduct from "views/MerchantProduct/EditMerchantProduct";
import InActiveMerchantProductList from "views/MerchantProduct/InActiveMerchantProductList";
import InDraftMerchantProductList from "views/MerchantProduct/InDraftMerchantProductList";
import PublishedMerchantProductList from "views/MerchantProduct/PublishedMerchantProductList";
import PublishMerchantProduct from "views/MerchantProduct/PublishMerchantProduct";
import ViewMerchantProduct from "views/MerchantProduct/ViewMerchantProduct";

const merchantProduct = () => {
  let views = [
    {
      path: "/MerchantFerryProducts/InDraft",
      name: "In Draft",
      mini: "IN",
      component: InDraftMerchantProductList,
      layout: "/admin"
    },
    {
      path: "/MerchantFerryProducts/Published",
      name: "Published",
      mini: "PU",
      component: PublishedMerchantProductList,
      layout: "/admin"
    },
    {
      path: "/MerchantFerryProducts/InActive",
      name: "In Active",
      mini: "IA",
      component: InActiveMerchantProductList,
      layout: "/admin"
    }
  ];

  return [
    {
      collapse: true,
      name: "M Ferry's Products",
      icon: BusinessIcon,
      state: "merchantFerryProducts",
      views: views
    }
  ];
};

const merchantProductHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/MerchantFerryProducts/Create",
      name: "Create Merchant Ferry's Product",
      component: CreateMerchantProduct,
      layout: "/admin"
    },
    {
      path: "/MerchantFerryProducts/Detail",
      name: "View Merchant Ferry's Product",
      component: ViewMerchantProduct,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantFerryProducts/Publish",
      name: "Publish Merchant Ferry's Product",
      component: PublishMerchantProduct,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantFerryProducts/Delete",
      name: "Delete Merchant Ferry's Product",
      component: DeleteMerchantProduct,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantFerryProducts/Edit",
      name: "Edit Merchant Ferry's Product",
      component: EditMerchantProduct,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantFerryProducts/Deactivate",
      name: "Deactivate Merchant Ferry's Product",
      component: DeactivateMerchantProduct,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  return [
    {
      collapse: true,
      name: "Merchant Ferry's Product Hidden",
      state: "merchantProductHidden",
      hideInSidebar: true,
      views
    }
  ];
};

export { merchantProduct, merchantProductHidden };
