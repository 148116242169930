import AirlineSeatReclineNormalIcon from "@material-ui/icons/AirlineSeatReclineNormal";

import InDraftBookingTypeList from "views/BookingType/InDraftBookingTypeList.js";
import PublishedBookingTypeList from "views/BookingType/PublishedBookingTypeList.js";
import DeactivatedBookingTypeList from "views/BookingType/DeactivatedBookingTypeList.js";
import CreateBookingType from "views/BookingType/CreateBookingType.js";
import EditBookingType from "views/BookingType/EditBookingType.js";
import BookingTypeDetail from "views/BookingType/BookingTypeDetail.js";
import DeleteBookingType from "views/BookingType/DeleteBookingType.js";
import PublishBookingType from "views/BookingType/PublishBookingType.js";
import DeactivateBookingType from "views/BookingType/DeactivateBookingType.js";

import { token } from "functions/Helper";

const bookingType = () => {
  let views = [];
  if (
    token["booking.booking_types.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/BookingTypes/InDraft",
        name: "In Draft",
        mini: "IN",
        component: InDraftBookingTypeList,
        layout: "/admin"
      },
      {
        path: "/BookingTypes/Published",
        name: "Published",
        mini: "PU",
        component: PublishedBookingTypeList,
        layout: "/admin"
      },
      {
        path: "/BookingTypes/Deactivated",
        name: "Deactivated",
        mini: "DE",
        component: DeactivatedBookingTypeList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Booking Types",
        icon: AirlineSeatReclineNormalIcon,
        state: "bookingTypeCollapse",
        views: views
      }
    ];
  return [];
};

const bookingTypeHidden = () => {
  let views = [];
  if (
    token["booking.booking_types.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/BookingType/Detail",
      name: "Booking Type",
      component: BookingTypeDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.booking_types.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/BookingType/Create",
        name: "Create Booking Type",
        component: CreateBookingType,
        layout: "/admin"
      },
      {
        path: "/BookingType/Edit",
        name: "Edit Booking Type",
        component: EditBookingType,
        layout: "/admin",
        hideInSidebar: true,
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/BookingType/Delete",
        name: "Delete Booking Type",
        component: DeleteBookingType,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["booking.booking_types.publish"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/BookingType/Publish",
      name: "Publish Booking Type",
      component: PublishBookingType,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.booking_types.deactivate"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/BookingType/Deactivate",
      name: "Deactivate Booking Type",
      component: DeactivateBookingType,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "BookingType Hidden",
        state: "bookingTypeHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { bookingType, bookingTypeHidden };
