import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { token } from "functions/Helper";

import { voucherApi, masterApi } from "functions/apis";
import { GetVoucherTypeValidityTypes } from "enums/VoucherTypeValidityType.js";
import MerchantDialog from "components/MerchantDialog/MerchantDialog";
import { Input } from "@material-ui/core";
import RichTextComponent from "views/MerchantPromo/SlateFormV2";
import { deserializeHtmlString } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function EditVoucherType(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const { id } = useParams();
  const [nationalityList, setNationalityList] = React.useState([]);
  const [paxTypeList, setPaxTypeList] = React.useState([]);
  const [state, setState] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [merchantName, setMerchantName] = React.useState(null);
  const [isEmptyInformation, setIsEmptyInformation] = React.useState(false);

  useEffect(() => {
    if (
      token["master.countries.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/Countries", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 2
            }
          }
        })
        .then(data => setNationalityList(data.records))
        .catch(error => console.log(error));

    if (
      token["master.pax_types.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/PaxTypes", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1
            }
          }
        })
        .then(data => setPaxTypeList(data.records))
        .catch(error => console.log(error));

    if (
      token["voucher.voucher_types.read"] === "allowed" ||
      token.is_admin === "True"
    )
      voucherApi
        .get("/VoucherTypes/" + id, {
          signal: source.current.signal
        })
        .then(data => {
          data.merchant && setMerchantName(data.merchant.name);
          setState({
            ...data,
            allowedPaxTypeID:
              data.allowedPaxType === null ? null : data.allowedPaxType.id,
            allowedNationalityID:
              data.allowedNationality === null
                ? null
                : data.allowedNationality.id
          });
          if (!data.information) {
            setIsEmptyInformation(true);
          }
        })
        .catch(error => console.log(error));
  }, [id]);

  const handleCodeChanged = e =>
    setState({ ...state, code: e.target.value === "" ? null : e.target.value });

  const handleValidityTypeChanged = e =>
    setState({ ...state, validityType: parseInt(e.target.value) });

  const handleValidityChanged = e =>
    setState({ ...state, validity: parseInt(e.target.value) });

  const handleNameChanged = e =>
    setState({ ...state, name: e.target.value === "" ? null : e.target.value });

  const handleIncludeSurchargeChanged = e =>
    setState({ ...state, includeSurcharge: e.target.checked });

  const handleIncludePDFChanged = e =>
    setState({ ...state, includePDF: e.target.checked });

  const handleIncludeTFChanged = e =>
    setState({ ...state, includeTF: e.target.checked });

  const handleIncludeCFChanged = e =>
    setState({ ...state, includeCF: e.target.checked });

  const handleHasPaxTypeRestrictionChanged = e =>
    setState({
      ...state,
      hasPaxTypeRestriction: e.target.checked,
      allowedPaxTypeID: e.target.checked ? state.allowedPaxTypeID : null
    });

  const handleHasNationalityRestrictionChanged = e =>
    setState({
      ...state,
      hasNationalityRestriction: e.target.checked,
      allowedNationalityID: e.target.checked ? state.allowedNationalityID : null
    });

  const handleHasIdentificationNoRestrictionChanged = e =>
    setState({
      ...state,
      hasIdentificationNoRestriction: e.target.checked,
      maxAllowedIdentificationNoCount: e.target.checked
        ? state.maxAllowedIdentificationNoCount
        : 0
    });

  const handleProhibitToUseOnInterliningTrips = e =>
    setState({
      ...state,
      prohibitToUseOnInterliningTrips: e.target.checked
    });

  const handleAllowedPaxTypeIDChanged = e =>
    setState({ ...state, allowedPaxTypeID: e.target.value });

  const handleAllowedNationalityIDChanged = e =>
    setState({ ...state, allowedNationalityID: e.target.value });

  const handleMaxAllowedIdentificationNoCountChanged = e =>
    setState({
      ...state,
      maxAllowedIdentificationNoCount: parseInt(e.target.value)
    });

  const handleHasMinimumIssuanceQuantityRestrictionChanged = e =>
    setState({
      ...state,
      hasMinimumIssuanceQuantityRestriction: e.target.checked,
      minimumIssuanceQuantity: e.target.checked
        ? state.minimumIssuanceQuantity
        : 0
    });

  const handleHasMaximumIssuanceQuantityRestrictionChanged = e =>
    setState({
      ...state,
      hasMaximumIssuanceQuantityRestriction: e.target.checked,
      maximumIssuanceQuantity: e.target.checked
        ? state.maximumIssuanceQuantity
        : 0
    });

  const handleMinimumIssuanceQuantityChanged = e =>
    setState({
      ...state,
      minimumIssuanceQuantity: parseInt(e.target.value)
    });

  const handleMaximumIssuanceQuantityChanged = e =>
    setState({
      ...state,
      maximumIssuanceQuantity: parseInt(e.target.value)
    });

  const handleHasMerchantChanged = e => {
    setState({
      ...state,
      hasMerchant: e.target.checked
    });
  };

  const handleInformationChanged = e => {
    setState({
      ...state,
      information: e === "" ? null : e
    });
  };

  const handleMerchantIDChange = e => {
    setShowModal(false);
    setMerchantName(e.name);
    setState({
      ...state,
      merchantID: e.id
    });
  };

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    voucherApi
      .put("/VoucherTypes/" + id, state, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/VoucherType/Detail/" + id} />
  ) : (
    state !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>EDIT VOUCHER TYPE</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={handleSubmit}>
                <CustomInput
                  labelText="Code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleCodeChanged,
                    value: state.code
                  }}
                />
                <CustomInput
                  labelText="Name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleNameChanged,
                    value: state.name
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      labelText="Validity"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleValidityChanged,
                        value: state.validity
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selValidityType"
                        className={classes.selectLabel}
                      >
                        Validity Type
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={state.validityType}
                        onChange={handleValidityTypeChanged}
                      >
                        {GetVoucherTypeValidityTypes().map(validityType => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={validityType.key}
                              key={validityType.key}
                            >
                              <b>{validityType.description}</b>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <InputLabel
                      htmlFor="selValidityType"
                      className={classes.selectLabel}
                    >
                      Information
                    </InputLabel>
                  </GridItem>

                  {isEmptyInformation ? (
                    <GridItem xs={12} md={12}>
                      <div
                        style={{
                          borderBottom: "1px solid #d6d6d6",
                          marginTop: 12
                        }}
                      >
                        <RichTextComponent
                          onChange={handleInformationChanged}
                          isCreate={true}
                          value={
                            state.information != null
                              ? deserializeHtmlString(state.information)
                              : null
                          }
                        />
                      </div>
                    </GridItem>
                  ) : (
                    state.information &&
                    state.information != "" && (
                      <GridItem xs={12} md={12}>
                        <div
                          style={{
                            borderBottom: "1px solid #d6d6d6",
                            marginTop: 12
                          }}
                        >
                          <RichTextComponent
                            onChange={handleInformationChanged}
                            value={
                              state.information != null
                                ? deserializeHtmlString(state.information)
                                : null
                            }
                          />
                        </div>
                      </GridItem>
                    )
                  )}
                </GridContainer>
                <div className={classes.checkboxAndRadio}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.includeSurcharge}
                            onChange={handleIncludeSurchargeChanged}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Include Surcharge"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.includePDF}
                            onChange={handleIncludePDFChanged}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Include PDF"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.includeTF}
                            onChange={handleIncludeTFChanged}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Include TF"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.includeCF}
                            onChange={handleIncludeCFChanged}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Include CF"
                      />
                    </GridItem>
                  </GridContainer>
                </div>
                <div className={classes.checkboxAndRadio}>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.hasPaxTypeRestriction}
                              onChange={handleHasPaxTypeRestrictionChanged}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has PaxType Restriction"
                        />
                      </GridItem>
                      {state.hasPaxTypeRestriction && ( // render only if Has PaxType Restriction
                        <GridItem>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="selPaxType"
                              className={classes.selectLabel}
                            >
                              Allowed PaxType
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={
                                state.allowedPaxTypeID === null
                                  ? ""
                                  : state.allowedPaxTypeID
                              }
                              onChange={handleAllowedPaxTypeIDChanged}
                            >
                              {paxTypeList.map(record => {
                                return (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={record.id}
                                    key={record.id}
                                  >
                                    <b>{record.code}</b>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </GridItem>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.hasNationalityRestriction}
                              onChange={handleHasNationalityRestrictionChanged}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has Nationality Restriction"
                        />
                      </GridItem>
                      {state.hasNationalityRestriction && ( // render only if Has PaxType Restriction
                        <GridItem>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="selNationality"
                              className={classes.selectLabel}
                            >
                              Allowed Nationality
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={
                                state.allowedNationalityID === null
                                  ? ""
                                  : state.allowedNationalityID
                              }
                              onChange={handleAllowedNationalityIDChanged}
                            >
                              {nationalityList.map(record => {
                                return (
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={record.id}
                                    key={record.id}
                                  >
                                    <b>{record.nationality}</b>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </GridItem>
                      )}
                    </GridItem>
                  </GridContainer>
                </div>
                <div className={classes.checkboxAndRadio}>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.hasIdentificationNoRestriction}
                              onChange={
                                handleHasIdentificationNoRestrictionChanged
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has IdentificationNo Restriction"
                        />
                      </GridItem>
                      {state.hasIdentificationNoRestriction && ( // render only if Has PaxType Restriction
                        <GridItem>
                          <CustomInput
                            labelText="Max Allowed Identification No Count"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "number",
                              onChange: handleMaxAllowedIdentificationNoCountChanged,
                              value: state.maxAllowedIdentificationNoCount
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.prohibitToUseOnInterliningTrips}
                              onChange={handleProhibitToUseOnInterliningTrips}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Prohibit to Use On Interlining Trips"
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </div>
                <div className={classes.checkboxAndRadio}>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                state.hasMinimumIssuanceQuantityRestriction
                              }
                              onChange={
                                handleHasMinimumIssuanceQuantityRestrictionChanged
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has Minimum Issuance Quantity Restriction"
                        />
                      </GridItem>
                      {state.hasMinimumIssuanceQuantityRestriction && ( // render only if Has Minimum Issuance Quantity Restriction
                        <GridItem>
                          <CustomInput
                            labelText="Minimum Issuance Quantity"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "number",
                              onChange: handleMinimumIssuanceQuantityChanged,
                              value: state.minimumIssuanceQuantity
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                state.hasMaximumIssuanceQuantityRestriction
                              }
                              onChange={
                                handleHasMaximumIssuanceQuantityRestrictionChanged
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has Maximum Issuance Quantity Restriction"
                        />
                      </GridItem>
                      {state.hasMaximumIssuanceQuantityRestriction && ( // render only if Has Maximum Issuance Quantity Restriction
                        <GridItem>
                          <CustomInput
                            labelText="Maximum Issuance Quantity"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              type: "number",
                              onChange: handleMaximumIssuanceQuantityChanged,
                              value: state.maximumIssuanceQuantity
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <GridItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.hasMerchant}
                              onChange={handleHasMerchantChanged}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has Merchant"
                        />
                      </GridItem>
                      {state.hasMerchant && ( // render only if Has Maximum Issuance Quantity Restriction
                        <GridItem>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="selAgent"
                              className={classes.selectLabel}
                            >
                              Merchant
                            </InputLabel>
                            <Input
                              classes={{
                                input: classes.input,
                                disabled: classes.active,
                                root: classes.active,
                                underline: classes.underline
                              }}
                              value={merchantName ?? ""}
                              onClick={() => setShowModal(true)}
                              fullWidth
                            />
                          </FormControl>
                          <MerchantDialog
                            openModal={showModal}
                            setOpenModal={val => setShowModal(val)}
                            onSelect={handleMerchantIDChange}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                  </GridContainer>
                </div>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Save Changes
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

EditVoucherType.propTypes = {
  history: PropTypes.object
};
