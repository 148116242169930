import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import View from "./View";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { bookingApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function Delete(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { id } = useParams();
  const [feePolicyGroup, setFeePolicyGroup] = React.useState(null);
  const [redirect, setRedirect] = React.useState(false);
  useEffect(() => {
    bookingApi
      .get("/FeePolicyGroups/" + id, {
        signal: source.current.signal
      })
      .then(data => setFeePolicyGroup(data))
      .catch(error => console.log(error));
  }, [id]);

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .delete("/FeePolicyGroups/" + id, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to="/admin/FeePolicyGroups/InDraft" />
  ) : (
    feePolicyGroup !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>DELETE FEE POLICY GROUP</h4>
            </CardHeader>
            <CardBody>
              <p>Delete this FeePolicyGroup?</p>
              <form onSubmit={handleSubmit}>
                <Button onClick={() => props.history.goBack()} value="Cancel">
                  Cancel
                </Button>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  DELETE
                </Button>
              </form>
              <View feePolicyGroup={feePolicyGroup} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

Delete.propTypes = {
  history: PropTypes.object
};
