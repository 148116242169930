import { makeStyles } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { bookingApi } from "functions/apis";
import CardIcon from "components/Card/CardIcon";
import Swal from "sweetalert2";

const useStyles = makeStyles(styles);

const ChangeSequenceOnlineBookingHomeContent = props => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);

  const changeSequenceOnlineBookingHomeContentSignal = React.useRef(
    new AbortController()
  );

  React.useEffect(() => {
    bookingApi
      .get("OnlineBookingHomeContents", {
        params: {
          filter: {
            searchString: "",
            isActive: true
          },
          pagination: {
            pageIndex: 0,
            pageSize: 20
          }
        },
        signal: changeSequenceOnlineBookingHomeContentSignal.current.signal
      })
      .then(resp => {
        setData(resp.records);
      })
      .catch(err => {
        console.log(err);
      });

    return () => changeSequenceOnlineBookingHomeContentSignal.current.abort();
  }, []);

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData("text/plain", "");
  };
  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    if (data) {
      const tempData = [...data];
      if (!draggingItem) return;

      const currentIndex = tempData.indexOf(draggingItem);
      const targetIndex = tempData.indexOf(targetItem);

      if (currentIndex !== -1 && targetIndex !== -1) {
        tempData.splice(currentIndex, 1);
        tempData.splice(targetIndex, 0, draggingItem);
        setData(tempData);
      }
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    const sequenceId = data.map(x => {
      return x.id;
    });
    bookingApi
      .post("OnlineBookingHomeContents/UpdateSequence", sequenceId, {
        signal: changeSequenceOnlineBookingHomeContentSignal.current.signal
      })
      .then(() => {
        Swal.fire({
          title: "Success!",
          text: "Success To Change Online Home Content Sequence!",
          icon: "success",
          didClose: () => {
            setRedirect(true);
          }
        });
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please check your input!",
          didClose: () => {
            setDisabled(false);
          }
        });
      })
      .finally(() => {
        setDisabled(false);
      });
  };
  return redirect ? (
    <Redirect to={"/admin/OnlineBookingHomeContentList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              CHANGE ONLINE HOME CONTENT SEQUENCE
            </h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={12}>
              <i>*Drag and drop the list below, then press the submit button</i>
            </GridItem>

            <form onSubmit={handleSubmit}>
              {data &&
                data.length > 0 &&
                data.map((val, key) => (
                  <GridItem xs={5} key={key}>
                    <GridContainer alignItems="center">
                      <p style={{ marginRight: 12, marginLeft: 12 }}>
                        {key + 1}
                      </p>
                      <GridItem xs={11} md={11}>
                        <div
                          draggable="true"
                          onDragStart={e => handleDragStart(e, val)}
                          onDragEnd={handleDragEnd}
                          onDragOver={handleDragOver}
                          onDrop={e => handleDrop(e, val)}
                        >
                          <div
                            style={{
                              borderWidth: 1,
                              borderRadius: 5,
                              borderColor: "black",
                              borderStyle: "solid",
                              marginBottom: 10
                            }}
                          >
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: "bold",
                                padding: 5,
                                paddingTop: 12
                              }}
                            >
                              {val.title}
                            </p>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                ))}
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ChangeSequenceOnlineBookingHomeContent;

ChangeSequenceOnlineBookingHomeContent.propTypes = {
  history: PropTypes.object
};
