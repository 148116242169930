import React, { useEffect, useRef } from "react";
import Moment from "moment";
import Datetime from "react-datetime";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { token } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/CoreApiCheckIn/listFilter.js";
import { masterApi, coreApi } from "functions/apis";
import { tripDateFormat } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function ListFilter(props) {
  const source = useRef(new AbortController());
  useEffect(() => {
    if (token["master.sectors.read"] === "allowed" || token.is_admin === "True")
      masterApi
        .get("/Sectors", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1
            }
          }
        })
        .then(data => setSectorList(data.records))
        .catch(error => console.log(error));

    return () => source.current.abort();
  }, []);
  const { filter, setFilter } = props;
  const [sectorList, setSectorList] = React.useState([]);
  const [routeList, setRouteList] = React.useState([]);
  const [timeList, setTimeList] = React.useState([]);

  useEffect(() => {
    if (token["master.routes.read"] === "allowed" || token.is_admin === "True")
      masterApi
        .get("/Routes", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1,
              sectorID: filter.sectorID
            }
          }
        })
        .then(data => setRouteList(data.records))
        .catch(error => console.log(error));
  }, [filter.sectorID]);

  useEffect(() => {
    if (filter.routeID !== null && filter.date !== null) {
      let selectedRoute = routeList.find(r => r.id === filter.routeID);
      let ports = selectedRoute.code.split(" - ");
      let embarkationPort = ports[0];
      let destinationPort = ports[1];
      if (embarkationPort === "TMFT") embarkationPort = "TMF";
      if (destinationPort === "TMFT") destinationPort = "TMF";
      coreApi
        .get("/Trips", {
          signal: source.current.signal,
          params: {
            embarkation: embarkationPort,
            destination: destinationPort,
            tripDate: Moment(filter.date).format("yyyyMMDD"),
            status: "active"
          }
        })
        .then(data => setTimeList(data.data))
        .catch(error => {
          console.log(error);
          setTimeList([]);
        });
    } else {
      setTimeList([]);
    }
  }, [filter.sectorID, filter.date]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const handleSectorIDChanged = e =>
    setFilter({
      ...filter,
      sectorID: e.target.value,
      routeID: null,
      time: null
    });

  const handleRouteIDChanged = e =>
    setFilter({ ...filter, routeID: e.target.value, time: null });

  const handleDateChanged = e =>
    setFilter({ ...filter, date: e === "" ? null : e, time: null });

  const handleTimeChanged = e => setFilter({ ...filter, time: e.target.value });

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} md={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="selSector" className={classes.selectLabel}>
            Sector
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={filter.sectorID === null ? "" : filter.sectorID}
            onChange={handleSectorIDChanged}
          >
            {sectorList.map(record => {
              return (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={record.id}
                  key={record.id}
                >
                  <b>{record.code}</b>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} md={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="selRoute" className={classes.selectLabel}>
            Route
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={filter.routeID === null ? "" : filter.routeID}
            onChange={handleRouteIDChanged}
          >
            {routeList.map(record => {
              return (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={record.id}
                  key={record.id}
                >
                  <b>{record.code}</b>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} md={6}>
        <InputLabel className={classes.selectLabel}>Departure Date</InputLabel>
        <FormControl fullWidth>
          <Datetime
            value={filter.date}
            onChange={handleDateChanged}
            dateFormat={tripDateFormat}
            timeFormat={false}
            utc={true}
            closeOnSelect={true}
            inputProps={{
              placeholder: "Date"
            }}
          />
        </FormControl>
      </GridItem>
      <GridItem xs={12} md={6}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="selTime" className={classes.selectLabel}>
            Time
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={filter.time === null ? "" : filter.time}
            onChange={handleTimeChanged}
          >
            {timeList.map(record => {
              return (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={record.tripTime}
                  key={record.tripTime}
                >
                  <p>
                    <b>{`${record.tripTime.substring(
                      0,
                      2
                    )}:${record.tripTime.substring(2, 4)}`}</b>{" "}
                    {record.timezoneLabel}
                  </p>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} md={3}>
        <CustomInput
          labelText="Search"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: handleSearchStringChanged,
            value: filter.searchString === null ? "" : filter.searchString
          }}
        />
      </GridItem>
    </GridContainer>
  );
}

ListFilter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func
};
