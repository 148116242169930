import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import ReactTable from "react-table";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { travelApi } from "functions/apis";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { Check } from "@material-ui/icons";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles(styles);
const bookingPaxInfoType = [
  "By Quantity Only",
  "ID Card",
  "Passport",
  "No And Name"
];
export default function ViewMerchantProduct(props) {
  const viewMerchantPromoSignal = useRef(new AbortController());

  const [body, setBody] = React.useState({});

  const { id } = useParams();

  const [merchantData, setMerchantData] = React.useState({
    id: "",
    name: ""
  });
  const [controlGroupData, setControlGroupData] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [variant, setVariant] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const getDetailVariant = id => {
    travelApi
      .get(`products/${id}/variants`, {
        signal: viewMerchantPromoSignal.current.signal,
        params: {
          filter: {},
          pagination: pagination
        }
      })
      .then(data => {
        setVariant(data.records);
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getDataMerchant = () => {
    travelApi
      .get("products/ferries/" + id, {
        signal: viewMerchantPromoSignal.current.signal
      })
      .then(data => {
        setBody(data);
        const dateOpenTemp = new Date();
        dateOpenTemp.setHours(parseInt(data.gateOpen.substring(0, 2)));
        dateOpenTemp.setMinutes(parseInt(data.gateOpen.substring(2, 4)));
        dateOpenTemp.setSeconds(0);
        setMerchantData({
          ...merchantData,
          id: data.merchant?.id,
          name: data.merchant?.name,
          gateOpen: dateOpenTemp
        });

        if (data.hasAgeVariant) {
          getDetailVariant(data.id);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDataMerchant();
    return () => viewMerchantPromoSignal.current.abort();
  }, []);

  useEffect(() => {
    if (body && body.controlGroupID) {
      travelApi
        .get("inventoryControlGroups/" + body.controlGroupID, {
          signal: viewMerchantPromoSignal.current.signal
        })
        .then(data => {
          setControlGroupData({
            id: data.id,
            name: data.name
          });
        })
        .catch(err => {
          toast.error(JSON.stringify(err.error?.response.data.title));
        });
    }
  }, [body]);

  useEffect(() => {
    if (body.hasAgeVariant) {
      getDetailVariant();
    }
  }, [pagination]);

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4
              className={classes.cardIconTitle}
            >{`VIEW MERCHANT FERRY'S PRODUCTS`}</h4>
          </CardHeader>
          <CardBody>
            <Button
              color="rose"
              onClick={() => props.history.goBack()}
              value="Cancel"
            >
              BACK
            </Button>
            <form>
              {body && controlGroupData && (
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12}>
                    <CustomInput
                      labelText="Merchant"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: merchantData === null ? "" : merchantData.name
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="Code"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: body.code === null ? "" : body.code
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="Name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: body.name === null ? "" : body.name
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Booking Pax Info Type"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:
                          body.bookingPaxInfoType === null
                            ? ""
                            : bookingPaxInfoType[body.bookingPaxInfoType]
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="Route"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: !body ? "" : body.route?.name
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      labelText="SeatClass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: body.seatClass === null ? "" : body.seatClass
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="Gate Open"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:
                          body.gateOpen === null
                            ? ""
                            : body.gateOpen.slice(0, 2) +
                              ":" +
                              body.gateOpen.slice(2, body.gateOpen.length)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="Gate Close"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:
                          body.gateClose === null
                            ? ""
                            : body.gateClose.slice(0, 2) +
                              ":" +
                              body.gateClose.slice(2, body.gateClose.length)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="Time"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:
                          body.time === null
                            ? ""
                            : body.time.slice(0, 2) +
                              ":" +
                              body.time.slice(2, body.time.length)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} md={6}>
                    <CustomInput
                      labelText="Estimate Time Of Arrival"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:
                          body.estimateTimeOfArrival === null
                            ? ""
                            : body.estimateTimeOfArrival.slice(0, 2) +
                              ":" +
                              body.estimateTimeOfArrival.slice(
                                2,
                                body.estimateTimeOfArrival.length
                              )
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="Remarks"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: body.remarks === null ? "" : body.remarks
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={12}>
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox
                          disabled
                          checked={body.hasAgeVariant}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has Age Variant"
                    />
                  </GridItem>
                  {body.hasAgeVariant && (
                    <GridItem xs={12}>
                      <ReactTable
                        manual
                        pages={pages}
                        page={pagination.pageIndex - 1}
                        pageSize={pagination.pageSize}
                        onPageChange={page =>
                          setPagination({
                            pageIndex: page + 1,
                            pageSize: pagination.pageSize
                          })
                        }
                        onPageSizeChange={(pageSize, page) => {
                          setPagination({
                            pageSize: pageSize,
                            pageIndex: page + 1
                          });
                        }}
                        loading={loading}
                        data={variant}
                        filterable={false}
                        columns={[
                          {
                            Header: "Name",
                            accessor: "name"
                          },
                          {
                            Header: "Age Range From",
                            accessor: "ageRangeFrom"
                          },
                          {
                            Header: "Age Range To",
                            accessor: "ageRangeTo"
                          }
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} md={12}>
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox
                          disabled
                          checked={body.hasNoInventory}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has No Inventory"
                    />
                  </GridItem>
                  {body.hasAgeRestriction && (
                    <GridItem xs={12} md={12}>
                      <CustomInput
                        labelText="Minimum Allowed Age"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          onChange: () => {},
                          value:
                            body.minimumAllowedAge === null
                              ? ""
                              : body.minimumAllowedAge
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} md={12}>
                    <FormControlLabel
                      disabled
                      control={
                        <Checkbox
                          checked={body.hasAgeRestriction}
                          onChange={() => {}}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Has Age Restriction"
                    />
                  </GridItem>
                </GridContainer>
              )}
            </form>
          </CardBody>
        </Card>
      </GridItem>
      <ToastContainer theme="light" />
    </GridContainer>
  );
}

ViewMerchantProduct.propTypes = {
  history: PropTypes.object
};
