import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { useParams } from "react-router-dom";
import { travelApi } from "functions/apis";
import { formatDate } from "functions/Helper";

const useStyles = makeStyles(styles);

const ViewMerchantInventoryGeneration = props => {
  const classes = useStyles();

  const [input, setInput] = useState(null);

  const { id } = useParams();

  const viewMerchantInventoryGenerationSignal = useRef(new AbortController());

  useEffect(() => {
    return () => viewMerchantInventoryGenerationSignal.current.abort();
  }, []);

  useEffect(() => {
    if (id) {
      travelApi
        .get("inventoryGenerations/" + id, {
          signal: viewMerchantInventoryGenerationSignal.current.signal
        })
        .then(resp => {
          setInput(resp);
        });
    }
  }, [id]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              VIEW MERCHANT INVENTORY GENERATION
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Control Group"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: input ? input.controlGroup.name : "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Start Date"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: input ? formatDate(input.startDate) : "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Start Date"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: input ? formatDate(input.untilDate) : "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Quantity"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: input ? input.quantity : "",
                      disabled: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewMerchantInventoryGeneration;

ViewMerchantInventoryGeneration.propTypes = {
  history: PropTypes.object
};
