export default function PublicationStatus(value) {
  switch (value) {
    case 0:
      return "In Draft";
    case 1:
      return "Published";
    case 2:
      return "In Active";
    default:
      return value;
  }
}
