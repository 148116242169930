import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";

import ApprovedList from "views/AgentBookingCancellation/ApprovedList.js";
import SubmittedList from "views/AgentBookingCancellation/SubmittedList.js";
import RejectedList from "views/AgentBookingCancellation/RejectedList.js";
import Detail from "views/AgentBookingCancellation/Detail.js";
import Approve from "views/AgentBookingCancellation/Approve.js";
import Reject from "views/AgentBookingCancellation/Reject.js";
import Retry from "views/AgentBookingCancellation/Retry.js";
import { token } from "functions/Helper";

const agentBookingCancellation = () => {
  let views = [];

  if (
    token["booking.agent_booking_cancellations.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/AgentBookingCancellations/Submitted",
        name: "Submitted",
        mini: "SU",
        component: SubmittedList,
        layout: "/admin"
      },
      {
        path: "/AgentBookingCancellations/Approved",
        name: "Approved",
        mini: "AP",
        component: ApprovedList,
        layout: "/admin"
      },
      {
        path: "/AgentBookingCancellations/Rejected",
        name: "Rejected",
        mini: "RJ",
        component: RejectedList,
        layout: "/admin"
      }
    ]);

  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Agent Booking Cancellations",
        icon: AssignmentLateIcon,
        state: "agentBookingCancellationCollapse",
        views: views
      }
    ];
  return [];
};
const agentBookingCancellationHidden = () => {
  let views = [];
  if (
    token["booking.agent_booking_cancellations.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/AgentBookingCancellation/Detail",
      name: "Agent Booking Cancellation Detail",
      component: Detail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["booking.agent_booking_cancellations.approve"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/AgentBookingCancellation/Approve",
        name: "Approve",
        component: Approve,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/AgentBookingCancellation/Retry",
        name: "Retry",
        component: Retry,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["booking.agent_booking_cancellations.reject"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/AgentBookingCancellation/Reject",
        name: "Reject",
        component: Reject,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Agent Booking Cancellation Hidden",
        state: "agentBookingCancellationHiddenCollapse",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};
export { agentBookingCancellation, agentBookingCancellationHidden };
