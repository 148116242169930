import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useParams, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { travelApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function DeactivateMerchant(props) {
  const source = useRef(new AbortController());

  const { id } = useParams();

  const [state, setState] = React.useState(null);
  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  useEffect(() => {
    travelApi
      .get("Merchants/" + id, {
        signal: source.current.signal
      })
      .then(res => setState(res))
      .catch(error => console.log(error));

    return () => {
      source.current.abort();
    };
  }, []);

  const classes = useStyles();

  const handleDeactivate = () => {
    setDisabled(true);
    travelApi
      .post(
        "/Merchants/Deactivate",
        {
          id: id
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to="/admin/Merchants/InActive" />
  ) : (
    state !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>DEACTIVATE MERCHANT</h4>
            </CardHeader>
            <CardBody>
              <i>
                *Are you sure want to deactivate this merchant? It cannot be
                reverted once it is deactivated.
              </i>
              <br />
              <Button onClick={() => props.history.goBack()} value="Cancel">
                Cancel
              </Button>
              <CustomInput
                labelText="Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: state.code === null ? "" : state.code
                }}
              />
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: state.name === null ? "" : state.name
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: state.email === null ? "" : state.email
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Business Category"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.businessCategory === null
                          ? ""
                          : state.businessCategory.name
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Location"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: state.country === null ? "" : state.country.name
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.contactNumber === null ? "" : state.contactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Company Address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.companyAddress === null
                          ? ""
                          : state.companyAddress
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Contact Number (Company)"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.companyContactNumber === null
                          ? ""
                          : state.companyContactNumber
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Currency"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        state.settlementCurrency === null
                          ? ""
                          : state.settlementCurrency.code
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
                onClick={() => {
                  handleDeactivate();
                }}
              >
                DEACTIVATE
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

DeactivateMerchant.propTypes = {
  history: PropTypes.object
};
