import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import View from "views/CoreApiCheckIn/View";

import { bookingApi } from "functions/apis";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { formatDateTime } from "functions/Helper";
import { GetCoreApiCheckInProcessTypeDescription } from "enums/CoreApiCheckInProcessType";

const useStyles = makeStyles(styles);
export default function Detail(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  let { id } = useParams();

  const [coreApiCheckIn, setCoreApiCheckIn] = React.useState(null);
  const [processLogs, setProcessLogs] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    bookingApi
      .get("/CoreApiCheckIns/" + id, {
        signal: source.current.signal
      })
      .then(data => setCoreApiCheckIn(data))
      .catch(error => console.log(error));

    bookingApi
      .get("/CoreApiCheckIns/" + id + "/ProcessLogs", {
        signal: source.current.signal,
        params: {
          pagination: pagination
        }
      })
      .then(data => {
        setProcessLogs(data.records);
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [id, pagination]);

  const classes = useStyles();
  return (
    coreApiCheckIn !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>CHECK IN</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <View coreApiCheckIn={coreApiCheckIn} />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>PROCESS LOGS</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                manual
                pages={pages}
                page={pagination.pageIndex - 1}
                pageSize={pagination.pageSize}
                onPageChange={page =>
                  setPagination({
                    pageIndex: page + 1,
                    pageSize: pagination.pageSize
                  })
                }
                onPageSizeChange={(pageSize, page) => {
                  setPagination({ pageSize: pageSize, pageIndex: page + 1 });
                }}
                loading={loading}
                data={processLogs}
                filterable={false}
                columns={[
                  {
                    id: "type",
                    Header: "Process Type",
                    accessor: d =>
                      GetCoreApiCheckInProcessTypeDescription(d.type)
                  },
                  {
                    id: "createdAt",
                    Header: "Created At",
                    accessor: d => formatDateTime(new Date(d.createdAt + "Z"))
                  },
                  {
                    Header: "Log",
                    accessor: "log"
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

Detail.propTypes = {
  history: PropTypes.object
};
