import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { useParams } from "react-router-dom";
import { travelApi } from "functions/apis";
import { formatDate } from "functions/Helper";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles(styles);

const DeleteMerchantInventoryGeneration = props => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);

  const [input, setInput] = useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const { id } = useParams();

  const deleteMerchantInventoryGenerationSignal = useRef(new AbortController());

  useEffect(() => {
    return () => deleteMerchantInventoryGenerationSignal.current.abort();
  }, []);

  useEffect(() => {
    if (id) {
      travelApi
        .get("inventoryGenerations/" + id, {
          signal: deleteMerchantInventoryGenerationSignal.current.signal
        })
        .then(resp => {
          setInput(resp);
        });
    }
  }, [id]);

  const deleteMerchantInventoryGeneration = () => {
    setDisabled(true);

    const resolveFromTravelApi = new Promise((resolve, reject) => {
      travelApi
        .delete("inventoryGenerations/" + id, {
          signal: deleteMerchantInventoryGenerationSignal.current.signal
        })
        .then(() => {
          resolve({ isSuccess: true });
        })
        .catch(error => {
          reject({ isSuccess: true, error });
        });
    });

    toast
      .promise(resolveFromTravelApi, {
        pending: "...Deleting data",
        success: {
          render({ data }) {
            if (data.isSuccess) {
              return `Delete data success`;
            } else {
              return `Error : ${JSON.stringify(
                data.error?.response.data.title
              )}`;
            }
          }
        },
        error: {
          render({ data }) {
            return `Error : ${JSON.stringify(data.error?.response.data.title)}`;
          }
        }
      })
      .then(resp => {
        if (resp && resp.isSuccess) {
          setRedirect(true);
        }
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/MerchantsProductGenerations/Pending"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              DELETE MERCHANT INVENTORY GENERATION
            </h4>
          </CardHeader>
          <CardBody>
            <i>*Are you sure to delete this merchant inventory generation?</i>
            <br />
            <Button onClick={() => props.history.goBack()} value="Back">
              Cancel
            </Button>
            <Button
              disabled={disabled}
              color="rose"
              onClick={() => deleteMerchantInventoryGeneration()}
              value="Cancel"
            >
              Delete
            </Button>
            <form>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Control Group"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: input ? input.controlGroup.name : "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Start Date"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: input ? formatDate(input.startDate) : "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Start Date"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: input ? formatDate(input.untilDate) : "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Quantity"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: input ? input.quantity : "",
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
      <ToastContainer theme="light" />
    </GridContainer>
  );
};

export default DeleteMerchantInventoryGeneration;

DeleteMerchantInventoryGeneration.propTypes = {
  history: PropTypes.object
};
