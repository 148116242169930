import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { GetTopUpStatusDescription } from "enums/TopUpStatus.js";

import { creditMonitoringApi } from "functions/apis";
import { formatDateTime, token } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function TopUpDetail(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { id } = useParams();
  const [topUp, setTopUp] = React.useState(null);

  useEffect(() => {
    creditMonitoringApi
      .get("/TopUps/" + id, {
        signal: source.current.signal
      })
      .then(res => setTopUp(res))
      .catch(error => console.log(error));
  }, [id]);

  const classes = useStyles();
  return (
    topUp !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>TOP UP</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <p>
                <i>
                  *Top Up status is &apos;
                  <b>{GetTopUpStatusDescription(topUp.status)}</b>&apos;.
                </i>
              </p>
              {topUp.status === 0 ? (
                <>
                  {(token["creditmonitoring.top_ups.approve"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/TopUps/Approve/" + topUp.id}>
                      <Button color="rose" value="Approve">
                        APPROVE
                      </Button>
                    </Link>
                  )}
                  {(token["creditmonitoring.top_ups.reject"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/TopUps/Reject/" + topUp.id}>
                      <Button color="rose" value="Reject">
                        REJECT
                      </Button>
                    </Link>
                  )}
                </>
              ) : (
                <p>
                  <i>
                    *Only &apos;Pending&apos; Top Up can be approved or
                    rejected.
                  </i>
                </p>
              )}
              <CustomInput
                labelText="No"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: topUp.no
                }}
              />
              <CustomInput
                labelText="Agent"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: topUp.agent.name
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Billing Currency"
                    disabled
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        topUp.agent.billingCurrency === null
                          ? ""
                          : topUp.agent.billingCurrency.code
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Balance"
                    id="numBalance"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: topUp.balance.toLocaleString()
                    }}
                  />
                </GridItem>
              </GridContainer>
              <CustomInput
                labelText="Note"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: topUp.note === null ? "" : topUp.note
                }}
              />
              {topUp.status === 0 && (
                <CustomInput
                  labelText="Created At"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: formatDateTime(new Date(topUp.createdAt + "Z"))
                  }}
                />
              )}
              {topUp.status === 1 && (
                <CustomInput
                  labelText="Approved At"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: formatDateTime(new Date(topUp.approvedAt + "Z"))
                  }}
                />
              )}
              {topUp.status === 2 && (
                <>
                  <CustomInput
                    labelText="Rejected At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(new Date(topUp.rejectedAt + "Z"))
                    }}
                  />
                  <CustomInput
                    labelText="Reject Note"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: topUp.rejectNote === null ? "" : topUp.rejectNote
                    }}
                  />
                </>
              )}
              {topUp.hasPaymentReceiptFile ? (
                <Button
                  round
                  color="rose"
                  className="like"
                  onClick={() =>
                    axios
                      .get(
                        process.env.REACT_APP_CREDIT_MONITORING_API_URL +
                          "/TopUps/PaymentReceipt/" +
                          id,
                        {
                          responseType: "arraybuffer",
                          headers: {
                            Authorization: localStorage.getItem("Authorization")
                          }
                        }
                      )
                      .then(response => {
                        let filename = response.request
                          .getResponseHeader("Content-Disposition")
                          .split(";")[2]
                          .replace("filename*=UTF-8''", "");
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                      })
                  }
                >
                  Download Attachment
                </Button>
              ) : (
                <div>No File Attached</div>
              )}
              {topUp.status === 1 && (
                <Button
                  round
                  color="rose"
                  className="like"
                  onClick={() =>
                    axios
                      .get(
                        process.env.REACT_APP_CREDIT_MONITORING_API_URL +
                          "/TopUps/PaymentNoReceipt/" +
                          id,
                        {
                          responseType: "arraybuffer",
                          headers: {
                            Authorization: localStorage.getItem("Authorization")
                          }
                        }
                      )
                      .then(response => {
                        let filename = response.request
                          .getResponseHeader("Content-Disposition")
                          .split(";")[2]
                          .replace("filename*=UTF-8''", "");
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                      })
                  }
                >
                  Download Payment Receipt
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

TopUpDetail.propTypes = {
  history: PropTypes.object
};
