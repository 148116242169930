import React, { useEffect } from "react";
import { MailOutline } from "@material-ui/icons";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import CardBody from "components/Card/CardBody";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  makeStyles
} from "@material-ui/core";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import Datetime from "react-datetime";
import MerchantDialog from "components/MerchantDialog/MerchantDialog";
import { bookingApi } from "functions/apis";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import RichTextComponent from "./SlateFormV2";
import { deserializeHtmlString } from "functions/Helper";

const useStyles = makeStyles(styles);

const emptyBody = {
  categoryID: "",
  merchantID: "",
  effectiveDate: null,
  expiryDate: null,
  type: null,
  generationEvent: null,
  title: "",
  description: "",
  termsAndConditions: "",
  usagePerUser: 0,
  limit: 0,
  claimableLimit: 0,
  banner: "",
  usagePeriodCalculationType: null,
  usageEffectiveDate: null,
  usageExpiryDate: null,
  usageValidityType: null,
  usageValidity: 0
};

const merchantPromoType = ["Claimable", "System Generated"];

const merchantPromoValidityType = ["Month", "Week", "Day"];

const merchantPromoGenerationEvent = ["New User", "First Booking", "Birthday"];

const usagePeriodCalculation = ["By ClaimDate", "Fixed"];

export default function EditMerchantPromo(props) {
  const classes = useStyles();
  const [body, setBody] = React.useState({
    data: emptyBody
  });
  let { id } = useParams();
  const source = React.useRef(new AbortController());
  const [showModal, setShowModal] = React.useState(false);
  const [merchantName, setMerchantName] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [merchantID, setMerchantID] = React.useState("");
  const [isEmptyTerms, setIsEmptyTerms] = React.useState(false);

  useEffect(() => {
    bookingApi
      .get("/merchantPromoCategories", {
        signal: source.current.signal,
        params: {
          filter: {
            searchString: null
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCategoryList(data.records))
      .catch(error => console.log(error));

    bookingApi
      .get("/MerchantPromos/" + id, {
        signal: source.current.signal
      })
      .then(data => {
        const str = data.termsAndConditions
          ? data.termsAndConditions.replace("\\", "")
          : "";
        // console.log(str);
        setBody({
          data: {
            banner: data.banner,
            categoryID: data.category?.id,
            description: data.description,
            effectiveDate: data.effectiveDate
              ? new Date(data.effectiveDate + "Z")
              : null,
            expiryDate: data.expiryDate
              ? new Date(data.expiryDate + "Z")
              : null,
            limit: data.limit,
            merchantID: data.merchant.id,
            title: data.title,
            usagePerUser: data.usagePerUser,
            usageValidityType: data.usageValidityType,
            usageValidity: data.usageValidity,
            type: data.type,
            generationEvent: data.generationEvent,
            termsAndConditions: str,
            claimableLimit: data.claimableLimit,
            usagePeriodCalculationType: data.usagePeriodCalculationType,
            usageEffectiveDate: data.usageEffectiveDate
              ? new Date(data.usageEffectiveDate + "Z")
              : null,
            usageExpiryDate: data.usageExpiryDate
              ? new Date(data.usageExpiryDate + "Z")
              : null
          }
        });

        if (!data.termsAndConditions) {
          setIsEmptyTerms(true);
        }

        if (data.merchant) {
          setMerchantName(data.merchant.name);
          setMerchantID(data.merchant.id);
        }
      })
      .catch(error => console.log(error));
    return () => source.current.abort();
  }, []);

  const [categoryList, setCategoryList] = React.useState([]);

  const handleTitleChanged = e => {
    setBody({
      ...body,
      data: {
        ...body.data,
        title: e.target.value === "" ? null : e.target.value
      }
    });
  };

  const handleDescriptionChanged = e => {
    setBody({
      ...body,
      data: {
        ...body.data,
        description: e.target.value === "" ? null : e.target.value
      }
    });
  };

  const handleBannerChanged = e => {
    setBody({
      ...body,
      data: {
        ...body.data,
        banner: e.target.value === "" ? null : e.target.value
      }
    });
  };

  const handleLimitChanged = e => {
    setBody({
      ...body,
      data: {
        ...body.data,
        limit: e.target.value === "" ? null : e.target.value
      }
    });
  };

  const handleClaimableLimitChanged = e => {
    setBody({
      ...body,
      data: {
        ...body.data,
        claimableLimit: e.target.value === "" ? null : e.target.value
      }
    });
  };

  const handleUsagePerUserChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        usagePerUser: e.target.value === "" ? null : e.target.value
      }
    });

  const handleEffectiveDateChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        effectiveDate: e === "" ? null : e
      }
    });

  const handleExpiryDateChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        expiryDate: e === "" ? null : e
      }
    });

  const handleUsageEffectiveDateChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        usageEffectiveDate: e === "" ? null : e
      }
    });

  const handleUsageExpiryDateChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        usageExpiryDate: e === "" ? null : e
      }
    });

  const handleCategoryIDChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        categoryID: e.target.value === "" ? null : e.target.value
      }
    });

  const handleTermsAndConditionsChanged = e => {
    setBody({
      ...body,
      data: {
        ...body.data,
        termsAndConditions: e === "" ? null : e
      }
    });
  };

  const handleUsagePeriodCalculationTypeChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        usagePeriodCalculationType:
          e.target.value === "" ? null : e.target.value
      }
    });

  const handleValidityTypeChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        usageValidityType: e.target.value === "" ? null : e.target.value
      }
    });

  const handleUsageValidityChanged = e => {
    setBody({
      ...body,
      data: {
        ...body.data,
        usageValidity: e.target.value === "" ? null : e.target.value
      }
    });
  };

  const handleTypeChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        type: e.target.value === "" ? null : e.target.value
      }
    });

  const handleGenerationEventChanged = e =>
    setBody({
      ...body,
      data: {
        ...body.data,
        generationEvent: e.target.value === "" ? null : e.target.value
      }
    });

  const handleMerchantIDChange = e => {
    setMerchantID(e.id);
    setMerchantName(e.name);
    setShowModal(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);

    bookingApi
      .put(
        "MerchantPromos/" + id,
        {
          ...body.data,
          merchantID: merchantID
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => {
        setRedirect(true);
      })
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/MerchantPromo/List"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Edit Merchant Promo</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Title"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleTitleChanged,
                  value: body.data.title === null ? "" : body.data.title
                }}
              />
              <CustomInput
                labelText="Description"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleDescriptionChanged,
                  value:
                    body.data.description === null ? "" : body.data.description
                }}
              />
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCategory"
                  className={classes.selectLabel}
                >
                  Category
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={
                    body.data.categoryID === null ? "" : body.data.categoryID
                  }
                  onChange={handleCategoryIDChanged}
                >
                  {categoryList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        <b>{record.name}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selAgent"
                      className={classes.selectLabel}
                    >
                      Merchant
                    </InputLabel>
                    <Input
                      classes={{
                        input: classes.input,
                        disabled: classes.active,
                        root: classes.active,
                        underline: classes.underline
                      }}
                      value={merchantName ?? ""}
                      onClick={() => setShowModal(true)}
                      fullWidth
                    />
                  </FormControl>
                  <MerchantDialog
                    openModal={showModal}
                    setOpenModal={val => setShowModal(val)}
                    onSelect={handleMerchantIDChange}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <p>Terms And Conditions</p>
                  </FormControl>
                </GridItem>
              </GridContainer>
              {isEmptyTerms ? (
                <GridItem xs={12} md={12}>
                  <div style={{ borderBottom: "1px solid #d6d6d6" }}>
                    <RichTextComponent
                      onChange={handleTermsAndConditionsChanged}
                      isCreate={true}
                      value={
                        body.data.termsAndConditions != null
                          ? deserializeHtmlString(body.data.termsAndConditions)
                          : null
                      }
                    />
                  </div>
                </GridItem>
              ) : (
                body.data.termsAndConditions &&
                body.data.termsAndConditions != "" && (
                  <GridItem xs={12} md={12}>
                    <div style={{ borderBottom: "1px solid #d6d6d6" }}>
                      <RichTextComponent
                        onChange={handleTermsAndConditionsChanged}
                        value={
                          body.data.termsAndConditions != null
                            ? deserializeHtmlString(
                                body.data.termsAndConditions
                              )
                            : null
                        }
                      />
                    </div>
                  </GridItem>
                )
              )}

              <CustomInput
                labelText="Banner"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleBannerChanged,
                  value: body.data.banner === null ? "" : body.data.banner
                }}
              />
              <CustomInput
                labelText="Limit"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: handleClaimableLimitChanged,
                  value:
                    body.data.claimableLimit === null
                      ? ""
                      : body.data.claimableLimit
                }}
              />
              <CustomInput
                labelText="Claimable Limit"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: handleLimitChanged,
                  value: body.data.limit === null ? "" : body.data.limit
                }}
              />
              <CustomInput
                labelText="Usage per user"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: handleUsagePerUserChanged,
                  value:
                    body.data.usagePerUser === null
                      ? ""
                      : body.data.usagePerUser
                }}
              />
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={body.data.effectiveDate}
                      onChange={handleEffectiveDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Effective Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={body.data.expiryDate}
                      onChange={handleExpiryDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Expiry Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selCategory"
                      className={classes.selectLabel}
                    >
                      Usage Period Calculation Type
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={
                        body.data.usagePeriodCalculationType === null
                          ? ""
                          : body.data.usagePeriodCalculationType
                      }
                      onChange={handleUsagePeriodCalculationTypeChanged}
                    >
                      {usagePeriodCalculation.map((record, key) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={key}
                            key={key}
                          >
                            <b>{record}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              {typeof body.data?.usagePeriodCalculationType === "number" &&
              body.data.usagePeriodCalculationType == 0 ? (
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selCategory"
                        className={classes.selectLabel}
                      >
                        Validity Type
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={
                          body.data.usageValidityType === null
                            ? ""
                            : body.data.usageValidityType
                        }
                        onChange={handleValidityTypeChanged}
                      >
                        {merchantPromoValidityType.map((record, key) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={key}
                              key={key}
                            >
                              <b>{record}</b>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      labelText="Validity"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleUsageValidityChanged,
                        value:
                          body.data.usageValidity === null
                            ? ""
                            : body.data.usageValidity
                      }}
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                body.data.usagePeriodCalculationType === 1 && (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <Datetime
                          value={body.data.usageEffectiveDate}
                          onChange={handleUsageEffectiveDateChanged}
                          timeFormat={true}
                          closeOnSelect={true}
                          inputProps={{
                            placeholder: "Usage Effective Date"
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <Datetime
                          value={body.data.usageExpiryDate}
                          onChange={handleUsageExpiryDateChanged}
                          timeFormat={true}
                          closeOnSelect={true}
                          inputProps={{
                            placeholder: "Usage Expiry Date"
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                )
              )}

              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCategory"
                  className={classes.selectLabel}
                >
                  Type
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={body.data.type === null ? "" : body.data.type}
                  onChange={handleTypeChanged}
                >
                  {merchantPromoType.map((record, key) => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={key}
                        key={key}
                      >
                        <b>{record}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {body.data.type === 1 && (
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCategory"
                    className={classes.selectLabel}
                  >
                    Generation Event
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={
                      body.data.generationEvent === null
                        ? ""
                        : body.data.generationEvent
                    }
                    onChange={handleGenerationEventChanged}
                  >
                    {merchantPromoGenerationEvent.map((record, key) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={key}
                          key={key}
                        >
                          <b>{record}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

EditMerchantPromo.propTypes = {
  history: PropTypes.object
};
