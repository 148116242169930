import LoyaltyIcon from "@material-ui/icons/Loyalty";
import CreateOnlineVoucherType from "views/OnlineVoucherType/CreateOnlineVoucherType";
import DeleteOnlineVoucherType from "views/OnlineVoucherType/DeleteOnlineVoucherType";
import EditOnlineVoucherType from "views/OnlineVoucherType/EditOnlineVoucherType";
import OnlineVoucherTypeList from "views/OnlineVoucherType/OnlineVoucherTypeList";
import ViewOnlineVoucherType from "views/OnlineVoucherType/ViewOnlineVoucherType";

const onlineVoucherType = () => {
  let views = [];

  views.push({
    path: "/OnlineVoucherType/List",
    name: "O Voucher Type",
    icon: LoyaltyIcon,
    component: OnlineVoucherTypeList,
    layout: "/admin"
  });

  return views;
};

const onlineVoucherTypeHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/OnlineVoucherType/Create",
      name: "Create Online Voucher Type",
      component: CreateOnlineVoucherType,
      layout: "/admin"
    },
    {
      path: "/OnlineVoucherType/Detail",
      name: "View Online Voucher Type",
      component: ViewOnlineVoucherType,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/OnlineVoucherType/Delete",
      name: "Delete Online Voucher Type",
      component: DeleteOnlineVoucherType,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/OnlineVoucherType/Edit",
      name: "Edit Online Voucher Type",
      component: EditOnlineVoucherType,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  return [
    {
      collapse: true,
      name: "Online Voucher Type Hidden",
      state: "onlineVoucherTypeHidden",
      hideInSidebar: true,
      views: views
    }
  ];
};

export { onlineVoucherType, onlineVoucherTypeHidden };
