import InDraftMerchantList from "views/Merchant/InDraft";
import BusinessIcon from "@material-ui/icons/Business";
import CreateMerchant from "views/Merchant/CreateMerchant";
import DetailMerchant from "views/Merchant/ViewMerchant";
import EditMerchant from "views/Merchant/EditMerchant";
import PublishMerchant from "views/Merchant/PublishMerchant";
import DeleteMerchant from "views/Merchant/DeleteMerchant";
import PublishedMerchantList from "views/Merchant/PublishedMerchantList";
import InActiveMerchantList from "views/Merchant/InActiveMerchantList";
import DeactivateMerchant from "views/Merchant/DeactivateMerchant";
import MerchantCreateUser from "views/Merchant/MerchantCreateUser";

const merchant = () => {
  let views = [];
  views = views.concat([
    {
      path: "/Merchants/InDraft",
      name: "In Draft",
      mini: "IN",
      component: InDraftMerchantList,
      layout: "/admin"
    },
    {
      path: "/Merchants/Published",
      name: "Published",
      mini: "PU",
      component: PublishedMerchantList,
      layout: "/admin"
    },
    {
      path: "/Merchants/InActive",
      name: "In Active",
      mini: "IA",
      component: InActiveMerchantList,
      layout: "/admin"
    }
  ]);

  return [
    {
      collapse: true,
      name: "Merchants",
      icon: BusinessIcon,
      state: "merchantCollapse",
      views: views
    }
  ];
};

const merchantHidden = () => {
  let views = [
    {
      path: "/Merchant/Create",
      name: "Create Merchant",
      component: CreateMerchant,
      layout: "/admin"
    },
    {
      path: "/Merchant/Detail",
      name: "Merchant Detail",
      component: DetailMerchant,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/Merchant/Edit",
      name: "Edit Merchant",
      component: EditMerchant,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/Merchant/Publish",
      name: "Publish Merchant",
      component: PublishMerchant,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/Merchant/Delete",
      name: "Delete Merchant",
      component: DeleteMerchant,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/Merchant/Deactivate",
      name: "Deactivate Merchant",
      component: DeactivateMerchant,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/Merchant/CreateUser",
      name: "Create User",
      component: MerchantCreateUser,
      layout: "/admin",
      params: [
        {
          name: "merchantID"
        }
      ]
    }
  ];

  return [
    {
      collapse: true,
      name: "Merchant Hidden",
      state: "merchantHidden",
      hideInSidebar: true,
      views: views
    }
  ];
};

export { merchant, merchantHidden };
