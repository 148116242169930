import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";

import InDraftCounterList from "views/Counter/InDraftCounterList.js";
import PublishedCounterList from "views/Counter/PublishedCounterList.js";
import DeactivatedCounterList from "views/Counter/DeactivatedCounterList.js";
import CreateCounter from "views/Counter/CreateCounter.js";
import PublishCounter from "views/Counter/PublishCounter.js";
import DeactivateCounter from "views/Counter/DeactivateCounter.js";
import DeleteCounter from "views/Counter/DeleteCounter.js";
import CounterDetail from "views/Counter/CounterDetail.js";
import UpdateCounterIssuanceInformation from "views/Counter/UpdateCounterIssuanceInformation.js";
import CounterCreateVoucherType from "views/Counter/CounterCreateVoucherType.js";
import CounterCreateUser from "views/Counter/CounterCreateUser.js";

import { token } from "functions/Helper";

const counter = () => {
  let views = [];
  if (token["counter.counters.read"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/Counters/InDraft",
        name: "In Draft",
        mini: "IN",
        component: InDraftCounterList,
        layout: "/admin"
      },
      {
        path: "/Counters/Published",
        name: "Published",
        mini: "PU",
        component: PublishedCounterList,
        layout: "/admin"
      },
      {
        path: "/Counters/Deactivated",
        name: "Deactivated",
        mini: "DE",
        component: DeactivatedCounterList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Counters",
        icon: ImportantDevicesIcon,
        state: "counterCollapse",
        views: views
      }
    ];
  return [];
};

const counterHidden = () => {
  let views = [];
  if (token["counter.counters.read"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Counter/Detail",
      name: "Counter Detail",
      component: CounterDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["counter.counters.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/Counter/Create",
        name: "Create Counter",
        component: CreateCounter,
        layout: "/admin"
      },
      {
        path: "/Counter/Delete",
        name: "Delete Counter",
        component: DeleteCounter,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Counter/CreateUser",
        name: "Create User",
        component: CounterCreateUser,
        layout: "/admin",
        params: [
          {
            name: "counterID"
          }
        ]
      }
    ]);
  if (
    token["counterissuance.counter_voucher_types.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Counter/CreateVoucherType",
      name: "Create Voucher Type",
      component: CounterCreateVoucherType,
      layout: "/admin",
      params: [
        {
          name: "counterID"
        }
      ]
    });
  if (
    token["counter.counters.publish"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Counter/Publish",
      name: "Publish Counter",
      component: PublishCounter,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["counter.counters.deactivate"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Counter/Deactivate",
      name: "Deactivate Counter",
      component: DeactivateCounter,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["counterissuance.counters.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Counter/UpdateIssuanceInformation",
      name: "Update Issuance Information",
      component: UpdateCounterIssuanceInformation,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Counter Hidden",
        state: "counterHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { counter, counterHidden };
