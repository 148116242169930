import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import CreateMerchantInventoryGeneration from "views/MerchantInventoryGenerations/CreateMerchantInventoryGenerationList";
import DeleteMerchantInventoryGeneration from "views/MerchantInventoryGenerations/DeleteMerchantInventoryGeneration";
import EditMerchantInventoryGeneration from "views/MerchantInventoryGenerations/EditMerchantInventoryGeneration";
import GeneratedMerchantInventoryGenerationList from "views/MerchantInventoryGenerations/GeneratedMerchantInventoryGenrationsList";
import GenerateMerchantInventoryGeneration from "views/MerchantInventoryGenerations/GenerateMerchantInventoryGenerations";
import PendingMerchantInventoryGenerationList from "views/MerchantInventoryGenerations/PendingMerchantInventoryGenerationsList";
import ViewMerchantInventoryGeneration from "views/MerchantInventoryGenerations/ViewMerchantInventoryGeneration";

const merchantInventoryGeneration = () => {
  let views = [
    {
      path: "/MerchantsProductGenerations/Pending",
      name: "Pending",
      mini: "PE",
      component: PendingMerchantInventoryGenerationList,
      layout: "/admin"
    },
    {
      path: "/MerchantsProductGenerations/Generated",
      name: "Generated",
      mini: "GE",
      component: GeneratedMerchantInventoryGenerationList,
      layout: "/admin"
    }
  ];

  return [
    {
      collapse: true,
      name: "M Inventory Generations",
      icon: ConfirmationNumberIcon,
      state: "merchantInventoryGenerations",
      views: views
    }
  ];
};

const merchantInventoryGenerationHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/MerchantInventoryGeneration/Create",
      name: "Create Merchant Inventory Generation",
      component: CreateMerchantInventoryGeneration,
      layout: "/admin"
    },
    {
      path: "/MerchantInventoryGeneration/View",
      name: "View Merchant Inventory Generation",
      component: ViewMerchantInventoryGeneration,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantInventoryGeneration/Delete",
      name: "View Merchant Inventory Generation",
      component: DeleteMerchantInventoryGeneration,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantInventoryGeneration/Generate",
      name: "Generate Merchant Inventory Generation",
      component: GenerateMerchantInventoryGeneration,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/MerchantInventoryGeneration/Edit",
      name: "Edit Merchant Inventory Generation",
      component: EditMerchantInventoryGeneration,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  return [
    {
      collapse: true,
      name: "Merchant Inventory Generation",
      state: "merchantInventoryGenerationHidden",
      hideInSidebar: true,
      views
    }
  ];
};

export { merchantInventoryGeneration, merchantInventoryGenerationHidden };
