import OnlineBookingPromoCodesList from "views/OnlineBookingPromoCodes/OnlineBookingPromoCodesList";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CreateOnlineBookingsPromoCode from "views/OnlineBookingPromoCodes/CreateOnlineBookingPromoCodes";
import EditOnlineBookingPromoCodes from "views/OnlineBookingPromoCodes/EditOnlineBookingPromoCodes";
import ViewOnlineBookingsPromoCode from "views/OnlineBookingPromoCodes/ViewOnlineBookingPromoCodes";
import DeleteOnlineBookingsPromoCode from "views/OnlineBookingPromoCodes/DeleteOnlineBookingPromoCodes";

const onlineBookingPromoCode = () => {
  return {
    path: "/OnlineBookingPromoCodesList",
    name: "Online Booking Promo Code",
    icon: MonetizationOnIcon,
    component: OnlineBookingPromoCodesList,
    layout: "/admin"
  };
};

const onlineBookingPromoCodeHidden = () => {
  return [
    {
      collapse: true,
      name: "Online Booking Hidden",
      state: "onlineBookingHidden",
      hideInSidebar: true,
      views: [
        {
          path: "/OnlineBookingPromoCodes/Create",
          name: "Create Online Booking Promo Codes",
          component: CreateOnlineBookingsPromoCode,
          layout: "/admin"
        },
        {
          path: "/OnlineBookingPromoCodes/Edit",
          name: "Edit Online Booking Promo Codes",
          component: EditOnlineBookingPromoCodes,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/OnlineBookingPromoCodes/View",
          name: "View Online Booking Promo Codes",
          component: ViewOnlineBookingsPromoCode,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/OnlineBookingPromoCodes/Delete",
          name: "Delete Online Booking Promo Codes",
          component: DeleteOnlineBookingsPromoCode,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        }
      ]
    }
  ];
};

export { onlineBookingPromoCode, onlineBookingPromoCodeHidden };
