import React from "react";
import PropTypes from "prop-types";
import Moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { GetGenderDescription } from "enums/Gender";
import { tripDateFormat } from "functions/Helper";
import { GetCheckInStatusDescription } from "enums/CheckInStatus";
import { GetCoreApiCheckInCancellationSourceDescription } from "enums/CoreApiCheckInCancellationSource";

export default function View(props) {
  const { coreApiCheckIn } = props;
  return (
    <GridContainer>
      <GridItem md={12} xs={12}>
        <CustomInput
          labelText="Confirmation Code"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.confirmationCode
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Embarkation"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.coreApiTrip.route.embarkationPort.name
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Destination"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.coreApiTrip.route.destinationPort.name
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Departure Date"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: Moment(new Date(coreApiCheckIn.coreApiTrip.date)).format(
              tripDateFormat
            )
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Departure Time"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.isOpen
              ? "OPEN TRIP"
              : coreApiCheckIn.coreApiTrip.time
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Passport No"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.identification.no
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Full Name"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.identification.fullName
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Gender"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: GetGenderDescription(coreApiCheckIn.identification.gender)
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Date of Birth"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: Moment(
              new Date(coreApiCheckIn.identification.dateOfBirth)
            ).format("DD MMM yyyy")
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Nationality"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.identification.nationality.nationality
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Issuance Country"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.identification.issuanceCountry.name
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Issue Date"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: Moment(
              new Date(coreApiCheckIn.identification.issueDate)
            ).format("DD MMM yyyy")
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Expiry Date"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: Moment(
              new Date(coreApiCheckIn.identification.expiryDate)
            ).format("DD MMM yyyy")
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Status"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: GetCheckInStatusDescription(coreApiCheckIn.status)
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Open Trip"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.isOpen ? "Yes" : "No"
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Remarks"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.remarks
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Cancellation Source"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: GetCoreApiCheckInCancellationSourceDescription(
              coreApiCheckIn.cancellationSource
            )
          }}
        />
      </GridItem>
      <GridItem md={6} xs={12}>
        <CustomInput
          labelText="Cancel Ref. No"
          disabled
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: coreApiCheckIn.cancelReferenceNo
          }}
        />
      </GridItem>
      {coreApiCheckIn.status == 2 &&
        (coreApiCheckIn.isOpen ? (
          <GridItem md={6} xs={12}>
            <CustomInput
              labelText="Open Trip ID"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: coreApiCheckIn.openTripID
              }}
            />
          </GridItem>
        ) : (
          <GridItem md={6} xs={12}>
            <CustomInput
              labelText=" Boarding Pass No"
              disabled
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: coreApiCheckIn.boardingPassNo
              }}
            />
          </GridItem>
        ))}
    </GridContainer>
  );
}

View.propTypes = {
  coreApiCheckIn: PropTypes.object
};
