import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import CounterVoucherTypeList from "./CounterVoucherTypeList";
import CounterUserList from "./CounterUserList";

import { counterApi, counterIssuanceApi } from "functions/apis";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function CounterDetail(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const { id } = useParams();
  const [counter, setCounter] = React.useState(null);

  const [counterCredit, setCounterCredit] = React.useState(null);

  useEffect(() => {
    counterApi
      .get("/Counters/" + id, {
        signal: source.current.signal
      })
      .then(res => setCounter(res))
      .catch(error => console.log(error));
  }, [id]);
  useEffect(() => {
    if (counter !== null && counter.publicationStatus !== 0)
      counterIssuanceApi
        .get("/Counters/" + counter.id, {
          signal: source.current.signal
        })
        .then(res => setCounterCredit(res))
        .catch(error => console.log(error));
  }, [counter]);

  const classes = useStyles();
  return (
    counter !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>COUNTER</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              {(token["counterissuance.counters.write"] === "allowed" ||
                token.is_admin === "True") && (
                <Link to={"/admin/Counter/UpdateIssuanceInformation/" + id}>
                  <Button color="rose" type="button" value="Create">
                    Update Issuance Information
                  </Button>
                </Link>
              )}
              <CustomInput
                labelText="Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: counter.code
                }}
              />

              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: counter.name
                }}
              />
              {counter.publicationStatus !== 0 && counterCredit !== null && (
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Billing Currency"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          counterCredit.currency === null
                            ? ""
                            : counterCredit.currency.code
                      }}
                    />
                  </GridItem>
                </GridContainer>
              )}
            </CardBody>
          </Card>
          {counter.publicationStatus !== 0 && counterCredit !== null && (
            <>
              {(token["counterissuance.counter_voucher_types.read"] ===
                "allowed" ||
                token.is_admin === "True") && (
                <CounterVoucherTypeList
                  id={id}
                  currency={counterCredit.currency}
                />
              )}
              <CounterUserList id={id} />
            </>
          )}
        </GridItem>
      </GridContainer>
    )
  );
}

CounterDetail.propTypes = {
  history: PropTypes.object
};
