import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { agentApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function AgentCreateUser(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { agentID } = useParams();

  const [redirect, setRedirect] = React.useState(false);

  const handleUserNameChanged = e =>
    setState({
      ...state,
      userName: e.target.value === "" ? null : e.target.value
    });

  const handleEmailChanged = e =>
    setState({
      ...state,
      email: e.target.value === "" ? null : e.target.value
    });

  const handlePasswordChanged = e =>
    setState({
      ...state,
      password: e.target.value === "" ? null : e.target.value
    });

  const handleIsSuperAdminChanged = e =>
    setState({ ...state, isSuperAdmin: e.target.checked });

  const handleIsAdminChanged = e =>
    setState({ ...state, isAdmin: e.target.checked });

  const [state, setState] = React.useState({
    userName: null,
    email: null,
    password: null,
    isSuperAdmin: false,
    isAdmin: false
  });

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    agentApi
      .post("/Agents/" + agentID + "/Users", state, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Agent/Detail/" + agentID} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>ADD USER</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="UserName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleUserNameChanged,
                  value: state.userName === null ? "" : state.userName
                }}
              />
              <CustomInput
                labelText="Email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleEmailChanged,
                  value: state.email === null ? "" : state.email
                }}
              />
              <CustomInput
                labelText="Password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handlePasswordChanged,
                  value: state.password === null ? "" : state.password
                }}
              />
              <GridContainer>
                <GridItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleIsSuperAdminChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Is Super Admin"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleIsAdminChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Is Admin"
                  />
                </GridItem>
              </GridContainer>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

AgentCreateUser.propTypes = {
  history: PropTypes.object
};
