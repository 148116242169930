export function GetOnlineBookingPaymentMethods() {
  return [
    {
      key: 0,
      description: "Card"
    },
    {
      key: 1,
      description: "Grab"
    },
    {
      key: 2,
      description: "Midtrans"
    },
    {
      key: 2003,
      description: "Alipay"
    },
    {
      key: 2004,
      description: "Wechat Pay"
    },
    {
      key: 2005,
      description: "Pay Now"
    }
  ];
}

export function GetOnlineBookingPaymentMethodDescription(value) {
  let onlineBookingPaymentMethod = GetOnlineBookingPaymentMethods().find(
    item => item.key === value
  );
  if (onlineBookingPaymentMethod) return onlineBookingPaymentMethod.description;
  return value;
}
