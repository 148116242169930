export function GetCheckInStatuses() {
  return [
    {
      key: 0,
      description: "Pending"
    },
    {
      key: 1,
      description: "Check-In Enqueued"
    },
    {
      key: 2,
      description: "Checked In"
    },
    {
      key: 3,
      description: "Boarded"
    },
    {
      key: 4,
      description: "Cancel Enqueued"
    },
    {
      key: 5,
      description: "Cancelled"
    },
    {
      key: 6,
      description: "Check-In Error"
    },
    {
      key: 7,
      description: "Cancel Error"
    },
    {
      key: 8,
      description: "Check-In Resolved"
    },
    {
      key: 7,
      description: "Cancel Resolved"
    }
  ];
}

export function GetCheckInStatusDescription(value) {
  let checkInStatus = GetCheckInStatuses().find(item => item.key === value);
  return checkInStatus.description;
}
