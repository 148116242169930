import Card from "components/Card/Card";
import React, { useEffect, useRef } from "react";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import PropTypes from "prop-types";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "react-table";
import { voucherApi } from "functions/apis";
import { formatDateTime } from "functions/Helper";
import { formatCurrency } from "functions/Helper";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import { Slide } from "@material-ui/core";

const sortOptionsList = [
  "COST_ASC",
  "COST_DESC",
  "EFFECTIVE_DATE_ASC",
  "EFFECTIVE_DATE_DESC",
  "EXPIRY_DATE_ASC",
  "EXPIRY_DATE_DESC",
  "CREATED_AT_ASC",
  "CREATED_AT_DESC"
];

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MerchantVoucherCost(props) {
  const source = useRef(new AbortController());

  const classes = useStyles();

  const { id } = props;

  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });

  const [merchantCostFilter, setMerchantCostFilter] = React.useState({
    searchString: "",
    sort: null
  });
  const [data, setData] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [merchantCostBody, setMerchantCostBody] = React.useState(null);
  const [editMerchantCost, setEditMerchantCost] = React.useState(null);
  const [deleteMerchantCost, setDeleteMerchantCost] = React.useState(null);
  const [pages, setPages] = React.useState(0);
  const [reload, setReload] = React.useState({});

  const handleSortOptionsChanged = e => {
    setMerchantCostFilter({ ...merchantCostFilter, sort: e.target.value });
  };

  const handleSearchChanged = e => {
    setMerchantCostFilter({
      ...merchantCostFilter,
      searchString: e.target.value
    });
  };

  const onSubmitMerchantCost = () => {
    voucherApi
      .post(
        "/voucherTypes/" + id + "/MerchantCosts",
        {
          ...merchantCostBody
        },
        {
          signal: source.current.signal
        }
      )
      .then(resp => {
        setReload({});
        console.log(resp);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleEffectiveDateChanged = e =>
    setMerchantCostBody({
      ...merchantCostBody,
      effectiveDate: e === "" ? null : e
    });

  const handleExpiryDateChanged = e =>
    setMerchantCostBody({
      ...merchantCostBody,
      expiryDate: e === "" ? null : e
    });

  const handleCostChanged = e =>
    setMerchantCostBody({
      ...merchantCostBody,
      cost: e.target.value
    });

  const handleEditEffectiveDateChanged = e =>
    setEditMerchantCost({
      ...editMerchantCost,
      effectiveDate: e === "" ? null : e
    });

  const handleEditExpiryDateChanged = e =>
    setEditMerchantCost({
      ...editMerchantCost,
      expiryDate: e === "" ? null : e
    });

  const handleEditCostChanged = e => {
    setEditMerchantCost({
      ...editMerchantCost,
      cost: e.target.value
    });
  };

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      voucherApi
        .get("/voucherTypes/" + id + "/MerchantCosts", {
          params: {
            filter: merchantCostFilter,
            pagination: pagination
          },
          signal: source.current.signal
        })
        .then(data => {
          setData(
            data.records.map(record => {
              let tmp = {
                ...record,
                effectiveDate: new Date(record.effectiveDate + "Z"),
                expiryDate:
                  record.expiryDate === null
                    ? null
                    : new Date(record.expiryDate + "Z")
              };
              return {
                ...tmp,
                actions: (
                  <div className="actions-right">
                    <>
                      <Button
                        onClick={() => setEditMerchantCost(tmp)}
                        color="info"
                        className="remove"
                      >
                        <EditIcon /> EDIT
                      </Button>
                      <Button
                        onClick={() => setDeleteMerchantCost(tmp)}
                        color="danger"
                        className="remove"
                      >
                        <DeleteIcon /> DELETE
                      </Button>
                    </>
                  </div>
                )
              };
            })
          );
          setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, reload, merchantCostFilter, classes]);

  const onEditMerchantVoucher = () => {
    setDisabled(true);
    voucherApi
      .put(
        "/voucherTypes/" + id + "/MerchantCosts/" + editMerchantCost.id,
        editMerchantCost,
        {
          signal: source.current.signal
        }
      )
      .then(() => {
        setReload({});
        setEditMerchantCost(null);
        setDisabled(false);
      })
      .catch(() => {
        setDisabled(false);
      });
  };

  const onDeleteMerchantVoucher = () => {
    setDisabled(true);
    voucherApi
      .delete(
        "/voucherTypes/" + id + "/MerchantCosts/" + deleteMerchantCost.id,
        {
          signal: source.current.signal
        }
      )
      .then(() => {
        setDeleteMerchantCost(null);
        setDisabled(false);
        setReload({});
      })
      .catch(() => {
        setDisabled(false);
      });
  };

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <MailOutline />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Merchant Cost</h4>
      </CardHeader>
      <CardBody>
        {editMerchantCost && (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal
            }}
            open={editMerchantCost !== null}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setEditMerchantCost(null)}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setEditMerchantCost(null)}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}>Edit Cost</h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classes.modalBody}
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={`Cost (${editMerchantCost?.voucherType?.merchant?.settlementCurrency?.code})`}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleEditCostChanged,
                      value:
                        editMerchantCost?.cost === null
                          ? ""
                          : editMerchantCost?.cost
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={new Date(editMerchantCost.effectiveDate)}
                      onChange={handleEditEffectiveDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Effective Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={
                        editMerchantCost.expiryDate === null
                          ? null
                          : new Date(editMerchantCost.expiryDate)
                      }
                      onChange={handleEditExpiryDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Expiry Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => setEditMerchantCost(null)}
                color="info"
                round
              >
                Cancel
              </Button>
              <Button
                disabled={disabled}
                onClick={onEditMerchantVoucher}
                color="danger"
                round
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {deleteMerchantCost && (
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal
            }}
            open={deleteMerchantCost !== null}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setDeleteMerchantCost(null)}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setDeleteMerchantCost(null)}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h4 className={classes.modalTitle}>
                Delete Merchant Voucher Cost
              </h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classes.modalBody}
            >
              <GridContainer justify>
                <GridItem xs={0} sm={2} />
                <GridItem xs={12} sm={8}>
                  <h4 className={classes.modalTitle}>
                    Are you sure want to delete this voucher cost?
                  </h4>
                </GridItem>
                <GridItem xs={0} sm={2} />
              </GridContainer>
            </DialogContent>
            <DialogActions
              className={classes.modalFooter + " " + classes.modalFooterCenter}
            >
              <Button
                onClick={() => setDeleteMerchantCost(null)}
                color="info"
                round
              >
                Cancel
              </Button>
              <Button
                disabled={disabled}
                onClick={onDeleteMerchantVoucher}
                color="warning"
                round
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <form>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <Datetime
                  value={merchantCostBody?.effectiveDate}
                  onChange={handleEffectiveDateChanged}
                  timeFormat={true}
                  closeOnSelect={true}
                  inputProps={{
                    placeholder: "Effective Date"
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <Datetime
                  value={merchantCostBody?.expiryDate}
                  onChange={handleExpiryDateChanged}
                  timeFormat={true}
                  closeOnSelect={true}
                  inputProps={{
                    placeholder: "Expiry Date"
                  }}
                />
              </FormControl>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <FormControl fullWidth>
                <CustomInput
                  labelText="Cost"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: handleCostChanged,
                    value: merchantCostBody?.cost
                  }}
                />
              </FormControl>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Button
                onClick={onSubmitMerchantCost}
                color="rose"
                value="Publish"
              >
                CREATE
              </Button>
            </GridItem>

            <GridItem xs={12} md={12} sm={12}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <CustomInput
                      labelText="Search"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleSearchChanged,
                        value: merchantCostFilter.searchString
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selSortOptions"
                      className={classes.selectLabel}
                    >
                      SORT BY
                    </InputLabel>
                    <Select
                      inputProps={{
                        id: "selSortOptions",
                        name: "selSortOptions"
                      }}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={merchantCostFilter.sort ?? ""}
                      onChange={handleSortOptionsChanged}
                    >
                      {sortOptionsList.map(record => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={record}
                            key={record}
                          >
                            <p>{record}</p>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <ReactTable
                manual
                pages={pages}
                page={pagination.pageIndex - 1}
                pageSize={pagination.pageSize}
                onPageChange={page =>
                  setPagination({
                    pageIndex: page + 1,
                    pageSize: pagination.pageSize
                  })
                }
                onPageSizeChange={(pageSize, page) => {
                  setPagination({
                    pageSize: pageSize,
                    pageIndex: page + 1
                  });
                }}
                loading={loading}
                data={data}
                filterable={false}
                columns={[
                  {
                    Header: "Cost",
                    id: "cost",
                    accessor: d =>
                      formatCurrency(
                        d.voucherType?.merchant?.settlementCurrency?.code,
                        d.cost
                      )
                  },
                  {
                    Header: "Effective Date",
                    id: "effectiveDate",
                    accessor: d => formatDateTime(new Date(d.effectiveDate))
                  },
                  {
                    id: "expiryDate",
                    Header: "Expiry Date",
                    accessor: d =>
                      d.expiryDate === null
                        ? ""
                        : formatDateTime(new Date(d.expiryDate))
                  },
                  {
                    Header: "Created At",
                    id: "createdAt",
                    accessor: d => formatDateTime(new Date(d.createdAt))
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </GridItem>
          </GridContainer>
        </form>
      </CardBody>
    </Card>
  );
}

MerchantVoucherCost.propTypes = {
  id: PropTypes.string
};
