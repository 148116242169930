import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { Redirect } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { bookingApi } from "functions/apis";

const useStyles = makeStyles(styles);

const CreateAppsNotification = props => {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [section, setSection] = useState([]);
  const [input, setInput] = useState({
    title: "",
    description: "",
    url: "",
    NotificationSectionID: null,
    name: ""
  });

  const createAppsNotificationSignal = useRef(new AbortController());

  useEffect(() => {
    bookingApi
      .get("appsNotificationSections", {
        signal: createAppsNotificationSignal.current.signal,
        params: {
          filter: {},
          pagination: {}
        }
      })
      .then(resp => {
        setSection(resp.records);
      });
    return () => {
      createAppsNotificationSignal.current.abort();
      setRedirect(disabled);
    };
  }, []);

  const handleNameChanged = e =>
    setInput({
      ...input,
      name: e.target.value === "" ? null : e.target.value
    });

  const handleTitleChanged = e =>
    setInput({
      ...input,
      title: e.target.value === "" ? null : e.target.value
    });

  const handleDescriptionChanged = e =>
    setInput({
      ...input,
      description: e.target.value === "" ? null : e.target.value
    });

  const handleNotificationSectionIDChanged = e =>
    setInput({
      ...input,
      NotificationSectionID: e.target.value === "" ? null : e.target.value
    });

  const handleUrlChanged = e =>
    setInput({
      ...input,
      url: e.target.value === "" ? null : e.target.value
    });

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post("appsnotifications", input, {
        signal: createAppsNotificationSignal.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/AppsNotification/List"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Create Apps Notification</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNameChanged,
                  value: input.name
                }}
              />
              <CustomInput
                labelText="PushNotification Title"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleTitleChanged,
                  value: input.title
                }}
              />
              <CustomInput
                labelText="PushNotification Description"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleDescriptionChanged,
                  value: input.description
                }}
              />
              <CustomInput
                labelText="Url"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleUrlChanged,
                  value: input.url ?? ""
                }}
              />
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCategory"
                  className={classes.selectLabel}
                >
                  Section
                </InputLabel>
                <Select
                  MenuProps={{
                    classes: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={input.NotificationSectionID ?? ""}
                  onChange={handleNotificationSectionIDChanged}
                >
                  {section.map(val => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      key={val.id}
                      value={val.id}
                    >
                      <b>{val.name}</b>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateAppsNotification;

CreateAppsNotification.propTypes = {
  history: PropTypes.object
};
