import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { masterApi, agentApi, adminApi } from "functions/apis";

import { token } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

const useStyles = makeStyles(styles);

export default function EditAgent(props) {
  const source = useRef(new AbortController());

  const [businessCategoryList, setBusinessCategoryList] = React.useState([]);
  const [countryList, setCountryList] = React.useState([]);
  const [userNameList, setUserNameList] = React.useState([]);

  useEffect(() => {
    if (
      token["master.business_categories.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/BusinessCategories", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1
            }
          }
        })
        .then(data => setBusinessCategoryList(data.records))
        .catch(error => console.log(error));

    if (
      token["master.countries.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/Countries", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 2
            }
          }
        })
        .then(data => setCountryList(data.records))
        .catch(error => console.log(error));

    if (
      token["admin.users.read_username"] === "allowed" ||
      token.is_admin === "True"
    )
      adminApi
        .get("/Users/UserNames", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null
            }
          }
        })
        .then(data => setUserNameList(data.records))
        .catch(error => console.log(error));

    return () => source.current.abort();
  }, []);
  const [agent, setAgent] = React.useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (token["agent.agents.read"] === "allowed" || token.is_admin === "True")
      agentApi
        .get("/Agents/" + id, {
          signal: source.current.signal
        })
        .then(data =>
          setAgent({
            ...data,
            businessCategoryID:
              data.businessCategory === null ? null : data.businessCategory.id,
            locationID: data.location === null ? null : data.location.id
          })
        )
        .catch(error => console.log(error));
  }, [id]);

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    agentApi
      .put("/Agents/" + id, agent, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const handleCodeChanged = e =>
    setAgent({ ...agent, code: e.target.value === "" ? null : e.target.value });
  const handleNameChanged = e =>
    setAgent({ ...agent, name: e.target.value === "" ? null : e.target.value });
  const handleBusinessCategoryIDChanged = e =>
    setAgent({
      ...agent,
      businessCategoryID: e.target.value === "" ? null : e.target.value
    });
  const handleLocationIDChanged = e =>
    setAgent({
      ...agent,
      locationID: e.target.value === "" ? null : e.target.value
    });
  const handleEmailChanged = e =>
    setAgent({
      ...agent,
      email: e.target.value === "" ? null : e.target.value
    });
  const handleContactPersonChanged = e =>
    setAgent({
      ...agent,
      contactPerson: e.target.value === "" ? null : e.target.value
    });
  const handleCompanyAddressChanged = e =>
    setAgent({
      ...agent,
      companyAddress: e.target.value === "" ? null : e.target.value
    });
  const handleOfficeContactNumberChanged = e =>
    setAgent({
      ...agent,
      officeContactNumber: e.target.value === "" ? null : e.target.value
    });
  const handleMobileContactNumberChanged = e =>
    setAgent({
      ...agent,
      mobileContactNumber: e.target.value === "" ? null : e.target.value
    });
  const handleReferrerChanged = e =>
    setAgent({
      ...agent,
      referrer: e.target.value === "" ? null : e.target.value
    });
  const handleAccountManagerChanged = e =>
    setAgent({
      ...agent,
      accountManager: e.target.value === "" ? null : e.target.value
    });
  const handleFirstReferrerChanged = e =>
    setAgent({
      ...agent,
      firstReferrer: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Agent/Detail/" + id} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>EDIT AGENT</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            {agent !== null && (
              <form onSubmit={handleSubmit}>
                <CustomInput
                  labelText="Code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleCodeChanged,
                    value: agent.code === null ? "" : agent.code
                  }}
                />

                <CustomInput
                  labelText="Name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleNameChanged,
                    value: agent.name === null ? "" : agent.name
                  }}
                />
                <CustomInput
                  labelText="Email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleEmailChanged,
                    value: agent.email === null ? "" : agent.email
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selBusinessCategory"
                        className={classes.selectLabel}
                      >
                        Business Category
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={
                          agent.businessCategoryID === null
                            ? ""
                            : agent.businessCategoryID
                        }
                        onChange={handleBusinessCategoryIDChanged}
                      >
                        {businessCategoryList.map(businessCategory => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={businessCategory.id}
                              key={businessCategory.id}
                            >
                              <b>{businessCategory.code}</b>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selLocation"
                        className={classes.selectLabel}
                      >
                        Location
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={
                          agent.locationID === null ? "" : agent.locationID
                        }
                        onChange={handleLocationIDChanged}
                      >
                        {countryList.map(country => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={country.id}
                              key={country.id}
                            >
                              <b>{country.name}</b>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Contact Person"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleContactPersonChanged,
                        value:
                          agent.contactPerson === null
                            ? ""
                            : agent.contactPerson
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Company Address"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleCompanyAddressChanged,
                        value:
                          agent.companyAddress === null
                            ? ""
                            : agent.companyAddress
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Contact Number (Office)"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleOfficeContactNumberChanged,
                        value:
                          agent.officeContactNumber === null
                            ? ""
                            : agent.officeContactNumber
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Contact Number (Mobile)"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleMobileContactNumberChanged,
                        value:
                          agent.mobileContactNumber === null
                            ? ""
                            : agent.mobileContactNumber
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Referrer"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleReferrerChanged,
                        value: agent.referrer === null ? "" : agent.referrer
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selAccountManager"
                        className={classes.selectLabel}
                      >
                        Account Manager
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={
                          agent.accountManager === null
                            ? ""
                            : agent.accountManager
                        }
                        onChange={handleAccountManagerChanged}
                      >
                        {userNameList.map(userName => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={userName}
                              key={userName}
                            >
                              <b>{userName}</b>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="First Referrer"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleFirstReferrerChanged,
                        value:
                          agent.firstReferrer === null
                            ? ""
                            : agent.firstReferrer
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

EditAgent.propTypes = {
  history: PropTypes.object
};
