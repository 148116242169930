import { Check, MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import { masterApi } from "functions/apis";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput.js";
import Datetime from "react-datetime";
import moment from "moment";
import { tripDateFormat } from "functions/Helper";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function EditOnlineBookingsPromoCode(props) {
  const [redirect, setRedirect] = useState(false);
  const [sectorList, setSectorList] = useState([]);
  const [state, setState] = useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [currencyList, setCurrencyList] = React.useState([]);
  const [dayGroupList, setDayGroupList] = React.useState([]);

  const { id } = useParams();

  const abortController = React.useRef(new AbortController());

  const [currencyLoaded, setCurrencyLoaded] = React.useState(false);
  const [sectorLoaded, setSectorLoaded] = React.useState(false);
  const [daygroupLoaded, setDayGroupLoaded] = React.useState(false);
  const getSector = () => {
    masterApi
      .get("/Sectors", {
        signal: abortController.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1
          }
        }
      })
      .then(data => {
        setSectorList(data.records);
        setSectorLoaded(true);
      })
      .catch(error => console.log(error));
  };

  const getDayGroup = () => {
    masterApi
      .get("/DayGroups", {
        signal: abortController.current.signal,
        params: {
          filter: {
            searchString: null,
            publicationStatus: 1
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => {
        setDayGroupList(data.records);
        setDayGroupLoaded(true);
      })
      .catch(error => console.log(error));
  };

  const getCurrency = () => {
    masterApi
      .get("/Currencies", {
        signal: abortController.current.signal,
        params: {
          filter: {
            searchString: null,
            publicationStatus: 1
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => {
        setCurrencyList(data.records);
        setCurrencyLoaded(true);
      })
      .catch(error => console.log(error));
  };

  const getData = () => {
    bookingApi
      .get("OnlineBookingPromoCodes/" + id, {
        signal: abortController.current.signal
      })
      .then(data => {
        const time = new Date();
        if (data.time && data.time.length === 4) {
          const hours = `${data.time[0]}${data.time[1]}`;
          const minute = `${data.time[2]}${data.time[3]}`;
          time.setHours(hours);
          time.setMinutes(minute);
        }
        setState({
          ...data,
          currencyID: data.currency.id,
          effectiveDate: moment(new Date(data.effectiveDate + "Z")),
          expiryDate: moment(new Date(data.expiryDate + "Z")),
          time: time,
          travelDateStart:
            data.travelDateStart !== null
              ? new Date(data.travelDateStart + "Z")
              : null,
          travelDateUntil:
            data.travelDateUntil !== null
              ? new Date(data.travelDateUntil + "Z")
              : null,
          allowedDayGroupID: data.allowedDayGroup?.id,
          allowedSectorIDs: data.allowedSectors.map(s => s.sector.id)
        });
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getDayGroup();
    getSector();
    getCurrency();

    return () => abortController.current.abort();
  }, []);

  useEffect(() => {
    if (daygroupLoaded && currencyLoaded && sectorLoaded) getData();
  }, [daygroupLoaded, currencyLoaded, sectorLoaded]);

  const handlePromoCodeChanged = e =>
    setState({ ...state, promoCode: e.target.value });

  const handleUserEmailChanged = e =>
    setState({ ...state, userEmail: e.target.value });

  const handleDiscountAmountChanged = e =>
    setState({ ...state, discountAmount: e.target.value });

  const handleLimitChanged = e => setState({ ...state, limit: e.target.value });

  const handleMinimalTripChanged = e =>
    setState({ ...state, minimalTrip: e.target.value });

  const handleCurrencyIDChanged = e => {
    setState({ ...state, currencyID: e.target.value });
  };

  const handleAllowedDayGroupIDChanged = e =>
    setState({ ...state, allowedDayGroupID: e.target.value });

  const handleAllowedSectorIDsChanged = e =>
    setState({ ...state, allowedSectorIDs: e.target.value });

  const handleHasDayGroupRestrictionChanged = e =>
    setState({ ...state, hasDayGroupRestriction: e.target.checked });

  const handleHasSectorRestrictionChanged = e =>
    setState({ ...state, hasSectorRestriction: e.target.checked });

  const handleHasUserRestrictionChanged = e =>
    setState({ ...state, hasUserRestriction: e.target.checked });

  const handleHasTravelDateRestrictionChanged = e =>
    setState({ ...state, hasTravelDateRestriction: e.target.checked });

  const handleHasRestrictedToCardPaymentChanged = e =>
    setState({ ...state, isRestrictedToCardPayment: e.target.checked });

  const handleHasTimeRestrictionsChanged = e =>
    setState({ ...state, hasTimeRestriction: e.target.checked });

  const handleCardNumberPrefixChanged = e =>
    setState({ ...state, cardNumberPrefix: e.target.value });

  const handleHasMinimalAppVersionChanged = e =>
    setState({ ...state, hasMinimalAppVersion: e.target.checked });

  const handleMinimalAndroidAppVersionChanged = e =>
    setState({ ...state, minimalAndroidAppVersion: e.target.value });

  const handleMinimalIOSAppVersionChanged = e =>
    setState({ ...state, minimalIOSAppVersion: e.target.value });

  const handleTimeChanged = e =>
    setState({ ...state, time: e === "" ? null : e });

  const handleSubmit = () => {
    setDisabled(true);
    const body = { ...state };
    body.time = body.time ? moment(body.time).format("HHmm") : null;
    bookingApi
      .put("/OnlineBookingPromoCodes/" + id, body, {
        signal: abortController.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();

  return redirect ? (
    <Redirect to={"/admin/OnlineBookingPromoCodesList"} />
  ) : (
    state !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                EDIT ONLINE BOOKING PROMO CODES
              </h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  <CustomInput
                    labelText="Promo Code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handlePromoCodeChanged,
                      value: state.promoCode ?? ""
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <CustomInput
                    labelText="Minimal Trip"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handleMinimalTripChanged,
                      value: state.minimalTrip
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <CustomInput
                    labelText="Limit"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handleLimitChanged,
                      value: state.limit
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <InputLabel className={classes.selectLabel}>
                    Effective Date
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      value={state.effectiveDate}
                      onChange={e =>
                        setState({
                          ...state,
                          effectiveDate: e === "" ? null : e
                        })
                      }
                      dateFormat={tripDateFormat}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Effective Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <InputLabel className={classes.selectLabel}>
                    Expiry Date
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      value={state.expiryDate}
                      onChange={e =>
                        setState({
                          ...state,
                          expiryDate: e === "" ? null : e
                        })
                      }
                      dateFormat={tripDateFormat}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Expiry Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selCurrency"
                      className={classes.selectLabel}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      inputProps={{
                        id: "selCurrency",
                        name: "selCurrency"
                      }}
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={state.currencyID ?? ""}
                      onChange={handleCurrencyIDChanged}
                    >
                      {currencyList.map(record => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={record.id}
                            key={record.id}
                          >
                            <b>{record.code}</b>
                            <br />
                            {record.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <CustomInput
                    labelText="Discount Amount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handleDiscountAmountChanged,
                      value: state.discountAmount
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.hasUserRestriction}
                        onChange={handleHasUserRestrictionChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has User Restriction"
                  />
                </GridItem>
                {state.hasUserRestriction && (
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="User Email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleUserEmailChanged,
                        value: state.userEmail ?? ""
                      }}
                    />
                  </GridItem>
                )}
                <GridItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleHasDayGroupRestrictionChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has DayGroup Restriction"
                  />
                </GridItem>
                {state.hasDayGroupRestriction && ( // render only if Has DayGroup Restriction
                  <GridItem xs={12} md={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selDayGroup"
                        className={classes.selectLabel}
                      >
                        Allowed DayGroup
                      </InputLabel>
                      <Select
                        inputProps={{
                          id: "selDayGroup",
                          name: "selDayGroup"
                        }}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={
                          state.allowedDayGroupID === null
                            ? ""
                            : state.allowedDayGroupID
                        }
                        onChange={handleAllowedDayGroupIDChanged}
                      >
                        {dayGroupList.map(record => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={record.id}
                              key={record.id}
                            >
                              <b>{record.name}</b>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.hasSectorRestriction}
                        onChange={handleHasSectorRestrictionChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Sector Restriction"
                  />
                </GridItem>
                {state.hasSectorRestriction && (
                  <GridItem xs={12} md={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selAllowedSectors"
                        className={classes.selectLabel}
                      >
                        Allowed Sectors
                      </InputLabel>
                      <Select
                        multiple
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={state.allowedSectorIDs}
                        onChange={handleAllowedSectorIDsChanged}
                        renderValue={() => (
                          <div className={classes.chips}>
                            {state.allowedSectorIDs.map(value => {
                              let sector = sectorList.find(
                                item => item.id === value
                              );
                              return (
                                <div key={value} className={classes.chips}>
                                  <Chip
                                    key={sector.id}
                                    label={<b>{sector.name}</b>}
                                    className={classes.chip}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      >
                        {sectorList.map(record => {
                          let sector = sectorList.find(
                            item => item.id === record.id
                          );
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelectedMultiple
                              }}
                              value={record.id}
                              key={record.id}
                            >
                              <b>{sector.code}</b> <br />
                              {sector.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.hasTravelDateRestriction}
                        onChange={handleHasTravelDateRestrictionChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Travel Date Restriction"
                  />
                </GridItem>
                {state.hasTravelDateRestriction && (
                  <GridItem xs={12} md={6}>
                    <InputLabel className={classes.selectLabel}>
                      Travel Date Start
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        value={state.travelDateStart}
                        onChange={e =>
                          setState({
                            ...state,
                            travelDateStart: e === "" ? null : e
                          })
                        }
                        dateFormat={tripDateFormat}
                        timeFormat={false}
                        utc={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "TravelDate Start"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                )}
                {state.hasTravelDateRestriction && (
                  <GridItem xs={12} md={6}>
                    <InputLabel className={classes.selectLabel}>
                      Travel Date Until
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        value={state.travelDateUntil}
                        onChange={e =>
                          setState({
                            ...state,
                            travelDateUntil: e === "" ? null : e
                          })
                        }
                        dateFormat={tripDateFormat}
                        timeFormat={false}
                        utc={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "TravelDate Until"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.hasTimeRestriction}
                        onChange={handleHasTimeRestrictionsChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Time Restriction"
                  />
                </GridItem>
                {state.hasTimeRestriction && (
                  <GridItem xs={12} md={12}>
                    <InputLabel className={classes.selectLabel}>
                      Travel Time
                    </InputLabel>
                    <FormControl fullWidth>
                      <Datetime
                        value={state.time}
                        onChange={handleTimeChanged}
                        dateFormat={false}
                        timeFormat={true}
                        inputProps={{
                          placeholder: "Time"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.isRestrictedToCardPayment}
                        onChange={handleHasRestrictedToCardPaymentChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Restricted To Card Payment"
                  />
                </GridItem>
                {state.isRestrictedToCardPayment && (
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="Card Number Prefix"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleCardNumberPrefixChanged,
                        value: state.cardNumberPrefix
                      }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.hasMinimalAppVersion}
                        onChange={handleHasMinimalAppVersionChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Has Minimal App Version"
                  />
                </GridItem>
                {state.hasMinimalAppVersion && (
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="Minimal Android App Version"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleMinimalAndroidAppVersionChanged,
                        value: state.minimalAndroidAppVersion
                      }}
                    />
                  </GridItem>
                )}
                {state.hasMinimalAppVersion && (
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="Minimal IOS App Version"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleMinimalIOSAppVersionChanged,
                        value: state.minimalIOSAppVersion
                      }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} md={12}>
                  <Button
                    disabled={disabled}
                    color="rose"
                    type="submit"
                    value="Submit"
                    onClick={() => handleSubmit()}
                  >
                    UPDATE
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

EditOnlineBookingsPromoCode.propTypes = {
  history: PropTypes.object
};
