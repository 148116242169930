import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import ChangeSequenceOnlineBookingHomeContent from "views/OnlineBookingHomeContent/ChangeSequenceOnlineHomeContent";
import CreateOnlineBookingHomeContent from "views/OnlineBookingHomeContent/CreateOnlineBookingHomeContent";
import DeleteOnlineBookingHomeContent from "views/OnlineBookingHomeContent/DeleteOnlineBookingHomeContent";
import EditOnlineBookingHomeContent from "views/OnlineBookingHomeContent/EditOnlineBookingHomeContent";
import OnlineBookingHomeContentList from "views/OnlineBookingHomeContent/OnlineBookingHomeContentList";
import ViewOnlineBookingHomeContent from "views/OnlineBookingHomeContent/ViewOnlineBookingHomeContent";

const onlineBookingHomeContent = () => {
  return {
    path: "/OnlineBookingHomeContentList",
    name: "Online Booking Home Contents",
    icon: AddToHomeScreenIcon,
    component: OnlineBookingHomeContentList,
    layout: "/admin"
  };
};

const onlineBookingHomeContentHidden = () => {
  let views = [
    {
      path: "/OnlineBookingHomeContent/Create",
      name: "Create Online Booking Home Content",
      component: CreateOnlineBookingHomeContent,
      layout: "/admin"
    },
    {
      path: "/OnlineBookingHomeContent/View",
      name: "View Online Booking Home Content",
      component: ViewOnlineBookingHomeContent,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/OnlineBookingHomeContent/Edit",
      name: "Edit Online Booking Home Content",
      component: EditOnlineBookingHomeContent,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/OnlineBookingHomeContent/Delete",
      name: "Delete Online Booking Home Content",
      component: DeleteOnlineBookingHomeContent,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/OnlineBookingHomeContent/ChangeSequence",
      name: "Change Online Booking Home Content Sequence",
      component: ChangeSequenceOnlineBookingHomeContent,
      layout: "/admin"
    }
  ];

  return [
    {
      collapse: true,
      name: "Online Booking Home Content Hidden",
      state: "onlineHomeContentHidden",
      hideInSidebar: true,
      views: views
    }
  ];
};

export { onlineBookingHomeContent, onlineBookingHomeContentHidden };
