import React, { useEffect } from "react";
import { MailOutline } from "@material-ui/icons";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import CardBody from "components/Card/CardBody";
import { FormControl, Input, InputLabel, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import Datetime from "react-datetime";
import MerchantDialog from "components/MerchantDialog/MerchantDialog";
import { bookingApi } from "functions/apis";
import { useParams } from "react-router-dom";
import RichTextComponent from "./SlateFormV2";
import { deserializeHtmlString } from "functions/Helper";

const useStyles = makeStyles(styles);

const merchantPromoType = ["Claimable", "System Generated"];

const merchantPromoValidityType = ["Month", "Week", "Day"];

const merchantPromoGenerationEvent = ["New User", "First Booking", "Birthday"];

const usagePeriodCalculation = ["By ClaimDate", "Fixed"];

export default function MerchantPromoDetail(props) {
  const classes = useStyles();
  const [body, setBody] = React.useState();
  const source = React.useRef(new AbortController());
  const [showModal, setShowModal] = React.useState(false);
  const [merchantName, setMerchantName] = React.useState(null);
  const [isEmptyTerms, setIsEmptyTerms] = React.useState(false);

  let { id } = useParams();

  useEffect(() => {
    bookingApi
      .get("/MerchantPromos/" + id, {
        signal: source.current.signal
      })
      .then(data => {
        setBody(data);
        setMerchantName(data.merchant.name);
        if (!data.termsAndConditions) {
          setIsEmptyTerms(true);
        }
      })
      .catch(error => console.log(error));
    return () => source.current.abort();
  }, []);

  const handleMerchantIDChange = e => {
    setShowModal(false);
    setMerchantName(e.name);
    console.log(body);
  };

  return (
    <GridContainer>
      {body && (
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Merchant Promo Detail</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={() => {}}>
                <CustomInput
                  labelText="Title"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    value: body.title ?? ""
                  }}
                />
                <CustomInput
                  labelText="Description"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    value: body.description ?? ""
                  }}
                />

                <CustomInput
                  labelText="Category"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    value: body.category ? body.category.name : ""
                  }}
                />

                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <FormControl
                      disabled
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selAgent"
                        className={classes.selectLabel}
                      >
                        Merchant
                      </InputLabel>
                      <Input
                        disabled
                        classes={{
                          input: classes.input,
                          disabled: classes.active,
                          root: classes.active,
                          underline: classes.underline
                        }}
                        value={merchantName ?? ""}
                        onClick={() => setShowModal(true)}
                        fullWidth
                      />
                    </FormControl>
                    <MerchantDialog
                      openModal={showModal}
                      setOpenModal={val => setShowModal(val)}
                      onSelect={handleMerchantIDChange}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <p>Terms And Conditions</p>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                {isEmptyTerms ? (
                  <GridItem xs={12} md={12}>
                    <div style={{ borderBottom: "1px solid #d6d6d6" }}>
                      <RichTextComponent
                        disable={true}
                        onChange={() => {}}
                        isCreate={true}
                        value={
                          body?.termsAndConditions != null &&
                          body?.termsAndConditions != ""
                            ? deserializeHtmlString(body.termsAndConditions)
                            : null
                        }
                      />
                    </div>
                  </GridItem>
                ) : (
                  body.termsAndConditions &&
                  body.termsAndConditions != "" && (
                    <GridItem xs={12} md={12}>
                      <div style={{ borderBottom: "1px solid #d6d6d6" }}>
                        <RichTextComponent
                          disable={true}
                          onChange={() => {}}
                          value={
                            body.termsAndConditions != null
                              ? deserializeHtmlString(body.termsAndConditions)
                              : null
                          }
                        />
                      </div>
                    </GridItem>
                  )
                )}
                <CustomInput
                  labelText="Banner"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    value: body.banner ?? ""
                  }}
                />
                <CustomInput
                  labelText="Limit"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    type: "number",
                    value: body.limit ?? ""
                  }}
                />
                <CustomInput
                  labelText="Claimable Limit"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    type: "number",
                    value: body.claimableLimit ?? ""
                  }}
                />
                <CustomInput
                  labelText="Usage per user"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    type: "number",
                    value: body.usagePerUser ?? ""
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl fullWidth disabled>
                      <Datetime
                        disabled
                        value={new Date(body.effectiveDate + "Z")}
                        timeFormat={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "Effective Date (UTC)"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl fullWidth disabled>
                      <Datetime
                        disabled
                        value={new Date(body.expiryDate + "Z")}
                        timeFormat={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "Expiry Date (UTC)"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <CustomInput
                  labelText="Usage Period Calculation Type"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    value:
                      body.usagePeriodCalculationType != null
                        ? usagePeriodCalculation[
                            body.usagePeriodCalculationType
                          ]
                        : ""
                  }}
                />
                {body && body.usagePeriodCalculationType == 0 ? (
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText="Validity Type"
                        formControlProps={{
                          fullWidth: true
                        }}
                        disabled
                        inputProps={{
                          value:
                            body.usageValidityType != null
                              ? merchantPromoValidityType[
                                  body.usageValidityType
                                ]
                              : ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText="Validity"
                        formControlProps={{
                          fullWidth: true
                        }}
                        disabled
                        inputProps={{
                          type: "number",
                          value:
                            body.usageValidity != null ? body.usageValidity : ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  body &&
                  body.usagePeriodCalculationType == 1 && (
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <FormControl fullWidth disabled>
                          <Datetime
                            disabled
                            value={new Date(body.usageEffectiveDate + "Z")}
                            timeFormat={true}
                            closeOnSelect={true}
                            inputProps={{
                              placeholder: "Usage Effective Date (UTC)"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <FormControl fullWidth disabled>
                          <Datetime
                            disabled
                            value={new Date(body.usageExpiryDate + "Z")}
                            timeFormat={true}
                            closeOnSelect={true}
                            inputProps={{
                              placeholder: "Usage Expiry Date (UTC)"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  )
                )}

                <CustomInput
                  labelText="Type"
                  formControlProps={{
                    fullWidth: true
                  }}
                  disabled
                  inputProps={{
                    value: body.type != null ? merchantPromoType[body.type] : ""
                  }}
                />
                {body && body.type === 1 && (
                  <CustomInput
                    labelText="Generation Event"
                    formControlProps={{
                      fullWidth: true
                    }}
                    disabled
                    inputProps={{
                      value:
                        body.generationEvent != null
                          ? merchantPromoGenerationEvent[body.generationEvent]
                          : ""
                    }}
                  />
                )}
              </form>
            </CardBody>
          </Card>
        </GridItem>
      )}
    </GridContainer>
  );
}

MerchantPromoDetail.propTypes = {
  history: PropTypes.object
};
