import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { creditMonitoringApi } from "functions/apis";
import { Input } from "@material-ui/core";
import AgentDialog from "components/AgentDialog/AgentDialog";

const useStyles = makeStyles(styles);

export default function CreateAdjustment(props) {
  const source = useRef(new AbortController());

  const [selectedAgentCreditData, setSelectedAgentCreditData] = React.useState(
    null
  );

  const [showModal, setShowModal] = useState(false);
  const [agentName, setAgentName] = useState(null);

  const handleAgentIDChanged = e => {
    setState({ ...state, agentID: e.id });
    setShowModal(false);
    setAgentName(e.name);
    creditMonitoringApi
      .get("Agents/" + e.id, {
        signal: source.current.signal
      })
      .then(data => setSelectedAgentCreditData(data))
      .catch(error => console.log(error));
  };

  const handleNoteChanged = e =>
    setState({ ...state, note: e.target.value === "" ? null : e.target.value });

  const handleBalanceChanged = e =>
    setState({ ...state, balance: e.target.value });

  const [state, setState] = React.useState({
    agentID: null,
    balance: 0,
    note: null
  });

  const classes = useStyles();

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    creditMonitoringApi
      .post(
        "/Adjustments",
        {
          ...state,
          balance: parseFloat(state.balance)
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  return redirect ? (
    <Redirect to={"/admin/AdjustmentList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CREATE ADJUSTMENT</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selNationality"
                  className={classes.selectLabel}
                >
                  Agent
                </InputLabel>
                <Input
                  onClick={() => setShowModal(prev => !prev)}
                  className={classes.select}
                  value={agentName ?? ""}
                  disabled
                />
                <AgentDialog
                  openModal={showModal}
                  setOpenModal={val => setShowModal(val)}
                  onSelect={handleAgentIDChanged}
                />
              </FormControl>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Currency"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value:
                        selectedAgentCreditData === null ||
                        selectedAgentCreditData.billingCurrency === null
                          ? ""
                          : selectedAgentCreditData.billingCurrency.code
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Amount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handleBalanceChanged,
                      value: state.balance
                    }}
                  />
                </GridItem>
              </GridContainer>
              <CustomInput
                labelText="Note"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNoteChanged,
                  value: state.note === null ? "" : state.note,
                  multiline: true
                }}
              />
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateAdjustment.propTypes = {
  history: PropTypes.object
};
