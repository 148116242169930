import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";

import RoleList from "views/Security/Role/RoleList";
import CreateRole from "views/Security/Role/CreateRole";
import DeleteRole from "views/Security/Role/DeleteRole";
import RoleDetail from "views/Security/Role/RoleDetail";

import UserList from "views/Security/User/UserList";
import CreateUser from "views/Security/User/CreateUser";
import DeleteUser from "views/Security/User/DeleteUser";
import UpdateLoginUser from "views/Security/User/UpdateLoginUser";
import ResetUserPassword from "views/Security/User/ResetUserPassword";
import UserDetail from "views/Security/User/UserDetail";

import { token } from "functions/Helper";

const security = () => {
  let views = [];
  if (
    token["admin.users.read"] === "allowed" ||
    token.is_super_admin === "True"
  )
    views.push({
      path: "/UserList",
      name: "Users",
      icon: PersonIcon,
      component: UserList,
      layout: "/admin"
    });
  if (
    token["admin.roles.read"] === "allowed" ||
    token.is_super_admin === "True"
  )
    views.push({
      path: "/RoleList",
      name: "Roles",
      icon: GroupIcon,
      component: RoleList,
      layout: "/admin"
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Security",
        icon: LockIcon,
        state: "securityCollapse",
        views: views
      }
    ];
  return [];
};

const userHidden = () => {
  let views = [];
  if (
    token["admin.users.read"] === "allowed" ||
    token.is_super_admin === "True"
  )
    views.push({
      path: "/Users/Detail",
      name: "User Detail",
      component: UserDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["admin.users.write"] === "allowed" ||
    token.is_super_admin === "True"
  )
    views = views.concat([
      {
        path: "/Users/Create",
        name: "Create User",
        component: CreateUser,
        layout: "/admin"
      },
      {
        path: "/Users/Delete",
        name: "Delete User",
        component: DeleteUser,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Users/UpdateLogin",
        name: "Update User Login",
        component: UpdateLoginUser,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Users/ResetPassword",
        name: "Reset User Password",
        component: ResetUserPassword,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "User Hidden",
        state: "userHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

const roleHidden = () => {
  let views = [];
  if (
    token["admin.roles.read"] === "allowed" ||
    token.is_super_admin === "True"
  )
    views.push({
      path: "/Roles/Detail",
      name: "Role Detail",
      component: RoleDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["admin.roles.write"] === "allowed" ||
    token.is_super_admin === "True"
  )
    views = views.concat([
      {
        path: "/Roles/Create",
        name: "Create Role",
        component: CreateRole,
        layout: "/admin"
      },
      {
        path: "/Roles/Delete",
        name: "Delete Role",
        component: DeleteRole,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Role Hidden",
        state: "roleHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { security, userHidden, roleHidden };
