import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import PublishIcon from "@material-ui/icons/Publish";
import VisibilityIcon from "@material-ui/icons/Visibility";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { travelApi } from "functions/apis";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import MerchantDialog from "components/MerchantDialog/MerchantDialog";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function PublishedMerchantProductList() {
  const publishedMerchantProductListSignal = useRef(new AbortController());
  useEffect(() => {
    return () => {
      publishedMerchantProductListSignal.current.abort();
    };
  }, []);

  const [data, setData] = React.useState([]);
  const [merchantName, setMerchantName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null,
    publicationStatus: 1,
    merchantID: null
  });
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    travelApi
      .get("/products/ferries", {
        signal: publishedMerchantProductListSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link to={"/admin/MerchantFerryProducts/Detail/" + record.id}>
                    <Button color="info" className="like">
                      <VisibilityIcon /> VIEW
                    </Button>
                  </Link>
                  <Link
                    to={"/admin/MerchantFerryProducts/Deactivate/" + record.id}
                  >
                    <Button color="danger" className="like">
                      <PublishIcon /> DEACTIVATE
                    </Button>
                  </Link>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const handleMerchantIDChange = e => {
    setFilter({ ...filter, merchantID: e.id });
    setMerchantName(e.name);
    setShowModal(false);
  };

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {`MERCHANT FERRY'S PRODUCTS - PUBLISHED`}
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selAgent"
                    className={classes.selectLabel}
                  >
                    Merchant
                  </InputLabel>
                  <Input
                    classes={{
                      input: classes.input,
                      disabled: classes.active,
                      root: classes.active,
                      underline: classes.underline
                    }}
                    value={merchantName ?? ""}
                    onClick={() => setShowModal(true)}
                    fullWidth
                  />
                </FormControl>
                <MerchantDialog
                  openModal={showModal}
                  setOpenModal={val => setShowModal(val)}
                  onSelect={handleMerchantIDChange}
                />
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  labelText="Search"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleSearchStringChanged,
                    value:
                      filter.searchString === null ? "" : filter.searchString
                  }}
                />
              </GridItem>
            </GridContainer>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "Code",
                  accessor: "code"
                },
                {
                  Header: "Name",
                  accessor: "name"
                },
                {
                  Header: "Merchant",
                  id: "merchantName",
                  accessor: d => d.merchant.name
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
