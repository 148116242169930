import React, { useEffect } from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import ReactTable from "react-table";

import { bookingApi } from "functions/apis";
import { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { GetPolicyActions } from "enums/PolicyAction";
import { GetFeeCalculationTypes } from "enums/FeeCalculationType";
import DeleteIcon from "@material-ui/icons/Delete";
import { GetPolicyActionDescription } from "enums/PolicyAction";
import { GetFeeCalculationTypeDescription } from "enums/FeeCalculationType";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const defaultState = {
  minimumDaysBeforeDeparture: 0,
  action: 0,
  calculationType: 0,
  percentage: 0,
  fixedIDRAmount: 0,
  fixedSGDAmount: 0
};
const useStyles = makeStyles(styles);

export default function FeePolicyDetailList(props) {
  const { id, status } = props;
  const feePolicyDetailListSignal = React.useRef(new AbortController());

  useEffect(() => {
    getData();
    return () => {
      feePolicyDetailListSignal.current.abort();
    };
  }, []);
  const [data, setData] = React.useState([]);
  const [state, setState] = useState(defaultState);
  const [reload, setReload] = React.useState({});
  const [pages, setPages] = React.useState(0);
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });

  const getData = () => {
    bookingApi
      .get(`/FeePolicies/${id}/Details`, {
        signal: feePolicyDetailListSignal.current.signal,
        params: {
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  {status === 0 && (
                    <Button
                      color="danger"
                      className="remove"
                      onClick={() => {
                        bookingApi
                          .delete(`/FeePolicies/${id}/Details/${record.id}`, {
                            signal: feePolicyDetailListSignal.current.signal
                          })
                          .then(() => {
                            setReload({});
                          })
                          .catch(error => {
                            console.log(error);
                          });
                      }}
                    >
                      <DeleteIcon /> DELETE
                    </Button>
                  )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getData();
  }, [pagination, reload]);

  useEffect(() => {
    bookingApi
      .get(`/FeePolicies/${id}/Details`, {
        signal: feePolicyDetailListSignal.current.signal
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => console.log(error));
  }, [reload]);

  useEffect(() => {
    if (state.calculationType != "") {
      if (state.calculationType === "Fixed") {
        setState({ ...state, percentage: 0 });
      } else {
        setState({ ...state, fixedIDRAmount: 0, fixedSGDAmount: 0 });
      }
    }
  }, [state.calculationType]);

  const handleMinimumBeforeDepartureChanged = e =>
    setState({ ...state, minimumDaysBeforeDeparture: e.target.value });

  const handlePolicyActionChanged = e =>
    setState({ ...state, action: e.target.value });

  const handleFeeCalculationTypeChanged = e =>
    setState({ ...state, calculationType: e.target.value });

  const handleFixedSGDChanged = e =>
    setState({ ...state, fixedSGDAmount: e.target.value });

  const handleFixedIDRChanged = e =>
    setState({ ...state, fixedIDRAmount: e.target.value });

  const handlePercentageChanged = e =>
    setState({ ...state, percentage: e.target.value });

  const handleSubmit = () => {
    setDisabled(true);
    bookingApi
      .post(`/FeePolicies/${id}/Details`, state, {
        signal: feePolicyDetailListSignal.current.signal
      })
      .then(() => {
        setReload({});
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setState(defaultState);
        setDisabled(false);
      });
  };
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>FEE POLICY DETAIL</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <CustomInput
              disabled
              labelText="Minimum Days Before Departure"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: handleMinimumBeforeDepartureChanged,
                value: state.minimumDaysBeforeDeparture ?? "",
                type: "number"
              }}
            />
          </GridItem>
          <GridItem xs={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="selAction" className={classes.selectLabel}>
                ACTION
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={state.action ?? ""}
                onChange={handlePolicyActionChanged}
              >
                {GetPolicyActions().map(record => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={record.key}
                      key={record.key}
                    >
                      <p>{record.description}</p>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          {state.action === 1 && (
            <GridItem xs={12} md={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCalculationType"
                  className={classes.selectLabel}
                >
                  CALCULATION TYPE
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={state.calculationType ?? ""}
                  onChange={handleFeeCalculationTypeChanged}
                >
                  {GetFeeCalculationTypes().map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.key}
                        key={record.key}
                      >
                        <p>{record.description}</p>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
          )}
          {state.action === 1 && state.calculationType === 0 && (
            <>
              <GridItem xs={12} md={6}>
                <CustomInput
                  disabled
                  labelText="Fixed IDR Amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleFixedIDRChanged,
                    value: state.fixedIDRAmount ?? "",
                    type: "number"
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  disabled
                  labelText="Fixed SGD Amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleFixedSGDChanged,
                    value: state.fixedSGDAmount ?? "",
                    type: "number"
                  }}
                />
              </GridItem>
            </>
          )}
          {state.action === 1 && state.calculationType === 1 && (
            <GridItem xs={12} md={12}>
              <CustomInput
                disabled
                labelText="Percentage"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handlePercentageChanged,
                  value: state.percentage ?? "",
                  type: "number"
                }}
              />
            </GridItem>
          )}
          {status === 0 && (
            <GridItem xs={12} md={12}>
              <Button
                disabled={disabled}
                onClick={() => handleSubmit()}
                color="rose"
                type="submit"
                value="Submit"
              >
                ADD
              </Button>
            </GridItem>
          )}
        </GridContainer>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={data}
          filterable={false}
          columns={[
            {
              Header: "Minimum Day Before Departure",
              accessor: "minimumDaysBeforeDeparture"
            },
            {
              id: "action",
              Header: "Action",
              accessor: d => GetPolicyActionDescription(d.action)
            },
            {
              id: "calculationType",
              Header: "Calculation Type",
              accessor: d => GetFeeCalculationTypeDescription(d.calculationType)
            },
            {
              Header: "Percentage",
              accessor: "percentage"
            },
            {
              Header: "Fixed IDR Amount",
              accessor: "fixedIDRAmount"
            },
            {
              Header: "Fixed SGD Amount",
              accessor: "fixedSGDAmount"
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false
            }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

FeePolicyDetailList.propTypes = {
  id: PropTypes.string,
  status: PropTypes.number,
  isDelete: PropTypes.bool
};
