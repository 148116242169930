import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { adminApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function Index() {
  const classes = useStyles();
  const abortController = React.useRef(new AbortController());
  useEffect(() => {
    return () => abortController.current.abort();
  }, []);

  const handleOpenReport = e => {
    e.preventDefault();
    adminApi
      .get("Profile/ReportToken", {
        signal: abortController.current.signal
      })
      .then(data => {
        window.open(
          process.env.REACT_APP_REPORTING_URL +
            "?pp=" +
            encodeURI("admin|" + data) +
            "&userTimezone=" +
            encodeURI(Intl.DateTimeFormat().resolvedOptions().timeZone)
        );
      })
      .catch(error => console.log(error));
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Report</h4>
          </CardHeader>
          <CardBody>
            <Button
              color="rose"
              type="button"
              value="View"
              onClick={handleOpenReport}
            >
              View Reports
            </Button>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
