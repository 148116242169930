import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import OnlineBookingPricingList from "views/OnlineBookingPricing/OnlineBookingPricingList.js";
import CreateOnlineBookingPricing from "views/OnlineBookingPricing/CreateOnlineBookingPricing.js";

import { token } from "functions/Helper";

const onlineBookingPricing = () => {
  let views = [];
  if (
    token["booking.online_booking_types.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/OnlineBookingPricing/List",
      name: "Online Booking Pricing",
      icon: MonetizationOnIcon,
      component: OnlineBookingPricingList,
      layout: "/admin"
    });
  return views;
};

const onlineBookingPricingHidden = () => {
  let views = [];
  if (
    token["booking.online_booking_types.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/OnlineBookingPricing/Create",
        name: "Create Online Booking Pricing",
        component: CreateOnlineBookingPricing,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "BookingType Hidden",
        state: "bookingTypeHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { onlineBookingPricing, onlineBookingPricingHidden };
