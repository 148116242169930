import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Create from "views/FeePolicy/Create";
import Deactivate from "views/FeePolicy/Deactivate";
import DeactivatedList from "views/FeePolicy/DeactivatedList";
import Delete from "views/FeePolicy/Delete";
import Edit from "views/FeePolicy/Edit";
import Detail from "views/FeePolicy/Detail";
import InDraftList from "views/FeePolicy/InDraftList";
import Publish from "views/FeePolicy/Publish";
import PublishedList from "views/FeePolicy/PublishedList";

const feePolicy = () => {
  return [
    {
      collapse: true,
      name: "Fee Policy",
      icon: MonetizationOnIcon,
      state: "feePolicyCollapse",
      views: [
        {
          path: "/FeePolicy/InDraft",
          name: "In Draft",
          mini: "IN",
          component: InDraftList,
          layout: "/admin"
        },
        {
          path: "/FeePolicy/Published",
          name: "Published",
          mini: "PB",
          component: PublishedList,
          layout: "/admin"
        },
        {
          path: "/FeePolicy/Deactivated",
          name: "Deactivate",
          mini: "DE",
          component: DeactivatedList,
          layout: "/admin"
        }
      ]
    }
  ];
};

const feePolicyDetailHidden = () => {
  return [
    {
      collapse: true,
      name: "Fee Policy Hidden",
      state: "feePolicyHidden",
      hideInSidebar: true,
      views: [
        {
          path: "/FeePolicy/Create",
          name: "Create Fee Policy",
          component: Create,
          layout: "/admin"
        },
        {
          path: "/FeePolicy/Edit",
          name: "Edit Fee Policy",
          component: Edit,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/FeePolicy/Delete",
          name: "Delete Fee Policy",
          component: Delete,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/FeePolicy/Publish",
          name: "Publish Fee Policy",
          component: Publish,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/FeePolicy/Detail",
          name: "FeePolicy",
          component: Detail,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/FeePolicy/Deactivate",
          name: "DeactivateFeePolicy",
          component: Deactivate,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        }
      ]
    }
  ];
};

export { feePolicyDetailHidden, feePolicy };
