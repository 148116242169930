import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { creditMonitoringApi } from "functions/apis";

import { formatCurrency, formatDateTime, token } from "functions/Helper";
import { Input } from "@material-ui/core";
import AgentDialog from "components/AgentDialog/AgentDialog";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function CommissionList() {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const [showModal, setShowModal] = React.useState(false);
  const [agentName, setAgentName] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: null,
    sort: 7, //TransactionTime DESC
    agentID: null
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    creditMonitoringApi
      .get("/Commissions", {
        signal: source.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(data.records);
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const handleAgentIDChanged = e => {
    setAgentName(e.name);
    setShowModal(false);
    setFilter({
      ...filter,
      agentID: e.id === "" ? null : e.id
    });
  };

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Commission</h4>
          </CardHeader>
          <CardBody>
            {(token["creditmonitoring.commissions.write"] === "allowed" ||
              token.is_admin === "True") && (
              <Link to={"/admin/Commission/Create"}>
                <Button color="rose" type="button" value="Create">
                  Create Commission
                </Button>
              </Link>
            )}
            <GridItem xs={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="selAgent" className={classes.selectLabel}>
                  Agent
                </InputLabel>
                <Input
                  onClick={() => setShowModal(prev => !prev)}
                  className={classes.select}
                  value={agentName ?? ""}
                  disabled
                />
                <AgentDialog
                  openModal={showModal}
                  setOpenModal={val => setShowModal(val)}
                  onSelect={handleAgentIDChanged}
                />
              </FormControl>
            </GridItem>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "No",
                  accessor: "no"
                },
                {
                  id: "createdAt",
                  Header: "Created At",
                  accessor: d => formatDateTime(new Date(d.createdAt + "Z"))
                },
                {
                  id: "agent",
                  Header: "Agent",
                  accessor: d => d.agent.code
                },
                {
                  id: "commissionType",
                  Header: "Type",
                  accessor: d => d.commissionType.description
                },
                {
                  id: "amount",
                  Header: "Amount",
                  accessor: d =>
                    formatCurrency(
                      d.agent.billingCurrency === null
                        ? "XXX"
                        : d.agent.billingCurrency.code,
                      d.amount
                    )
                },
                {
                  Header: "Note",
                  accessor: "note"
                },
                {
                  Header: "Created By",
                  headerStyle: { textAlign: "left" },
                  accessor: "createdBy"
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
