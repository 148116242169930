import FaceIcon from "@material-ui/icons/Face";

import Profile from "views/Profile/Profile";
import Notification from "views/Profile/Notification";

const profile = () => {
  let views = [];
  views.push({
    path: "/MyProfile",
    name: "Profile",
    icon: FaceIcon,
    component: Profile,
    layout: "/admin"
  });
  return views;
};

const profileHidden = () => {
  return [
    {
      collapse: true,
      name: "Notification",
      state: "profileHidden",
      hideInSidebar: true,
      views: [
        {
          path: "/MyNotification",
          name: "My Notification",
          component: Notification,
          layout: "/admin"
        }
      ]
    }
  ];
};
export { profile, profileHidden };
