export function GetCoreApiCheckInCancellationSources() {
  return [
    {
      key: 0,
      description: "Booking Cancellation"
    },
    {
      key: 1,
      description: "Booking Transfer"
    }
  ];
}

export function GetCoreApiCheckInCancellationSourceDescription(value) {
  if (value === null) return "";
  let cancellationSource = GetCoreApiCheckInCancellationSources().find(
    item => item.key === value
  );
  return cancellationSource.description;
}
