import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Check from "@material-ui/icons/Check";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { adminApi } from "functions/apis";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function Profile() {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const [user, setUser] = React.useState(null);

  useEffect(() => {
    adminApi
      .get("/Profile", {
        signal: source.current.signal
      })
      .then(data => setUser(data))
      .catch(error => console.log(error));
    return () => source.current.abort();
  }, []);

  const classes = useStyles();
  return (
    user !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>PROFILE</h4>
            </CardHeader>
            <CardBody>
              <Link to={"/admin/Profile/ChangePassword"}>
                <Button color="rose" type="button" value="Create">
                  Change Password
                </Button>
              </Link>
              <CustomInput
                labelText="UserName"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: user.userName === null ? "" : user.userName
                }}
              />
              <CustomInput
                labelText="Email"
                disabled
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: user.email === null ? "" : user.email
                }}
              />
              <GridItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.isSuperAdmin}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Is Super Admin"
                />
              </GridItem>
              <GridItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={user.isAdmin}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Is Admin"
                />
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}
