import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CreatePeakHourTrips from "views/PeakHourTrips/CreatePeakHourTrips";
import DeactivatedPeakHourTripsList from "views/PeakHourTrips/DeactivatedPeakHourTrips";
import deletePeakHourTrips from "views/PeakHourTrips/DeletePeakHourTrips";
import EditPeakHourTrips from "views/PeakHourTrips/EditPeakHourTrips";
import InDraftPeakHourTripsList from "views/PeakHourTrips/InDraftPeakHourTrips";
import PublishedPeakHourTripsList from "views/PeakHourTrips/PublishedPeakHourTrips";

const peakHourTrip = () => {
  let views = [
    {
      path: "/PeakHourTripsList/InDraft",
      name: "In Draft",
      mini: "ID",
      component: InDraftPeakHourTripsList,
      layout: "/admin"
    },
    {
      path: "/PeakHourTripsList/Published",
      name: "Published",
      mini: "PU",
      component: PublishedPeakHourTripsList,
      layout: "/admin"
    },
    {
      path: "/PeakHourTripsList/Deactivated",
      name: "Deactivated",
      mini: "DE",
      component: DeactivatedPeakHourTripsList,
      layout: "/admin"
    }
  ];

  return [
    {
      collapse: true,
      name: "Peak Hour Trips",
      icon: MonetizationOnIcon,
      state: "PeakHourCollapse",
      views: views
    }
  ];
};

const peakHourTripHidden = () => {
  return [
    {
      collapse: true,
      name: "Peak Hidden",
      state: "peakHidden",
      hideInSidebar: true,
      views: [
        {
          path: "/PeakHourTrips/Create",
          name: "Create Peak Hour Trips",
          component: CreatePeakHourTrips,
          layout: "/admin"
        },
        {
          path: "/PeakHourTrips/Edit",
          name: "Edit Peak Hour Trips",
          component: EditPeakHourTrips,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/PeakHourTrips/Delete",
          name: "Delete Peak Hour Trips",
          component: deletePeakHourTrips,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        }
      ]
    }
  ];
};

export { peakHourTrip, peakHourTripHidden };
