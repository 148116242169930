import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { Redirect } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { bookingApi } from "functions/apis";

const useStyles = makeStyles(styles);

const CreateAppsNotificationSection = props => {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [input, setInput] = useState({
    name: ""
  });

  const createAppsNotificationSectionSignal = useRef(new AbortController());

  useEffect(() => {
    return () => createAppsNotificationSectionSignal.current.abort();
  }, []);

  const handleNameChanged = e =>
    setInput({
      ...input,
      name: e.target.value === "" ? null : e.target.value
    });

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post("appsNotificationSections", input, {
        signal: createAppsNotificationSectionSignal.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/AppsNotificationSection/List"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Create Apps Notification Section
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Section Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNameChanged,
                  value: input.name
                }}
              />
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateAppsNotificationSection;

CreateAppsNotificationSection.propTypes = {
  history: PropTypes.object
};
