import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import { counterIssuanceApi } from "functions/apis";

import { formatCurrency, formatDateTime } from "functions/Helper";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function CounterVoucherTypeList(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const { id, currency } = props;
  const [reload, setReload] = React.useState({});
  const [voucherTypes, setVoucherTypes] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });
  const [filter, setFilter] = React.useState({
    searchString: null
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    counterIssuanceApi
      .get("/Counters/" + id + "/VoucherTypes", {
        signal: source.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setVoucherTypes(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  {(token["counterissuance.counter_voucher_types.write"] ===
                    "allowed" ||
                    token.is_admin === "True") && (
                    <Button
                      color="danger"
                      className="remove"
                      onClick={() =>
                        counterIssuanceApi
                          .delete(
                            "/Counters/" + id + "/VoucherTypes/" + record.id,
                            {
                              signal: source.current.signal
                            }
                          )
                          .then(() => setReload({}))
                          .catch(error => console.log(error))
                      }
                    >
                      <DeleteIcon /> DELETE
                    </Button>
                  )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [id, pagination, filter, reload]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      searchString: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">
          <Assignment />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>VOUCHER TYPES</h4>
      </CardHeader>
      <CardBody>
        {(token["counterissuance.counter_voucher_types.write"] === "allowed" ||
          token.is_admin === "True") && (
          <Link to={"/admin/Counter/CreateVoucherType/" + id}>
            <Button color="rose" type="button" value="Create">
              Add Voucher Type
            </Button>
          </Link>
        )}
        <GridItem xs={3}>
          <CustomInput
            labelText="Search"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: handleSearchStringChanged,
              value: filter.searchString === null ? "" : filter.searchString
            }}
          />
        </GridItem>
        <ReactTable
          manual
          pages={pages}
          page={pagination.pageIndex - 1}
          pageSize={pagination.pageSize}
          onPageChange={page =>
            setPagination({
              pageIndex: page + 1,
              pageSize: pagination.pageSize
            })
          }
          onPageSizeChange={(pageSize, page) => {
            setPagination({ pageSize: pageSize, pageIndex: page + 1 });
          }}
          loading={loading}
          data={voucherTypes}
          filterable={false}
          columns={[
            {
              id: "voucherType",
              Header: "Voucher Type",
              accessor: d => d.voucherType.code
            },
            {
              id: "billingPrice",
              Header: "Billing Price",
              accessor: d =>
                formatCurrency(
                  currency === null ? "XXX" : currency.code,
                  d.billingPrice
                )
            },
            {
              id: "createdAt",
              Header: "Created At",
              accessor: d => formatDateTime(new Date(d.createdAt + "Z"))
            },
            {
              Header: "Actions",
              accessor: "actions",
              sortable: false,
              filterable: false
            }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        />
      </CardBody>
    </Card>
  );
}

CounterVoucherTypeList.propTypes = {
  id: PropTypes.string,
  currency: PropTypes.object
};
