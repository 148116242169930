import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { orderApi } from "functions/apis";

export default function ViewOrder(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const { id } = props;

  const [order, setOrder] = React.useState(null);
  useEffect(() => {
    orderApi
      .get("/Orders/" + id, {
        signal: source.current.signal
      })
      .then(data => setOrder(data))
      .catch(error => console.log(error));
  }, [id]);

  return (
    order !== null && (
      <>
        <CustomInput
          disabled
          labelText="No"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: order.no
          }}
        />
        <CustomInput
          disabled
          labelText="Agent"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: order.agent.name
          }}
        />
        <CustomInput
          disabled
          labelText="Voucher Type"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: order.voucherType.code
          }}
        />
        <CustomInput
          disabled
          labelText="Quantity"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            disabled: true,
            value: order.quantity.toLocaleString()
          }}
        />
        <GridContainer>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              disabled
              labelText="Currency"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value:
                  order.agent.billingCurrency === null
                    ? ""
                    : order.agent.billingCurrency.code
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              disabled
              labelText="Unit Price"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: order.unitPrice.toLocaleString()
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              disabled
              labelText="Total"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: order.total.toLocaleString()
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={3} md={3}>
            <CustomInput
              disabled
              labelText="GST"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: order.gst.toLocaleString()
              }}
            />
          </GridItem>
        </GridContainer>
      </>
    )
  );
}

ViewOrder.propTypes = {
  id: PropTypes.string
};
