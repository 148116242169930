import React from "react";
import { makeStyles } from "@material-ui/core";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridItem from "components/Grid/GridItem";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import MailOutline from "@material-ui/icons/MailOutline";
import GridContainer from "components/Grid/GridContainer";
import { formatDate } from "functions/Helper";

// import CloseIcon from '@mui/icons-material/Close';
const productType = ["Specific Date", "Date Range"];

const useStyles = makeStyles(styles);

export default function ViewMerchantAgentBookingDetail(props) {
  const classes = useStyles();
  const { data } = props;

  const [pages, setPages] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [slicedData, setSlicedData] = React.useState([]);

  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 5
  });

  const getDataSliced = () => {
    setLoading(true);
    const tempData = { ...data };

    var dataArr = [];
    dataArr = tempData.paxes.slice(
      (pagination.pageIndex - 1) * pagination.pageSize,
      pagination.pageIndex * pagination.pageSize
    );
    setSlicedData(dataArr);
    setPages(Math.ceil(data.paxes.length / pagination.pageSize));
    setLoading(false);
  };

  React.useEffect(() => {
    getDataSliced();
  }, [pagination]);

  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <MailOutline />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          VIEW MERCHANT AGENT BOOKING DETAIL
        </h4>
      </CardHeader>
      <CardBody>
        <form>
          <GridContainer>
            <GridItem xs={6}>
              <CustomInput
                labelText="Age Variant"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: data.ageVariant,
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText="Date"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: formatDate(new Date(data.startDate)),
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText="Product Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: data.product ? data.product.code : "-",
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText="Product Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: data.product ? data.product.name : "-",
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText="Product Type"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: data.product ? productType[data.product.type] : "-",
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText="Quantity"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: data.quantity,
                  disabled: true
                }}
              />
            </GridItem>

            <GridItem xs={6}>
              <CustomInput
                labelText="Unit Price"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: data.unitPrice,
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                labelText="Total"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: data.total,
                  disabled: true
                }}
              />
            </GridItem>
          </GridContainer>

          <ReactTable
            manual
            pages={pages}
            page={pagination.pageIndex - 1}
            pageSize={pagination.pageSize}
            onPageChange={page =>
              setPagination({
                pageIndex: page + 1,
                pageSize: pagination.pageSize
              })
            }
            onPageSizeChange={(pageSize, page) => {
              setPagination({
                pageSize: pageSize,
                pageIndex: page + 1
              });
            }}
            loading={loading}
            data={slicedData}
            filterable={false}
            columns={[
              {
                Header: "Passport No",
                id: "identityNo",
                accessor: d => d.identityNo
              },
              {
                Header: "FullName",
                accessor: "fullName"
              },
              {
                Header: "Gender",
                id: "gender",
                accessor: d => (d.gender == 0 ? "Male" : "Female")
              },
              {
                Header: "Place Of Birth",
                accessor: "placeOfBirth"
              },
              {
                Header: "Date Of Birth",
                id: "dateOfBirth",
                accessor: d => formatDate(new Date(d.dateOfBirth))
              },
              {
                Header: "Issuance Country",
                id: "issuanceCountry",
                accessor: d => d.issuanceCountry.name
              },
              {
                Header: "Issuance Date",
                id: "issuanceDate",
                accessor: d => formatDate(d.issueDate)
              },
              {
                Header: "Nationality",
                id: "nationality",
                accessor: d => d.issuanceCountry.code
              }
            ]}
            defaultPageSize={10}
            showPaginationTop
            showPaginationBottom={false}
            className="-striped -highlight"
          />
        </form>
      </CardBody>
    </Card>
  );
}

ViewMerchantAgentBookingDetail.propTypes = {
  data: PropTypes.object
};
