import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import VisibilityIcon from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { token } from "functions/Helper";
import { travelApi } from "functions/apis";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { formatDateTime } from "functions/Helper";
import ControlGroupDialog from "components/ControlGroupDialog/ControlGroupDialog";
import MerchantProductInventoryDialog from "components/MerchantProductInventoryDialog/MerchantProductInventoryDialog";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function MerchantInventoriesList() {
  const merchantInventoriesListSignal = useRef(new AbortController());
  useEffect(() => {
    return () => {
      merchantInventoriesListSignal.current.abort();
    };
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    controlGroupID: null,
    productID: null,
    sort: 0
  });

  const [productData, setProductData] = React.useState({
    id: "",
    name: ""
  });

  useEffect(() => {
    if (productData.id != "") {
      setFilter({ ...filter, productID: productData.id });
    }
  }, [productData]);

  const [showProductModal, setShowProductModal] = React.useState(false);
  const handleProductIDChanged = e => {
    if (e) {
      setProductData({ id: e.id, name: e.name });
      setShowProductModal(false);
    }
  };
  const [loading, setLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [controlGroupData, setControlGroupData] = React.useState({
    id: "",
    name: ""
  });

  const handleControlGroupChanged = e => {
    setShowModal(false);
    setControlGroupData({
      id: e.id,
      name: e.name
    });
  };

  useEffect(() => {
    setFilter({
      ...filter,
      controlGroupID: controlGroupData.id
    });
  }, [controlGroupData]);

  useEffect(() => {
    setFilter({
      ...filter,
      productID: productData.id
    });
  }, [productData]);

  useEffect(() => {
    setLoading(true);
    travelApi
      .get("/inventories", {
        signal: merchantInventoriesListSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link to={"/admin/MerchantInventories/Detail/" + record.id}>
                    <Button color="info" className="like">
                      <VisibilityIcon /> VIEW
                    </Button>
                  </Link>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{`MERCHANT INVENTORIES`}</h4>
          </CardHeader>
          <CardBody>
            {(token["agent.agents.write"] === "allowed" ||
              token.is_admin === "True") && (
              <Link to={"/admin/MerchantInventories/Create"}>
                <Button color="rose" type="button" value="Create">
                  Create Inventories
                </Button>
              </Link>
            )}
            <GridContainer>
              <GridItem xs={6} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selNationality"
                    className={classes.selectLabel}
                  >
                    Control Group
                  </InputLabel>
                  <Input
                    onClick={() => setShowModal(prev => !prev)}
                    className={classes.select}
                    value={controlGroupData.name ?? ""}
                  />
                  <ControlGroupDialog
                    openModal={showModal}
                    setOpenModal={val => setShowModal(val)}
                    onSelect={handleControlGroupChanged}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selAgent"
                    className={classes.selectLabel}
                  >
                    Merchant Product
                  </InputLabel>
                  <Input
                    onClick={() => setShowProductModal(prev => !prev)}
                    className={classes.select}
                    value={productData ? productData.name : ""}
                  />
                  <MerchantProductInventoryDialog
                    openModal={showProductModal}
                    setOpenModal={val => setShowProductModal(val)}
                    onSelect={handleProductIDChanged}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "Control Group Code",
                  id: "controlGroupCode",
                  accessor: d => d.controlGroup.code
                },
                {
                  Header: "Current Balance",
                  accessor: "currentBalance"
                },
                {
                  Header: "Date",
                  id: "date",
                  accessor: d => formatDateTime(new Date(d.date))
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
