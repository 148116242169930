import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { token } from "functions/Helper";

import { bookingApi, masterApi } from "functions/apis";
const useStyles = makeStyles(styles);

export default function CreateBookingType(props) {
  const source = useRef(new AbortController());

  const [nationalityList, setNationalityList] = React.useState([]);
  const [paxTypeList, setPaxTypeList] = React.useState([]);
  const [dayGroupList, setDayGroupList] = React.useState([]);
  const [sectorList, setSectorList] = React.useState([]);

  useEffect(() => {
    if (
      token["master.countries.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/Countries", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 2
            }
          }
        })
        .then(data => setNationalityList(data.records))
        .catch(error => console.log(error));

    if (
      token["master.pax_types.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/PaxTypes", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1
            }
          }
        })
        .then(data => setPaxTypeList(data.records))
        .catch(error => console.log(error));

    if (
      token["master.day_groups.read"] === "allowed" ||
      token.is_admin === "True"
    )
      masterApi
        .get("/DayGroups", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1
            }
          }
        })
        .then(data => setDayGroupList(data.records))
        .catch(error => console.log(error));

    if (token["master.sectors.read"] === "allowed" || token.is_admin === "True")
      masterApi
        .get("/Sectors", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1
            }
          }
        })
        .then(data => setSectorList(data.records))
        .catch(error => console.log(error));
    return () => source.current.abort();
  }, []);
  const handleCodeChanged = e =>
    setState({ ...state, code: e.target.value === "" ? null : e.target.value });

  const handleNameChanged = e =>
    setState({ ...state, name: e.target.value === "" ? null : e.target.value });

  const handleIsRoundTripChanged = e =>
    setState({ ...state, isRoundTrip: e.target.checked });

  const handleIsVTLChanged = e =>
    setState({ ...state, isVTL: e.target.checked });

  const handleDepartureSectorIDChanged = e =>
    setState({ ...state, departureSectorID: e.target.value });

  const handleHasDayGroupRestrictionChanged = e =>
    setState({
      ...state,
      hasDayGroupRestriction: e.target.checked,
      allowedDayGroupID: e.target.checked ? state.allowedDayGroupID : null
    });

  const handleHasPaxTypeRestrictionChanged = e =>
    setState({
      ...state,
      hasPaxTypeRestriction: e.target.checked,
      allowedPaxTypeID: e.target.checked ? state.allowedPaxTypeID : null
    });

  const handleHasNationalityRestrictionChanged = e =>
    setState({
      ...state,
      hasNationalityRestriction: e.target.checked,
      allowedNationalityID: e.target.checked ? state.allowedNationalityID : null
    });

  const handleAllowedPaxTypeIDChanged = e =>
    setState({ ...state, allowedPaxTypeID: e.target.value });

  const handleAllowedNationalityIDChanged = e =>
    setState({ ...state, allowedNationalityID: e.target.value });

  const handleAllowedDayGroupIDChanged = e =>
    setState({ ...state, allowedDayGroupID: e.target.value });

  const handleAdditionalCriteriaStringChanged = e =>
    setState({
      ...state,
      additionalCriteriaString: e.target.value === "" ? null : e.target.value
    });

  const [state, setState] = React.useState({
    code: null,
    name: null,
    isRoundTrip: false,
    isVTL: false,
    departureSectorID: null,
    hasPaxTypeRestriction: false,
    hasNationalityRestriction: false,
    hasDayGroupRestriction: false,
    allowedPaxTypeID: null,
    allowedNationalityID: null,
    allowedDayGroupID: null,
    additionalCriteriaString: null
  });

  const classes = useStyles();

  const [redirection, setRedirection] = React.useState({
    redirect: false,
    id: null
  });
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post("/BookingTypes", state, {
        signal: source.current.signal
      })
      .then(data =>
        setRedirection({
          redirect: true,
          id: data
        })
      )
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  return redirection.redirect ? (
    <Redirect to={"/admin/BookingTypes/InDraft"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>CREATE BOOKING TYPE</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleCodeChanged,
                  value: state.code === null ? "" : state.code
                }}
              />
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleNameChanged,
                  value: state.name === null ? "" : state.name
                }}
              />
              <GridContainer>
                <GridItem md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.isRoundTrip}
                        onChange={handleIsRoundTripChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Is Round Trip"
                  />
                </GridItem>
                <GridItem md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.isVTL}
                        onChange={handleIsVTLChanged}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Is VTL"
                  />
                </GridItem>
              </GridContainer>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selDepartureSector"
                  className={classes.selectLabel}
                >
                  Departure Sector
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={
                    state.departureSectorID === null
                      ? ""
                      : state.departureSectorID
                  }
                  onChange={handleDepartureSectorIDChanged}
                >
                  {sectorList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        <b>{record.code}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <div className={classes.checkboxAndRadio}>
                <GridContainer>
                  <GridItem xs={12} sm={4}>
                    <GridItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleHasPaxTypeRestrictionChanged}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has PaxType Restriction"
                      />
                    </GridItem>
                    {state.hasPaxTypeRestriction && ( // render only if Has PaxType Restriction
                      <GridItem>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="selPaxType"
                            className={classes.selectLabel}
                          >
                            Allowed PaxType
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={
                              state.allowedPaxTypeID === null
                                ? ""
                                : state.allowedPaxTypeID
                            }
                            onChange={handleAllowedPaxTypeIDChanged}
                          >
                            {paxTypeList.map(record => {
                              return (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={record.id}
                                  key={record.id}
                                >
                                  <b>{record.code}</b>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <GridItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleHasNationalityRestrictionChanged}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has Nationality Restriction"
                      />
                    </GridItem>
                    {state.hasNationalityRestriction && ( // render only if Has PaxType Restriction
                      <GridItem>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="selNationality"
                            className={classes.selectLabel}
                          >
                            Allowed Nationality
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={
                              state.allowedNationalityID === null
                                ? ""
                                : state.allowedNationalityID
                            }
                            onChange={handleAllowedNationalityIDChanged}
                          >
                            {nationalityList.map(record => {
                              return (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={record.id}
                                  key={record.id}
                                >
                                  <b>{record.nationality}</b>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={4}>
                    <GridItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleHasDayGroupRestrictionChanged}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has DayGroup Restriction"
                      />
                    </GridItem>
                    {state.hasDayGroupRestriction && ( // render only if Has DayGroup Restriction
                      <GridItem>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="selDayGroup"
                            className={classes.selectLabel}
                          >
                            Allowed DayGroup
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={
                              state.allowedDayGroupID === null
                                ? ""
                                : state.allowedDayGroupID
                            }
                            onChange={handleAllowedDayGroupIDChanged}
                          >
                            {dayGroupList.map(record => {
                              return (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={record.id}
                                  key={record.id}
                                >
                                  <b>{record.name}</b>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                    )}
                  </GridItem>
                </GridContainer>
              </div>
              <CustomInput
                labelText="Additional Criteria String"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleAdditionalCriteriaStringChanged,
                  value:
                    state.additionalCriteriaString === null
                      ? ""
                      : state.additionalCriteriaString
                }}
              />
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateBookingType.propTypes = {
  history: PropTypes.object
};
