import BusinessIcon from "@material-ui/icons/Business";

import InDraftAgentList from "views/Agent/InDraftAgentList.js";
import PublishedAgentList from "views/Agent/PublishedAgentList.js";
import DeactivatedAgentList from "views/Agent/DeactivatedAgentList.js";
import CreateAgent from "views/Agent/CreateAgent.js";
import PublishAgent from "views/Agent/PublishAgent.js";
import DeactivateAgent from "views/Agent/DeactivateAgent.js";
import DeleteAgent from "views/Agent/DeleteAgent.js";
import EditAgent from "views/Agent/EditAgent.js";
import AgentDetail from "views/Agent/AgentDetail.js";
import AgentCreateBookingType from "views/Agent/AgentCreateBookingType.js";
import AgentCreateVoucherType from "views/Agent/AgentCreateVoucherType.js";
import AgentCreateUser from "views/Agent/AgentCreateUser.js";
import EditCreditInformation from "views/Agent/EditCreditInformation.js";
import EditBookingConfiguration from "views/Agent/EditBookingConfiguration.js";

import { token } from "functions/Helper";
import PredefinedRoleList from "views/Agent/PredefinedRole/PredefinedRoleList";
import CreatePredefinedRole from "views/Agent/PredefinedRole/CreatePredefinedRole";
import DeletePredefinedRole from "views/Agent/PredefinedRole/DeletePredefinedRole";
import PredefinedRoleDetail from "views/Agent/PredefinedRole/PredefinedRoleDetail";
import CreateAgentMerchantProductPricing from "views/Agent/MerchantProductPricing/CreateAgentMerchantProductPricing";
import ViewAgentMerchantProductPricing from "views/Agent/MerchantProductPricing/ViewAgentMerchantProductPricing";
import DeleteAgentMerchantProductPricing from "views/Agent/MerchantProductPricing/DeleteAgentMerchantProductPricing";
import EditAgentMerchantProductPricing from "views/Agent/MerchantProductPricing/EditAgentMerchantProductPricing";

const agent = () => {
  let views = [];
  if (token["agent.agents.read"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/Agents/InDraft",
        name: "In Draft",
        mini: "IN",
        component: InDraftAgentList,
        layout: "/admin"
      },
      {
        path: "/Agents/Published",
        name: "Published",
        mini: "PU",
        component: PublishedAgentList,
        layout: "/admin"
      },
      {
        path: "/Agents/Deactivated",
        name: "Deactivated",
        mini: "DE",
        component: DeactivatedAgentList,
        layout: "/admin"
      }
    ]);
  if (
    token["agent.predefined_roles.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/Agent/PredefinedRoleList",
        name: "Predefined Role",
        mini: "PR",
        component: PredefinedRoleList,
        layout: "/admin"
      }
    ]);
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Agents",
        icon: BusinessIcon,
        state: "agentCollapse",
        views: views
      }
    ];
  return [];
};

const agentHidden = () => {
  let views = [];
  if (token["agent.agents.read"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Agent/Detail",
      name: "Agent Detail",
      component: AgentDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["agent.predefined_roles.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Agent/PredefinedRole/Detail",
      name: "Agent Predefined Role Detail",
      component: PredefinedRoleDetail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (token["agent.agents.write"] === "allowed" || token.is_admin === "True")
    views = views.concat([
      {
        path: "/Agent/Create",
        name: "Create Agent",
        component: CreateAgent,
        layout: "/admin"
      },
      {
        path: "/Agent/Delete",
        name: "Delete Agent",
        component: DeleteAgent,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Agent/Edit",
        name: "Edit Agent",
        component: EditAgent,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      },
      {
        path: "/Agent/CreateUser",
        name: "Create User",
        component: AgentCreateUser,
        layout: "/admin",
        params: [
          {
            name: "agentID"
          }
        ]
      }
    ]);
  if (
    token["agent.predefined_roles.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views = views.concat([
      {
        path: "/Agent/PredefinedRole/Create",
        name: "Create Agent Predefined Role",
        component: CreatePredefinedRole,
        layout: "/admin"
      },
      {
        path: "/Agent/PredefinedRole/Delete",
        name: "Delete Agent Predefined Role",
        component: DeletePredefinedRole,
        layout: "/admin",
        params: [
          {
            name: "id"
          }
        ]
      }
    ]);
  if (
    token["creditmonitoring.agents.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Agent/EditCreditInformation",
      name: "Edit Credit Information",
      component: EditCreditInformation,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (token["booking.agents.write"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Agent/EditBookingConfiguration",
      name: "Edit Booking Configuration",
      component: EditBookingConfiguration,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["order.agent_voucher_types.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Agent/CreateVoucherType",
      name: "Create Voucher Type",
      component: AgentCreateVoucherType,
      layout: "/admin",
      params: [
        {
          name: "agentID"
        }
      ]
    });
  if (
    token["booking.agent_booking_types.write"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Agent/CreateBookingType",
      name: "Create Booking Type",
      component: AgentCreateBookingType,
      layout: "/admin",
      params: [
        {
          name: "agentID"
        }
      ]
    });
  if (token["agent.agents.publish"] === "allowed" || token.is_admin === "True")
    views.push({
      path: "/Agent/Publish",
      name: "Publish Agent",
      component: PublishAgent,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (
    token["agent.agents.deactivate"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/Agent/Deactivate",
      name: "Deactivate Agent",
      component: DeactivateAgent,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });

  views.push({
    path: "/Agent/CreateMerchantProductPricing",
    name: "Create Merchant Product Pricing",
    component: CreateAgentMerchantProductPricing,
    layout: "/admin",
    params: [
      {
        name: "id"
      }
    ]
  });

  views.push({
    path: "/Agent/MerchantProductPricing/Detail",
    name: "View Merchant Product Pricing",
    component: ViewAgentMerchantProductPricing,
    layout: "/admin",
    params: [
      {
        name: "id"
      }
    ]
  });

  views.push({
    path: "/Agent/MerchantProductPricing/Delete",
    name: "Delete Merchant Product Pricing",
    component: DeleteAgentMerchantProductPricing,
    layout: "/admin",
    params: [
      {
        name: "id"
      }
    ]
  });

  views.push({
    path: "/Agent/MerchantProductPricing/Edit",
    name: "Edit Merchant Product Pricing",
    component: EditAgentMerchantProductPricing,
    layout: "/admin",
    params: [
      {
        name: "id"
      }
    ]
  });

  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Agent Hidden",
        state: "agentHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { agent, agentHidden };
