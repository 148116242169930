import React, { useEffect, useRef } from "react";
import Datetime from "react-datetime";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { masterApi } from "functions/apis";

import { formatDateTime, token } from "functions/Helper";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ExchangeRateList() {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const [currencyList, setCurrencyList] = React.useState([]);
  useEffect(() => {
    setLoading(true);
    masterApi
      .get("/Currencies", {
        signal: source.current.signal,
        params: {
          filter: {
            searchString: null,
            publicationStatus: 1
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCurrencyList(data.records))
      .catch(error => console.log(error));
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    minTime: null,
    maxTime: null,
    currencyID: null
  });
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    masterApi
      .get("/CurrencyExchangeRates", {
        signal: source.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  {(token["master.currency_exchange_rate.write"] ===
                    "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/ExchangeRate/Delete/" + record.id}>
                      <Button color="danger" className="remove">
                        <DeleteIcon /> DELETE
                      </Button>
                    </Link>
                  )}
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const handleCurrencyIDChanged = e =>
    setFilter({
      ...filter,
      currencyID: e.target.value === "" ? null : e.target.value
    });

  const handleMinTimeChanged = e =>
    setFilter({
      ...filter,
      minTime: e === "" ? null : e
    });

  const handleMaxTimeChanged = e =>
    setFilter({
      ...filter,
      maxTime: e === "" ? null : e
    });

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Exchange Rate</h4>
          </CardHeader>
          <CardBody>
            {(token["master.currency_exchange_rate.write"] === "allowed" ||
              token.is_admin === "True") && (
              <Link to={"/admin/ExchangeRate/Create"}>
                <Button color="rose" type="button" value="Create">
                  Create Exchange Rate
                </Button>
              </Link>
            )}
            <GridItem xs={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCurrency"
                  className={classes.selectLabel}
                >
                  Currency
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={filter.currencyID === null ? "" : filter.currencyID}
                  onChange={handleCurrencyIDChanged}
                >
                  {currencyList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        <b>{record.code}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <Datetime
                  value={filter.minTime}
                  onChange={handleMinTimeChanged}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "Min Time"
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <Datetime
                  value={filter.maxTime}
                  onChange={handleMaxTimeChanged}
                  timeFormat={false}
                  inputProps={{
                    placeholder: "Max Time"
                  }}
                />
              </FormControl>
            </GridItem>
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  id: "currency",
                  Header: "Currency",
                  accessor: d => d.currency.code
                },
                {
                  id: "Time",
                  Header: "Time",
                  accessor: d => formatDateTime(new Date(d.time + "Z"))
                },
                {
                  id: "rate",
                  Header: "Rate",
                  accessor: d => d.rate.toLocaleString()
                },
                {
                  id: "CreatedAt",
                  Header: "Created At",
                  accessor: d => formatDateTime(new Date(d.createdAt + "Z"))
                },
                {
                  Header: "Created By",
                  headerStyle: { textAlign: "left" },
                  accessor: "createdBy"
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
