import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { GetOrderStatusDescription } from "enums/OrderStatus.js";
import { GetOrderCancellationStatusDescription } from "enums/OrderCancellationStatus.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { orderApi } from "functions/apis";

import { formatDateTime, token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function OrderDetail(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);

  const { id } = useParams();

  const [order, setOrder] = React.useState(null);
  useEffect(() => {
    orderApi
      .get("/Orders/" + id, {
        signal: source.current.signal
      })
      .then(data => setOrder(data))
      .catch(error => console.log(error));
  }, [id]);

  const classes = useStyles();
  return (
    order !== null && (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <MailOutline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>ORDER</h4>
              </CardHeader>
              <CardBody>
                <Button onClick={() => props.history.goBack()} value="Back">
                  BACK
                </Button>
                {order.status === 4 &&
                  (token["order.orders.cancel"] === "allowed" ||
                    token.is_admin === "True") && (
                    <Link to={"/admin/Orders/Cancel/" + order.id}>
                      <Button color="danger" value="Cancel">
                        CANCEL ORDER
                      </Button>
                    </Link>
                  )}
                <CustomInput
                  disabled
                  labelText="No"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: order.no
                  }}
                />
                <CustomInput
                  disabled
                  labelText="Agent"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: order.agent.name
                  }}
                />
                <CustomInput
                  disabled
                  labelText="Voucher Type"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: order.voucherType.code
                  }}
                />
                <CustomInput
                  disabled
                  labelText="Quantity"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: order.quantity.toLocaleString()
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      disabled
                      labelText="Currency"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          order.agent.billingCurrency === null
                            ? ""
                            : order.agent.billingCurrency.code
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      disabled
                      labelText="Unit Price"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: order.unitPrice.toLocaleString()
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      disabled
                      labelText="Total"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: order.total.toLocaleString()
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CustomInput
                  labelText="Status"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: GetOrderStatusDescription(order.status)
                  }}
                />
                {order.status === 0 && (
                  <CustomInput
                    labelText="Transaction Time"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(order.transactionTime + "Z")
                      )
                    }}
                  />
                )}
                {order.status === 2 && (
                  <CustomInput
                    labelText="Paid At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(order.paymentProcessedAt + "Z")
                      )
                    }}
                  />
                )}
                {order.status === 3 && (
                  <CustomInput
                    labelText="Payment Failed At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(order.paymentProcessedAt + "Z")
                      )
                    }}
                  />
                )}
                {order.status === 4 && (
                  <CustomInput
                    labelText="Confirmed At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(new Date(order.confirmedAt + "Z"))
                    }}
                  />
                )}
                <CustomInput
                  labelText="Cancellation Status"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: GetOrderCancellationStatusDescription(
                      order.cancellationStatus
                    )
                  }}
                />
                {order.cancellationStatus !== 0 && (
                  <>
                    <CustomInput
                      labelText="Cancellation Reason"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          order.cancellationReason === null
                            ? ""
                            : order.cancellationReason
                      }}
                    />
                    <CustomInput
                      labelText="Cancellation Requested At"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: formatDateTime(
                          new Date(order.cancellationRequestedAt + "Z")
                        )
                      }}
                    />
                  </>
                )}
                {order.cancellationStatus === 2 && (
                  <>
                    <CustomInput
                      labelText="Cancellation Approved At"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: formatDateTime(
                          new Date(order.cancellationApprovedAt + "Z")
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Cancellation Approved By"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          order.cancellationApprovedBy === null
                            ? ""
                            : order.cancellationApprovedBy
                      }}
                    />
                  </>
                )}
                {order.cancellationStatus === 3 && (
                  <>
                    <CustomInput
                      labelText="Cancellation Rejected At"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: formatDateTime(
                          new Date(order.cancellationRejectedAt + "Z")
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Cancellation Reject Reason"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          order.cancellationRejectReason === null
                            ? ""
                            : order.cancellationRejectReason
                      }}
                    />
                    <CustomInput
                      labelText="Cancellation Rejected By"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          order.cancellationRejectedBy === null
                            ? ""
                            : order.cancellationRejectedBy
                      }}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    )
  );
}

OrderDetail.propTypes = {
  history: PropTypes.object
};
