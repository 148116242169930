import React, { useEffect, useRef } from "react";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { bookingApi, masterApi } from "functions/apis";
import { token } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function BulkRetryCheckInError(props) {
  const source = useRef(new AbortController());
  const [routeList, setRouteList] = React.useState([]);
  useEffect(() => {
    if (token["master.routes.read"] === "allowed" || token.is_admin === "True")
      masterApi
        .get("/Routes", {
          signal: source.current.signal,
          params: {
            pagination: {
              pageIndex: 0,
              pageSize: 0
            },
            filter: {
              searchString: null,
              publicationStatus: 1,
              sort: 2
            }
          }
        })
        .then(data => setRouteList(data.records))
        .catch(error => console.log(error));

    return () => source.current.abort();
  }, []);

  const [state, setState] = React.useState({
    routeID: null,
    date: null,
    tripTime: null
  });

  const handleRouteIDChanged = e =>
    setState({
      ...state,
      routeID: e.target.value === "" ? null : e.target.value
    });

  const handleDateChanged = e =>
    setState({ ...state, date: e === "" ? null : e });

  const handleTripTimeChanged = e =>
    setState({
      ...state,
      tripTime: e.target.value === "" ? null : e.target.value
    });

  const classes = useStyles();

  const [redirect, setRedirect] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post("/CoreApiCheckIns/BulkRetryProcess", state, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };
  return redirect ? (
    <Redirect to={"/admin/CoreApiCheckIns/CheckInError"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>BULK RETRY CANCEL ERROR</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="selRoute" className={classes.selectLabel}>
                  Route
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={state.routeID === null ? "" : state.routeID}
                  onChange={handleRouteIDChanged}
                >
                  {routeList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        <b>{record.code}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <Datetime
                  value={state.date}
                  onChange={handleDateChanged}
                  timeFormat={false}
                  closeOnSelect={true}
                  utc={true}
                  inputProps={{
                    placeholder: "Date"
                  }}
                />
              </FormControl>
              <CustomInput
                labelText="Trip Time"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleTripTimeChanged,
                  value: state.name === null ? "" : state.name
                }}
              />

              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

BulkRetryCheckInError.propTypes = {
  history: PropTypes.object
};
