import React, { useEffect, useRef } from "react";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import { Redirect, useParams } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import { bookingApi, creditMonitoringApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function AgentCreateBookingType(props) {
  const source = useRef(new AbortController());

  useEffect(() => {
    return () => source.current.abort();
  }, []);
  const { agentID } = useParams();
  const [agentCredit, setAgentCredit] = React.useState(null);
  const [bookingTypeList, setBookingTypeList] = React.useState([]);
  const [bookingType, setBookingType] = React.useState(null);
  const [redirect, setRedirect] = React.useState(false);
  useEffect(() => {
    bookingApi
      .get("/BookingTypes", {
        signal: source.current.signal,
        params: {
          filter: {
            searchString: null,
            publicationStatus: 1,
            sort: 0 //CODE_ASC
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setBookingTypeList(data.records))
      .catch(error => console.log(error));
    creditMonitoringApi
      .get("/Agents/" + agentID, {
        signal: source.current.signal
      })
      .then(data => setAgentCredit(data))
      .catch(error => console.log(error));
  }, [agentID]);

  const [state, setState] = React.useState({
    bookingTypeID: null,
    description: null,
    effectiveDate: null,
    expiryDate: null,
    hasTravelDateRestriction: false,
    travelDateFrom: null,
    travelDateUntil: null,
    departurePricing: {
      ticket: 0,
      pdf: 0,
      terminalFee: 0,
      surcharge: 0,
      confirmationFee: 0,
      vtlFee: 0,
      total: 0
    },
    returnPricing: {
      ticket: 0,
      pdf: 0,
      terminalFee: 0,
      surcharge: 0,
      confirmationFee: 0,
      vtlFee: 0,
      total: 0
    },
    totalPrice: 0
  });

  const handleBookingTypeIDChanged = (e, v) => {
    setState({
      ...state,
      bookingTypeID: v === null ? null : v.id,
      departurePricing: {
        ticket: 0,
        pdf: 0,
        terminalFee: 0,
        surcharge: 0,
        confirmationFee: 0,
        vtlFee: 0,
        total: 0
      },
      returnPricing: {
        ticket: 0,
        pdf: 0,
        terminalFee: 0,
        surcharge: 0,
        confirmationFee: 0,
        vtlFee: 0,
        total: 0
      }
    });
    if (v === null) {
      setBookingType(null);
      return;
    }
    setBookingType(v);
  };

  const handleDescriptionChanged = e =>
    setState({
      ...state,
      description: e.target.value === "" ? null : e.target.value
    });

  const handleDeparturePricingTicketChanged = e => {
    let newDeparturePricingTicket = parseFloat(e.target.value);
    if (newDeparturePricingTicket !== state.departurePricing.ticket)
      setState({
        ...state,
        departurePricing: {
          ...state.departurePricing,
          ticket: newDeparturePricingTicket
        }
      });
  };

  const handleDeparturePricingPDFChanged = e => {
    let newDeparturePricingPDF = parseFloat(e.target.value);
    if (newDeparturePricingPDF !== state.departurePricing.pdf)
      setState({
        ...state,
        departurePricing: {
          ...state.departurePricing,
          pdf: newDeparturePricingPDF
        }
      });
  };

  const handleDeparturePricingTerminalFeeChanged = e => {
    let newDeparturePricingTerminalFee = parseFloat(e.target.value);
    if (newDeparturePricingTerminalFee !== state.departurePricing.terminalFee)
      setState({
        ...state,
        departurePricing: {
          ...state.departurePricing,
          terminalFee: newDeparturePricingTerminalFee
        }
      });
  };

  const handleDeparturePricingSurchargeChanged = e => {
    let newDeparturePricingSurcharge = parseFloat(e.target.value);
    if (newDeparturePricingSurcharge !== state.departurePricing.surcharge)
      setState({
        ...state,
        departurePricing: {
          ...state.departurePricing,
          surcharge: newDeparturePricingSurcharge
        }
      });
  };

  const handleDeparturePricingConfirmationFeeChanged = e => {
    let newDeparturePricingConfirmationFee = parseFloat(e.target.value);
    if (
      newDeparturePricingConfirmationFee !==
      state.departurePricing.confirmationFee
    )
      setState({
        ...state,
        departurePricing: {
          ...state.departurePricing,
          confirmationFee: newDeparturePricingConfirmationFee
        }
      });
  };

  const handleDeparturePricingVTLFeeChanged = e => {
    let newDeparturePricingVTLFee = parseFloat(e.target.value);
    if (newDeparturePricingVTLFee !== state.departurePricing.vtlFee)
      setState({
        ...state,
        departurePricing: {
          ...state.departurePricing,
          vtlFee: newDeparturePricingVTLFee
        }
      });
  };

  const handleReturnPricingTicketChanged = e => {
    let newReturnPricingTicket = parseFloat(e.target.value);
    if (newReturnPricingTicket !== state.returnPricing.ticket)
      setState({
        ...state,
        returnPricing: {
          ...state.returnPricing,
          ticket: newReturnPricingTicket
        }
      });
  };

  const handleReturnPricingPDFChanged = e => {
    let newReturnPricingPDF = parseFloat(e.target.value);
    if (newReturnPricingPDF !== state.returnPricing.pdf)
      setState({
        ...state,
        returnPricing: {
          ...state.returnPricing,
          pdf: newReturnPricingPDF
        }
      });
  };

  const handleReturnPricingTerminalFeeChanged = e => {
    let newReturnPricingTerminalFee = parseFloat(e.target.value);
    if (newReturnPricingTerminalFee !== state.returnPricing.terminalFee)
      setState({
        ...state,
        returnPricing: {
          ...state.returnPricing,
          terminalFee: newReturnPricingTerminalFee
        }
      });
  };

  const handleReturnPricingSurchargeChanged = e => {
    let newReturnPricingSurcharge = parseFloat(e.target.value);
    if (newReturnPricingSurcharge !== state.returnPricing.surcharge)
      setState({
        ...state,
        returnPricing: {
          ...state.returnPricing,
          surcharge: newReturnPricingSurcharge
        }
      });
  };

  const handleReturnPricingConfirmationFeeChanged = e => {
    let newReturnPricingConfirmationFee = parseFloat(e.target.value);
    if (newReturnPricingConfirmationFee !== state.returnPricing.confirmationFee)
      setState({
        ...state,
        returnPricing: {
          ...state.returnPricing,
          confirmationFee: newReturnPricingConfirmationFee
        }
      });
  };

  const handleReturnPricingVTLFeeChanged = e => {
    let newReturnPricingVTLFee = parseFloat(e.target.value);
    if (newReturnPricingVTLFee !== state.returnPricing.vtlFee)
      setState({
        ...state,
        returnPricing: {
          ...state.returnPricing,
          vtlFee: newReturnPricingVTLFee
        }
      });
  };

  const handleEffectiveDateChanged = e =>
    setState({
      ...state,
      effectiveDate: e === "" ? null : e
    });

  const handleExpiryDateChanged = e =>
    setState({
      ...state,
      expiryDate: e === "" ? null : e
    });

  const handleHasTravelDateRestrictionChanged = e =>
    setState({ ...state, hasTravelDateRestriction: e.target.checked });

  const handleTravelDateFromChanged = e =>
    setState({
      ...state,
      travelDateFrom: e === "" ? null : e
    });

  const handleTravelDateUntilChanged = e =>
    setState({
      ...state,
      travelDateUntil: e === "" ? null : e
    });

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    bookingApi
      .post("/Agents/" + agentID + "/BookingTypes", state, {
        signal: source.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();
  return redirect ? (
    <Redirect to={"/admin/Agent/Detail/" + agentID} />
  ) : (
    agentCredit !== null && (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>ADD BOOKING TYPE</h4>
            </CardHeader>
            <CardBody>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <form onSubmit={handleSubmit}>
                <CustomInput
                  labelText="Description"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleDescriptionChanged,
                    value: state.description === null ? "" : state.description
                  }}
                />
                <Autocomplete
                  id="bookingType"
                  onChange={handleBookingTypeIDChanged}
                  options={bookingTypeList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={option => option.name}
                  renderOption={option => (
                    <React.Fragment>
                      <b>{option.code}</b> - {option.name}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Booking Type"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
                <CustomInput
                  labelText="Billing Currency"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value:
                      agentCredit.billingCurrency === null
                        ? ""
                        : agentCredit.billingCurrency.code
                  }}
                />
                {bookingType !== null && (
                  <>
                    Departure Pricing:
                    <GridContainer>
                      <GridItem xs={12} sm={9} md={9}>
                        <GridContainer>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="Ticket"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleDeparturePricingTicketChanged,
                                value: state.departurePricing.ticket
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="PDF"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleDeparturePricingPDFChanged,
                                value: state.departurePricing.pdf
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="Terminal Fee"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleDeparturePricingTerminalFeeChanged,
                                value: state.departurePricing.terminalFee
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="Surcharge"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleDeparturePricingSurchargeChanged,
                                value: state.departurePricing.surcharge
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="Confirmation Fee"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleDeparturePricingConfirmationFeeChanged,
                                value: state.departurePricing.confirmationFee
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="VTL Fee"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleDeparturePricingVTLFeeChanged,
                                value: state.departurePricing.vtlFee
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                          labelText="Total"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            disabled: true,
                            value:
                              state.departurePricing.ticket +
                              state.departurePricing.pdf +
                              state.departurePricing.terminalFee +
                              state.departurePricing.surcharge +
                              state.departurePricing.confirmationFee +
                              state.departurePricing.vtlFee
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                )}
                {bookingType !== null && bookingType.isRoundTrip === true && (
                  <>
                    Return Pricing:
                    <GridContainer>
                      <GridItem xs={12} sm={9} md={9}>
                        <GridContainer>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="Ticket"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleReturnPricingTicketChanged,
                                value: state.returnPricing.ticket
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="PDF"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleReturnPricingPDFChanged,
                                value: state.returnPricing.pdf
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="Terminal Fee"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleReturnPricingTerminalFeeChanged,
                                value: state.returnPricing.terminalFee
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="Surcharge"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleReturnPricingSurchargeChanged,
                                value: state.returnPricing.surcharge
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="Confirmation Fee"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleReturnPricingConfirmationFeeChanged,
                                value: state.returnPricing.confirmationFee
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={2} md={2}>
                            <CustomInput
                              labelText="VTL Fee"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                type: "number",
                                onChange: handleReturnPricingVTLFeeChanged,
                                value: state.returnPricing.vtlFee
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={3} md={3}>
                        <CustomInput
                          labelText="Total"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            disabled: true,
                            value:
                              state.returnPricing.ticket +
                              state.returnPricing.pdf +
                              state.returnPricing.terminalFee +
                              state.returnPricing.surcharge +
                              state.returnPricing.confirmationFee +
                              state.returnPricing.vtlFee
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </>
                )}
                <CustomInput
                  labelText="Total Price"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    disabled: true,
                    value:
                      state.departurePricing.ticket +
                      state.departurePricing.pdf +
                      state.departurePricing.terminalFee +
                      state.departurePricing.surcharge +
                      state.departurePricing.confirmationFee +
                      state.departurePricing.vtlFee +
                      state.returnPricing.ticket +
                      state.returnPricing.pdf +
                      state.returnPricing.terminalFee +
                      state.returnPricing.surcharge +
                      state.returnPricing.confirmationFee +
                      state.returnPricing.vtlFee
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                      <Datetime
                        value={state.effectiveDate}
                        onChange={handleEffectiveDateChanged}
                        timeFormat={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "Effective Date"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                      <Datetime
                        value={state.expiryDate}
                        onChange={handleExpiryDateChanged}
                        timeFormat={true}
                        closeOnSelect={true}
                        inputProps={{
                          placeholder: "Expiry Date"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.hasTravelDateRestriction}
                      onChange={handleHasTravelDateRestrictionChanged}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Has TravelDate Restriction"
                />
                <br />
                {state.hasTravelDateRestriction && (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <Datetime
                          value={state.travelDateFrom}
                          onChange={handleTravelDateFromChanged}
                          timeFormat={true}
                          closeOnSelect={true}
                          inputProps={{
                            placeholder: "TravelDate From"
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <Datetime
                          value={state.travelDateUntil}
                          onChange={handleTravelDateUntilChanged}
                          timeFormat={true}
                          closeOnSelect={true}
                          inputProps={{
                            placeholder: "TravelDate Until"
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                )}
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  );
}

AgentCreateBookingType.propTypes = {
  history: PropTypes.object
};
