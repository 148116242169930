import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { useParams } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput";

const useStyles = makeStyles(styles);

const ViewOnlineBookingHomeContent = props => {
  const classes = useStyles();

  const { id } = useParams();

  const [input, setInput] = useState({
    title: "",
    description: "",
    banner: "",
    url: ""
  });

  const viewOnlineBookingHomeContentSignal = useRef(new AbortController());

  const getData = () => {
    bookingApi
      .get("OnlineBookingHomeContents/" + id, {
        signal: viewOnlineBookingHomeContentSignal.current.signal
      })
      .then(data => {
        setInput({
          ...input,
          title: data.title,
          banner: data.banner,
          description: data.description,
          url: data.action
        });
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getData();
    return () => viewOnlineBookingHomeContentSignal.current.abort();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              VIEW ONLINE BOOKING HOME CONTENT
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form>
              <CustomInput
                labelText="Title"
                formControlProps={{
                  fullWidth: true
                }}
                disabled
                inputProps={{
                  value: input.title === null ? "" : input.title,
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Description"
                formControlProps={{
                  fullWidth: true
                }}
                disabled
                inputProps={{
                  value: input.title === null ? "" : input.description,
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Banner URL"
                formControlProps={{
                  fullWidth: true
                }}
                disabled
                inputProps={{
                  value: input.banner === null ? "" : input.banner,
                  disabled: true
                }}
              />

              <CustomInput
                labelText="Web Url"
                formControlProps={{
                  fullWidth: true
                }}
                disabled
                inputProps={{
                  value: input.url === null ? "" : input.url,
                  disabled: true
                }}
              />
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewOnlineBookingHomeContent;

ViewOnlineBookingHomeContent.propTypes = {
  history: PropTypes.object
};
