import { FormControl, Input, InputLabel, makeStyles } from "@material-ui/core";
import { travelApi } from "functions/apis";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import { Assignment } from "@material-ui/icons";
import CardBody from "components/Card/CardBody";
import ReactTable from "react-table";
import MerchantControlGroupDialog from "components/MerchantControlGroupDialog/MerchantControlGroupDialog";
import { formatDate } from "functions/Helper";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const useStyles = makeStyles(styles);

const GeneratedMerchantInventoryGenerationList = () => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [filter, setFilter] = useState({
    controlGroupID: "",
    status: 1
  });
  const [isShowDialog, setIsShowDialog] = React.useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [controlGroupData, setControlGroupData] = React.useState({
    id: "",
    name: ""
  });

  const pendingMerchantInventoryGenerationListSignal = React.useRef(
    new AbortController()
  );

  React.useEffect(() => {
    if (controlGroupData.id != "") {
      setFilter({ ...filter, controlGroupID: controlGroupData.id });
    }
  }, [controlGroupData]);

  React.useEffect(() => {
    travelApi
      .get("inventoryGenerations", {
        params: {
          filter,
          pagination
        },
        signal: pendingMerchantInventoryGenerationListSignal.current.signal
      })
      .then(resp => {
        setData(
          resp.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <div className="actions-right">
                    <Link
                      to={
                        "/admin/MerchantInventoryGeneration/View/" + record.id
                      }
                    >
                      <Button color="info" className="like">
                        <VisibilityIcon /> VIEW
                      </Button>
                    </Link>
                  </div>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(resp.totalRecords / pagination.pageSize));
        setLoading(false);
      });
  }, [filter, pagination]);

  const handleControlGroupIDChanged = e => {
    setControlGroupData({ ...controlGroupData, id: e.id, name: e.name });
    setIsShowDialog(false);
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              GENERATED MERCHANT INVENTORY GENERATIONS
            </h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={6} sm={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="selAgent" className={classes.selectLabel}>
                  Control Group
                </InputLabel>
                <Input
                  classes={{
                    input: classes.input,
                    disabled: classes.active,
                    root: classes.active,
                    underline: classes.underline
                  }}
                  value={controlGroupData.name ?? ""}
                  onClick={() => setIsShowDialog(true)}
                  fullWidth
                />
              </FormControl>
              <MerchantControlGroupDialog
                onSelect={handleControlGroupIDChanged}
                openModal={isShowDialog}
                setOpenModal={isOpenModal => setIsShowDialog(isOpenModal)}
              />
            </GridItem>
            <GridItem>
              <ReactTable
                manual
                pages={pages}
                page={pagination.pageIndex - 1}
                pageSize={pagination.pageSize}
                onPageChange={page =>
                  setPagination({
                    pageIndex: page + 1,
                    pageSize: pagination.pageSize
                  })
                }
                onPageSizeChange={(pageSize, page) => {
                  setPagination({ pageSize: pageSize, pageIndex: page + 1 });
                }}
                loading={loading}
                data={data}
                filterable={false}
                columns={[
                  {
                    id: "id",
                    Header: "ID",
                    accessor: d => d.id
                  },
                  {
                    id: "code",
                    Header: "Control Group Code",
                    accessor: d => d.controlGroup.code
                  },
                  {
                    id: "startDate",
                    Header: "Start Date",
                    accessor: d => formatDate(d.startDate)
                  },
                  {
                    id: "untilDate",
                    Header: "End Date",
                    accessor: d => formatDate(d.untilDate)
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default GeneratedMerchantInventoryGenerationList;
