import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PendingList from "views/OnlineBooking/PendingList";
import WaitingList from "views/OnlineBooking/WaitingList";
import PaidList from "views/OnlineBooking/PaidList";
import CancelledList from "views/OnlineBooking/CancelledList";
import Detail from "views/OnlineBooking/Detail";
import { token } from "functions/Helper";

const onlineBooking = () => {
  let views = [];
  if (
    token["booking.online_bookings.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views = [
      {
        path: "/OnlineBooking/Pending",
        name: "Pending",
        mini: "PE",
        component: PendingList,
        layout: "/admin"
      },
      {
        path: "/OnlineBooking/WaitingForPayment",
        name: "Waiting",
        mini: "WA",
        component: WaitingList,
        layout: "/admin"
      },
      {
        path: "/OnlineBooking/Paid",
        name: "Paid",
        mini: "PA",
        component: PaidList,
        layout: "/admin"
      },
      {
        path: "/OnlineBooking/Cancelled",
        name: "Cancelled",
        mini: "CA",
        component: CancelledList,
        layout: "/admin"
      }
    ];

  return [
    {
      collapse: true,
      name: "Online Booking",
      icon: MonetizationOnIcon,
      state: "OnlineBookingCollapse",
      views: views
    }
  ];
};

const onlineBookingHidden = () => {
  let views = [];
  if (
    token["booking.online_bookings.read"] === "allowed" ||
    token.is_admin === "True"
  )
    views.push({
      path: "/OnlineBooking/Detail",
      name: "View Online Booking",
      component: Detail,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    });
  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Online Booking Hidden",
        state: "onlineBookingHidden",
        hideInSidebar: true,
        views: views
      }
    ];
  return [];
};

export { onlineBooking, onlineBookingHidden };
