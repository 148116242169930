import NotificationsIcon from "@material-ui/icons/Notifications";
import AppsNotification from "views/AppsNotification/AppsNotification";
import CreateAppsNotification from "views/AppsNotification/CreateAppsNotification";
import DeleteAppsNotification from "views/AppsNotification/DeleteAppsNotification";
import EditAppsNotification from "views/AppsNotification/EditAppsNotification";
import ViewAppsNotification from "views/AppsNotification/ViewAppsNotification";

const appsNotification = () => {
  let views = [];

  views.push({
    path: "/AppsNotification/List",
    name: "Apps Notifications",
    icon: NotificationsIcon,
    component: AppsNotification,
    layout: "/admin"
  });

  return views;
};

const appsNotificationHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/AppsNotification/Create",
      name: "Create Apps Notification",
      component: CreateAppsNotification,
      layout: "/admin"
    },
    {
      path: "/AppsNotification/Edit",
      name: "Edit Apps Notification",
      component: EditAppsNotification,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/AppsNotification/View",
      name: "View Apps Notification",
      component: ViewAppsNotification,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    },
    {
      path: "/AppsNotification/Delete",
      name: "Delete Apps Notification",
      component: DeleteAppsNotification,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  if (views.length > 0)
    return [
      {
        collapse: true,
        name: "Apps Notification Hidden",
        state: "appsNotificationHidden",
        hideInSidebar: true,
        views: views
      }
    ];
};

export { appsNotification, appsNotificationHidden };
