import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import MailOutline from "@material-ui/icons/MailOutline";
import CardIcon from "components/Card/CardIcon.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Chip from "@material-ui/core/Chip";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";

import PublicationStatus from "enums/PublicationStatus";
import { voucherApi, masterApi } from "functions/apis";
import { GetVoucherTypeValidityTypeDescription } from "enums/VoucherTypeValidityType";

import { token } from "functions/Helper";
import MerchantVoucherCost from "./MerchantVoucherCost";
import RichTextComponent from "views/MerchantPromo/SlateFormV2";
import { deserializeHtmlString } from "functions/Helper";

const useStyles = makeStyles(styles);

export default function VoucherTypeDetail(props) {
  const source = useRef(new AbortController());

  let { id } = useParams();
  const [dayGroupList, setDayGroupList] = React.useState([]);
  const [sectorList, setSectorList] = React.useState([]);
  const [routeList, setRouteList] = React.useState([]);
  const [reload, setReload] = React.useState({});

  useEffect(() => {
    masterApi
      .get("/DayGroups", {
        signal: source.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1
          }
        }
      })
      .then(data => setDayGroupList(data.records))
      .catch(error => console.log(error));
    masterApi
      .get("/Sectors", {
        signal: source.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1
          }
        }
      })
      .then(data => setSectorList(data.records))
      .catch(error => console.log(error));
    masterApi
      .get("/Routes", {
        signal: source.current.signal,
        params: {
          pagination: {
            pageIndex: 0,
            pageSize: 0
          },
          filter: {
            searchString: null,
            publicationStatus: 1
          }
        }
      })
      .then(data => setRouteList(data.records))
      .catch(error => console.log(error));
    return () => source.current.abort();
  }, []);

  const [voucherType, setVoucherType] = React.useState(null);

  let defaultNewTrip = {
    description: "",
    sequence: 0,
    nonTicket: false,
    hasGST: false,
    isVTL: false,
    baseValue: 0.0,
    hasDayGroupRestriction: false,
    allowedDayGroupID: null,
    hasSectorRestriction: false,
    allowedSectorID: null,
    hasRouteRestriction: false,
    allowedRouteIDs: []
  };
  const [newTrip, setNewTrip] = React.useState(null);

  const handleDescriptionChanged = e =>
    setNewTrip({
      ...newTrip,
      description: e.target.value === "" ? null : e.target.value
    });

  const handleSequenceChanged = e =>
    setNewTrip({ ...newTrip, sequence: parseInt(e.target.value) });

  const handleNonTicketChanged = e =>
    setNewTrip({
      ...newTrip,
      nonTicket: e.target.checked
    });
  const handlehasGSTChanged = e =>
    setNewTrip({
      ...newTrip,
      hasGST: e.target.checked
    });
  const handleisVTLChanged = e =>
    setNewTrip({
      ...newTrip,
      isVTL: e.target.checked
    });
  const handleBaseValueChanged = e =>
    setNewTrip({
      ...newTrip,
      baseValue: e.target.value === "" ? null : e.target.value
    });
  const handleHasDayGroupRestrictionChanged = e =>
    setNewTrip({
      ...newTrip,
      hasDayGroupRestriction: e.target.checked,
      allowedDayGroupID: e.target.checked ? newTrip.allowedDayGroupID : null
    });

  const handleAllowedDayGroupIDChanged = e =>
    setNewTrip({ ...newTrip, allowedDayGroupID: e.target.value });

  const handleHasSectorRestrictionChanged = e =>
    setNewTrip({
      ...newTrip,
      hasSectorRestriction: e.target.checked,
      allowedSectorID: e.target.checked ? newTrip.allowedSectorID : null
    });
  const handleAllowedSectorIDChanged = e =>
    setNewTrip({ ...newTrip, allowedSectorID: e.target.value });

  const handleHasRouteRestrictionChanged = e =>
    setNewTrip({
      ...newTrip,
      hasRouteRestriction: e.target.checked,
      allowedRoutes: []
    });

  const handleAllowedRouteIDsChanged = e =>
    setNewTrip({ ...newTrip, allowedRouteIDs: e.target.value });

  const [disabled, setDisabled] = React.useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);
    voucherApi
      .post("/VoucherTypes/" + id + "/Trips", newTrip, {
        signal: source.current.signal
      })
      .then(() => {
        setNewTrip(null);
        setReload({});
      })
      .catch(error => console.log(error))
      .finally(() => setDisabled(false));
  };

  const classes = useStyles();

  useEffect(() => {
    voucherApi
      .get("/VoucherTypes/" + id, {
        signal: source.current.signal
      })
      .then(res =>
        setVoucherType({
          ...res,
          trips: res.trips.map(trip => {
            return (
              <GridItem md={6} lg={6} key={trip.id}>
                <Card>
                  <CardHeader color="rose" icon>
                    <h4 className={classes.cardIconTitle}>
                      {trip.description}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Sequence"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: trip.sequence
                      }}
                    />
                    <CustomInput
                      labelText="Base Value"
                      disabled
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: "SGD" + " " + trip.baseValue
                      }}
                    />
                    <GridItem>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={trip.nonTicket}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Non Ticket"
                      />
                    </GridItem>
                    <GridItem>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={trip.hasGST}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has GST"
                      />
                    </GridItem>
                    <GridItem>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={trip.isVTL}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Is VTL"
                      />
                    </GridItem>
                    <GridItem>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={trip.hasDayGroupRestriction}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has DayGroup Restriction"
                      />
                    </GridItem>
                    {trip.hasDayGroupRestriction && ( // render only if Has DayGroup Restriction
                      <GridItem>
                        <CustomInput
                          labelText="Allowed DayGroup"
                          disabled
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: trip.allowedDayGroup.name
                          }}
                        />
                      </GridItem>
                    )}
                    <GridItem>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={trip.hasSectorRestriction}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has Sector Restriction"
                      />
                    </GridItem>
                    {trip.hasSectorRestriction && ( // render only if Has Sector Restriction
                      <GridItem>
                        <CustomInput
                          labelText="Allowed Sector"
                          disabled
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: trip.allowedSector.name
                          }}
                        />
                      </GridItem>
                    )}
                    <GridItem>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={trip.hasRouteRestriction}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Has Route Restriction"
                      />
                    </GridItem>
                    {trip.hasRouteRestriction && ( // render only if Has Route Restriction
                      <GridItem>
                        <p>Allowed Routes: </p>
                        {trip.allowedRoutes.map(allowedRoute => {
                          return (
                            <MenuItem
                              key={allowedRoute.route.id}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={allowedRoute.id}
                            >
                              <b>{allowedRoute.route.code}</b>
                              <p>{allowedRoute.route.name}</p>
                            </MenuItem>
                          );
                        })}
                      </GridItem>
                    )}
                    {res.publicationStatus === 0 && (
                      <Button
                        onClick={() =>
                          voucherApi
                            .delete(
                              "/VoucherTypes/" + id + "/Trips/" + trip.id,
                              {
                                signal: source.current.signal
                              }
                            )
                            .then(() => setReload({}))
                            .catch(error => console.log(error))
                        }
                        color="rose"
                        type="button"
                        value="delete"
                      >
                        DELETE
                      </Button>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            );
          })
        })
      )
      .catch(error => console.log(error));
  }, [id, reload, classes]);

  return (
    voucherType !== null && (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <MailOutline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>VOUCHER TYPE</h4>
              </CardHeader>
              <CardBody>
                <Button onClick={() => props.history.goBack()} value="Back">
                  BACK
                </Button>

                <p>
                  <i>
                    *Voucher Type status is &apos;
                    {PublicationStatus(voucherType.publicationStatus)}&apos;.
                  </i>
                </p>
                {voucherType.publicationStatus === 0 ? (
                  <>
                    {(token["voucher.voucher_types.publish"] === "allowed" ||
                      token.is_admin === "True") && (
                      <Link to={"/admin/VoucherType/Publish/" + voucherType.id}>
                        <Button color="rose" value="Publish">
                          PUBLISH
                        </Button>
                      </Link>
                    )}
                    {(token["voucher.voucher_types.write"] === "allowed" ||
                      token.is_admin === "True") && (
                      <Link to={"/admin/VoucherType/Delete/" + voucherType.id}>
                        <Button color="rose" value="Delete">
                          DELETE
                        </Button>
                      </Link>
                    )}
                    {(token["voucher.voucher_types.write"] === "allowed" ||
                      token.is_admin === "True") && (
                      <Link to={"/admin/VoucherType/Edit/" + id}>
                        <Button color="rose" type="submit" value="Edit">
                          EDIT
                        </Button>
                      </Link>
                    )}
                  </>
                ) : (
                  <p>
                    <i>
                      *Only &apos;InDraft&apos; Voucher Type can be edited,
                      published or deleted.
                    </i>
                  </p>
                )}
                <CustomInput
                  labelText="Code"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: voucherType.code
                  }}
                />

                <CustomInput
                  labelText="Name"
                  disabled
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: voucherType.name
                  }}
                />
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      disabled
                      labelText="Validity"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        type: "number",
                        value: voucherType.validity
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      disabled
                      labelText="Validity Type"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: GetVoucherTypeValidityTypeDescription(
                          voucherType.validityType
                        )
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <InputLabel
                      htmlFor="selValidityType"
                      className={classes.selectLabel}
                    >
                      Information
                    </InputLabel>
                  </GridItem>
                  <GridItem xs={12} md={12}>
                    <div
                      style={{
                        borderBottom: "1px solid #d6d6d6",
                        marginTop: 12
                      }}
                    >
                      <RichTextComponent
                        onChange={() => {}}
                        disable={true}
                        value={
                          voucherType.information != null
                            ? deserializeHtmlString(voucherType.information)
                            : null
                        }
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <div className={classes.checkboxAndRadio}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={3}>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={voucherType.includeSurcharge}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Include Surcharge"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={voucherType.includePDF}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Include PDF"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={voucherType.includeTF}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Include TF"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={voucherType.includeCF}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Include CF"
                      />
                    </GridItem>
                  </GridContainer>
                </div>
                <div className={classes.checkboxAndRadio}>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={voucherType.hasPaxTypeRestriction}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has PaxType Restriction"
                        />
                      </GridItem>
                      {voucherType.hasPaxTypeRestriction && ( // render only if Has PaxType Restriction
                        <GridItem>
                          <CustomInput
                            disabled
                            labelText="Allowed PaxType"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: voucherType.allowedPaxType.name
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={voucherType.hasNationalityRestriction}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has Nationality Restriction"
                        />
                      </GridItem>
                      {voucherType.hasNationalityRestriction && ( // render only if Has PaxType Restriction
                        <GridItem>
                          <CustomInput
                            disabled
                            labelText="Allowed Nationality"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: voucherType.allowedNationality.nationality
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                  </GridContainer>
                </div>
                <div className={classes.checkboxAndRadio}>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={
                                voucherType.hasIdentificationNoRestriction
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has IdentificationNo Restriction"
                        />
                      </GridItem>
                      {voucherType.hasIdentificationNoRestriction && (
                        <GridItem>
                          <CustomInput
                            disabled
                            labelText="MaxAllowedIdentificationNoCount"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: voucherType.maxAllowedIdentificationNoCount
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={
                                voucherType.prohibitToUseOnInterliningTrips
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Prohibit to Use On Interlining Trips"
                        />
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                </div>
                <div className={classes.checkboxAndRadio}>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={
                                voucherType.hasMinimumIssuanceQuantityRestriction
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has Minimum Issuance Quantity Restriction"
                        />
                      </GridItem>
                      {voucherType.hasMinimumIssuanceQuantityRestriction && (
                        <GridItem>
                          <CustomInput
                            disabled
                            labelText="Minimum Issuance Quantity"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: voucherType.minimumIssuanceQuantity
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <GridItem>
                        <FormControlLabel
                          disabled
                          control={
                            <Checkbox
                              checked={
                                voucherType.hasMaximumIssuanceQuantityRestriction
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has Maximum Issuance Quantity Restriction"
                        />
                      </GridItem>
                      {voucherType.hasMaximumIssuanceQuantityRestriction && (
                        <GridItem>
                          <CustomInput
                            disabled
                            labelText="Maximum Issuance Quantity"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: voucherType.maximumIssuanceQuantity
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                  </GridContainer>
                </div>
                <div className={classes.checkboxAndRadio}>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <GridItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled
                              checked={voucherType.hasMerchant}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Has Merchant"
                        />
                      </GridItem>
                      {voucherType.hasMerchant && (
                        <GridItem>
                          <CustomInput
                            labelText="Merchant"
                            disabled
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: voucherType.merchant.name
                            }}
                          />
                        </GridItem>
                      )}
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/* Merchant */}

        {voucherType &&
          voucherType.publicationStatus === 1 &&
          voucherType.hasMerchant && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MerchantVoucherCost id={id} />
              </GridItem>
            </GridContainer>
          )}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <MailOutline />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>TRIPS</h4>
              </CardHeader>
              <CardBody>
                {voucherType.publicationStatus === 0 &&
                  (newTrip === null ? (
                    <Button
                      onClick={() => setNewTrip(defaultNewTrip)}
                      color="rose"
                      type="submit"
                      value="Submit"
                    >
                      Add Trip
                    </Button>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <GridItem>
                        <CustomInput
                          labelText="Sequence"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: handleSequenceChanged,
                            value: newTrip.sequence
                          }}
                        />
                        <CustomInput
                          labelText="Description"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: handleDescriptionChanged,
                            value:
                              newTrip.description === null
                                ? ""
                                : newTrip.description
                          }}
                        />
                        <CustomInput
                          labelText="Base Value"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: handleBaseValueChanged,
                            value:
                              newTrip.baseValue === null ? 0 : newTrip.baseValue
                          }}
                        />
                        <GridContainer>
                          <GridItem xs={4} sm={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={newTrip.nonTicket}
                                  onChange={handleNonTicketChanged}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                                root: classes.labelRoot
                              }}
                              label="Non Ticket"
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={newTrip.hasGST}
                                  onChange={handlehasGSTChanged}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                                root: classes.labelRoot
                              }}
                              label="Has GST"
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={newTrip.isVTL}
                                  onChange={handleisVTLChanged}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                                root: classes.labelRoot
                              }}
                              label="Is VTL"
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={4}>
                            <GridItem>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={newTrip.hasDayGroupRestriction}
                                    onChange={
                                      handleHasDayGroupRestrictionChanged
                                    }
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot
                                }}
                                label="Has DayGroup Restriction"
                              />
                            </GridItem>
                            {newTrip.hasDayGroupRestriction && ( // render only if Has PaxType Restriction
                              <GridItem>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="selDayGroup"
                                    className={classes.selectLabel}
                                  >
                                    Allowed DayGroup
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu
                                    }}
                                    classes={{
                                      select: classes.select
                                    }}
                                    value={
                                      newTrip.allowedDayGroupID === null
                                        ? ""
                                        : newTrip.allowedDayGroupID
                                    }
                                    onChange={handleAllowedDayGroupIDChanged}
                                  >
                                    {dayGroupList.map(record => {
                                      return (
                                        <MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelected
                                          }}
                                          value={record.id}
                                          key={record.id}
                                        >
                                          <b>{record.name}</b>
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            )}
                          </GridItem>

                          <GridItem xs={12} sm={4}>
                            <GridItem>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={newTrip.hasSectorRestriction}
                                    onChange={handleHasSectorRestrictionChanged}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot
                                }}
                                label="Has Sector Restriction"
                              />
                            </GridItem>
                            {newTrip.hasSectorRestriction && ( // render only if Has PaxType Restriction
                              <GridItem>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="selSector"
                                    className={classes.selectLabel}
                                  >
                                    Allowed Sector
                                  </InputLabel>
                                  <Select
                                    MenuProps={{
                                      className: classes.selectMenu
                                    }}
                                    classes={{
                                      select: classes.select
                                    }}
                                    value={
                                      newTrip.allowedSectorID === null
                                        ? ""
                                        : newTrip.allowedSectorID
                                    }
                                    onChange={handleAllowedSectorIDChanged}
                                  >
                                    {sectorList.map(record => {
                                      return (
                                        <MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelected
                                          }}
                                          value={record.id}
                                          key={record.id}
                                        >
                                          <b>{record.name}</b>
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            )}
                          </GridItem>

                          <GridItem xs={12} sm={4}>
                            <GridItem>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={newTrip.hasRouteRestriction}
                                    onChange={handleHasRouteRestrictionChanged}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot
                                }}
                                label="Has Route Restriction"
                              />
                            </GridItem>
                            {newTrip.hasRouteRestriction && ( // render only if Has PaxType Restriction
                              <GridItem>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <InputLabel
                                    htmlFor="selAllowedRoutes"
                                    className={classes.selectLabel}
                                  >
                                    Allowed Routes
                                  </InputLabel>
                                  <Select
                                    multiple
                                    MenuProps={{
                                      className: classes.selectMenu
                                    }}
                                    classes={{
                                      select: classes.select
                                    }}
                                    value={newTrip.allowedRouteIDs}
                                    onChange={handleAllowedRouteIDsChanged}
                                    renderValue={() => (
                                      <div className={classes.chips}>
                                        {newTrip.allowedRouteIDs.map(value => {
                                          let route = routeList.find(
                                            item => item.id === value
                                          );
                                          return (
                                            <div
                                              key={value}
                                              className={classes.chips}
                                            >
                                              <Chip
                                                key={route.id}
                                                label={<b>{route.name}</b>}
                                                className={classes.chip}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  >
                                    {routeList.map(record => {
                                      let route = routeList.find(
                                        item => item.id === record.id
                                      );
                                      return (
                                        <MenuItem
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected:
                                              classes.selectMenuItemSelectedMultiple
                                          }}
                                          value={record.id}
                                          key={record.id}
                                        >
                                          <b>{route.code}</b> <br />
                                          {route.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </GridItem>
                            )}
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <Button
                        disabled={disabled}
                        color="rose"
                        type="submit"
                        value="Submit"
                      >
                        Add
                      </Button>
                    </form>
                  ))}
                <GridContainer>{voucherType.trips}</GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    )
  );
}

VoucherTypeDetail.propTypes = {
  history: PropTypes.object
};
