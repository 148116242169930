import React from "react";
import PropTypes from "prop-types";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import { formatDateTime } from "functions/Helper";

export default function ViewExchangeRate(props) {
  const { exchangeRate } = props;
  return (
    <>
      <CustomInput
        labelText="Currency"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: exchangeRate.currency.code
        }}
      />
      <CustomInput
        labelText="Time"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: formatDateTime(new Date(exchangeRate.time + "Z"))
        }}
      />
      <CustomInput
        labelText="Rate"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: exchangeRate.rate
        }}
      />
      <CustomInput
        labelText="Created By"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: exchangeRate.createdBy
        }}
      />
      <CustomInput
        labelText="Created At"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          disabled: true,
          value: formatDateTime(new Date(exchangeRate.createdAt + "Z"))
        }}
      />
    </>
  );
}

ViewExchangeRate.propTypes = {
  exchangeRate: PropTypes.object
};
