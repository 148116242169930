import { MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import "react-datetime/css/react-datetime.css";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { masterApi } from "functions/apis";
import { orderApi } from "functions/apis";
import { Autocomplete } from "@material-ui/lab";
import { voucherApi } from "functions/apis";

const useStyles = makeStyles(styles);

export default function CreateOnlineVoucherType(props) {
  const [redirect, setRedirect] = useState(false);
  const [state, setState] = useState({
    voucherTypeID: null,
    currencyID: null,
    totalPrice: 0,
    effectiveDate: null,
    expiryDate: null,
    trips: []
  });
  const [currencyList, setCurrencyList] = useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [voucherTypeList, setVoucherTypeList] = React.useState([]);
  const abortController = React.useRef(new AbortController());

  useEffect(() => {
    orderApi
      .get(`/VoucherTypes`, {
        signal: abortController.current.signal,
        params: {
          filter: {
            searchString: null,
            sort: 0
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setVoucherTypeList(data.records))
      .catch(error => console.log(error));
    masterApi
      .get("/Currencies", {
        signal: abortController.current.signal,
        params: {
          filter: {
            publicationStatus: 1
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCurrencyList(data.records))
      .catch(error => console.log(error));

    return () => abortController.current.abort();
  }, []);

  const handleEffectiveDateChanged = e =>
    setState({
      ...state,
      effectiveDate: e === "" ? null : e
    });

  const handleExpiryDateChanged = e =>
    setState({
      ...state,
      expiryDate: e === "" ? null : e
    });

  const handleCurrencyIDChange = e =>
    setState({
      ...state,
      currencyID: e.target.value === "" ? null : e.target.value
    });

  const handleVoucherTypeChanged = (e, v) => {
    if (v === null) {
      setState({
        ...state,
        voucherTypeID: null,
        trips: []
      });
      return;
    }
    if (state.voucherTypeID !== v.id)
      voucherApi
        .get("/VoucherTypes/" + v.id, {
          signal: abortController.current.signal
        })
        .then(data => {
          setState({
            ...state,
            voucherTypeID: v.id,
            trips: data.trips.map(trip => {
              return {
                description: trip.description,
                voucherTypeTripID: trip.id,
                pricing: {
                  ticket: 0,
                  pdf: 0,
                  terminalFee: 0,
                  surcharge: 0,
                  confirmationFee: 0,
                  vtlFee: 0
                }
              };
            })
          });
        })
        .catch(error => console.log(error));
  };

  const handleSubmit = () => {
    let totalPrice = 0;
    state.trips.map(x => {
      totalPrice +=
        x.pricing.ticket +
        x.pricing.pdf +
        x.pricing.terminalFee +
        x.pricing.surcharge +
        x.pricing.confirmationFee +
        x.pricing.vtlFee;
    });
    setDisabled(true);
    var body = { ...state };

    orderApi
      .post(
        "/onlineVoucherTypes",
        { ...body, totalPrice: totalPrice },
        {
          signal: abortController.current.signal
        }
      )
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  const classes = useStyles();

  return redirect ? (
    <Redirect to={"/admin/OnlineVoucherType/List"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              CREATE ONLINE VOUCHER TYPES
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <GridContainer>
              <GridItem xs={12} md={12}>
                <Autocomplete
                  id="voucherType"
                  onChange={handleVoucherTypeChanged}
                  options={voucherTypeList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={option => option.name}
                  renderOption={option => (
                    <React.Fragment>
                      <b>{option.code}</b> - {option.name}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Voucher Type"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps
                      }}
                    />
                  )}
                />
              </GridItem>
              <GridItem xs={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCurrency"
                    className={classes.selectLabel}
                  >
                    Currency
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={state.currencyID === null ? "" : state.currencyID}
                    onChange={handleCurrencyIDChange}
                  >
                    {currencyList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.id}
                          key={record.id}
                        >
                          <b>{record.code}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={6} md={6}>
                <FormControl fullWidth>
                  <Datetime
                    utc
                    value={state.effectiveDate}
                    onChange={handleEffectiveDateChanged}
                    timeFormat={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Effective Date"
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} md={6}>
                <FormControl fullWidth>
                  <Datetime
                    utc
                    value={state.expiryDate}
                    onChange={handleExpiryDateChanged}
                    timeFormat={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Expiry Date"
                    }}
                  />
                </FormControl>
              </GridItem>
              {state.trips.map(record => {
                return (
                  <GridItem key={record.id} xs={12}>
                    <GridContainer key={record.voucherTypeTripID}>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Trip"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: record.description
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Ticket"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.ticket = e.target.value;
                              else
                                newTrips[index].pricing.ticket = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.ticket
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="PDF"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.pdf = e.target.value;
                              else
                                newTrips[index].pricing.pdf = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.pdf
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Terminal Fee"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.terminalFee =
                                  e.target.value;
                              else
                                newTrips[
                                  index
                                ].pricing.terminalFee = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.terminalFee
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Surcharge"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.surcharge =
                                  e.target.value;
                              else
                                newTrips[index].pricing.surcharge = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.surcharge
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Confirmation Fee"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.confirmationFee =
                                  e.target.value;
                              else
                                newTrips[
                                  index
                                ].pricing.confirmationFee = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.confirmationFee
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="VTL Fee"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            onChange: e => {
                              let index = state.trips.findIndex(
                                t =>
                                  t.voucherTypeTripID ===
                                  record.voucherTypeTripID
                              );
                              let newTrips = state.trips;
                              let val = parseFloat(e.target.value);
                              if (isNaN(val))
                                newTrips[index].pricing.vtlFee = e.target.value;
                              else
                                newTrips[index].pricing.vtlFee = parseFloat(
                                  e.target.value
                                );
                              setState({
                                ...state,
                                trips: newTrips
                              });
                            },
                            value: record.pricing.vtlFee
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText={<b>Total</b>}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            disabled: true,
                            value:
                              record.pricing.ticket +
                              record.pricing.pdf +
                              record.pricing.terminalFee +
                              record.pricing.surcharge +
                              record.pricing.confirmationFee +
                              record.pricing.vtlFee
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                );
              })}
              <GridItem xs={12} md={12}>
                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateOnlineVoucherType.propTypes = {
  history: PropTypes.object
};
