import React from "react";
import PropTypes from "prop-types";

import CustomInput from "components/CustomInput/CustomInput.js";

export default function ViewPredefinedRole(props) {
  const { predefinedRole } = props;
  return (
    <CustomInput
      labelText="Name"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        disabled: true,
        value: predefinedRole.name === null ? "" : predefinedRole.name
      }}
    />
  );
}

ViewPredefinedRole.propTypes = {
  predefinedRole: PropTypes.object
};
