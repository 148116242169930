import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { bookingApi } from "functions/apis";
import { useParams } from "react-router-dom";
import CustomInput from "components/CustomInput/CustomInput";
import ListUser from "./ListUser";
import ListAvailableUserList from "./ListAvailableUserList";
import { GetAppNotificationTypeDescription } from "enums/AppsNotificationType";

const useStyles = makeStyles(styles);

const ViewAppsNotification = props => {
  const classes = useStyles();

  const [input, setInput] = useState({
    appsNotificationID: "",
    type: null,
    status: null,
    users: [],
    appsNotificationData: null
  });
  const [reload, setReload] = React.useState({});
  const reloadHandler = () => {
    setReload({});
  };

  const { id } = useParams();

  const viewSendAppsNotificationSignal = useRef(new AbortController());

  useEffect(() => {
    return () => viewSendAppsNotificationSignal.current.abort();
  }, []);

  useEffect(() => {
    if (id) {
      bookingApi
        .get("SendAppsNotificationTransactions/" + id, {
          signal: viewSendAppsNotificationSignal.current.signal
        })
        .then(resp => {
          setInput({
            ...input,
            appsNotificationID: resp.appsNotification.name,
            status: resp.status,
            type: resp.type,
            appsNotificationData: resp.appsNotification
          });
        });
    }
  }, [id]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              View Send Apps Notification
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form>
              <CustomInput
                labelText="Notification Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: input.appsNotificationData
                    ? input.appsNotificationData.name
                    : "",
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Notification Title"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: input.appsNotificationData
                    ? input.appsNotificationData.title
                    : "",
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Notification Description"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: input.appsNotificationData
                    ? input.appsNotificationData.description
                    : "",
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Notification Type"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value:
                    input.type != null
                      ? GetAppNotificationTypeDescription(input.type)
                      : "",
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Notification Section"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value:
                    input.appsNotificationData &&
                    input.appsNotificationData.notificationSection
                      ? input.appsNotificationData.notificationSection.name
                      : "",
                  disabled: true
                }}
              />
              <CustomInput
                labelText="URL"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: input.appsNotificationData
                    ? input.appsNotificationData.url
                    : "",
                  disabled: true
                }}
              />
              <CustomInput
                labelText="Notification Section"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value:
                    input.appsNotificationData &&
                    input.appsNotificationData.notificationSection
                      ? input.appsNotificationData.notificationSection.name
                      : "",
                  disabled: true
                }}
              />
            </form>
          </CardBody>
        </Card>
        {input.type != 0 && input.type && (
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListUser
                id={id}
                reload={reload}
                reloadHandler={reloadHandler}
                disabled={true}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <ListAvailableUserList
                id={id}
                reload={reload}
                reloadHandler={reloadHandler}
                disabled={true}
              />
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default ViewAppsNotification;

ViewAppsNotification.propTypes = {
  history: PropTypes.object
};
