import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import CreateMerchantInventories from "views/MerchantInventories/CreateMerchantInventories";
import MerchantInventoriesList from "views/MerchantInventories/MerchantInventoriesList";
import ViewMerchantInventories from "views/MerchantInventories/ViewMerchantInventories";

const merchantInventories = () => {
  let views = [];

  views.push({
    path: "/MerchantInventories/List",
    name: "M Inventories",
    icon: ConfirmationNumberIcon,
    component: MerchantInventoriesList,
    layout: "/admin"
  });

  return views;
};

const merchantInventoriesHidden = () => {
  let views = [];

  views = views.concat([
    {
      path: "/MerchantInventories/Create",
      name: "Create Merchant Inventories",
      component: CreateMerchantInventories,
      layout: "/admin"
    },
    {
      path: "/MerchantInventories/Detail",
      name: "View Merchant Inventories",
      component: ViewMerchantInventories,
      layout: "/admin",
      params: [
        {
          name: "id"
        }
      ]
    }
  ]);

  return [
    {
      collapse: true,
      name: "Merchant Inventories Hidden",
      state: "merchantInventoriesHidden",
      hideInSidebar: true,
      views
    }
  ];
};

export { merchantInventories, merchantInventoriesHidden };
