import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { GetAgentBookingTransferStatusDescription } from "enums/AgentBookingTransferStatus.js";
import { formatDateTime } from "functions/Helper";
import moment from "moment";

const useStyles = makeStyles(styles);

export default function View(props) {
  const { agentBookingTransfer, history } = props;
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              AGENT BOOKING TRANSFER DETAILS
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => history.goBack()} value="Back">
              BACK
            </Button>
            <CustomInput
              labelText="Transfer No"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: agentBookingTransfer.no
              }}
            />
            <CustomInput
              labelText="Agent"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: agentBookingTransfer.agent.name
              }}
            />
            <CustomInput
              labelText="Booking No"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: agentBookingTransfer.booking.no
              }}
            />
            <CustomInput
              labelText="Confirmation Code"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: agentBookingTransfer.booking.confirmationCode
              }}
            />
            {agentBookingTransfer.status === 2 && (
              <CustomInput
                labelText="Revision"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: agentBookingTransfer.revision
                }}
              />
            )}
            <FormControlLabel
              disabled
              control={
                <Checkbox
                  checked={agentBookingTransfer.transferDepartureTrip}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label="Transfer Departure Trip"
            />
            <br />
            {agentBookingTransfer.transferDepartureTrip && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Old Departure Route"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          agentBookingTransfer.oldDepartureCoreApiTrip.route
                            .name
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Old Departure Date"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: moment(
                          agentBookingTransfer.oldDepartureCoreApiTrip.date
                        ).format("dddd, MMMM Do YYYY")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="Old Departure Time"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: `${agentBookingTransfer.oldDepartureCoreApiTrip.time.substring(
                          0,
                          2
                        )}:${agentBookingTransfer.oldDepartureCoreApiTrip.time.substring(
                          2,
                          4
                        )}`
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="New Departure Route"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          agentBookingTransfer.departureCoreApiTrip.route.name
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="New Departure Date"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: moment(
                          agentBookingTransfer.departureCoreApiTrip.date
                        ).format("dddd, MMMM Do YYYY")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                      labelText="New Departure Time"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: `${agentBookingTransfer.departureCoreApiTrip.time.substring(
                          0,
                          2
                        )}:${agentBookingTransfer.departureCoreApiTrip.time.substring(
                          2,
                          4
                        )}`
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </>
            )}
            {agentBookingTransfer.booking.isRoundTrip && (
              <>
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      checked={agentBookingTransfer.transferReturnTrip}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Transfer Return Trip"
                />
                <br />
                {agentBookingTransfer.transferReturnTrip && (
                  <>
                    {agentBookingTransfer.oldIsReturnTripOpen ? (
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={agentBookingTransfer.oldIsReturnTripOpen}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Old Open Return Trip"
                      />
                    ) : (
                      <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            labelText="Old Return Route"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value:
                                agentBookingTransfer.oldReturnCoreApiTrip.route
                                  .name
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            labelText="Old Return Date"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: moment(
                                agentBookingTransfer.oldReturnCoreApiTrip.date
                              ).format("dddd, MMMM Do YYYY")
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            labelText="Old Return Time"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: `${agentBookingTransfer.oldReturnCoreApiTrip.time.substring(
                                0,
                                2
                              )}:${agentBookingTransfer.oldReturnCoreApiTrip.time.substring(
                                2,
                                4
                              )}`
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    )}
                    {agentBookingTransfer.isReturnTripOpen ? (
                      <FormControlLabel
                        disabled
                        control={
                          <Checkbox
                            checked={agentBookingTransfer.isReturnTripOpen}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="New Open Return Trip"
                      />
                    ) : (
                      <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            labelText="New Return Route"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value:
                                agentBookingTransfer.returnCoreApiTrip.route
                                  .name
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            labelText="New Return Date"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: moment(
                                agentBookingTransfer.returnCoreApiTrip.date
                              ).format("dddd, MMMM Do YYYY")
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                          <CustomInput
                            labelText="New Return Time"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: true,
                              value: `${agentBookingTransfer.returnCoreApiTrip.time.substring(
                                0,
                                2
                              )}:${agentBookingTransfer.returnCoreApiTrip.time.substring(
                                2,
                                4
                              )}`
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                    )}
                  </>
                )}
              </>
            )}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Currency"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: agentBookingTransfer.agent.billingCurrency.code
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Administration Fee"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: agentBookingTransfer.transferFee.toLocaleString()
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Outstanding Amount"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: agentBookingTransfer.outstandingAmount.toLocaleString()
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Pax(es) Transfer Fee"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: agentBookingTransfer.paxesTransferFee.toLocaleString()
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3}>
                <CustomInput
                  labelText="Total"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: agentBookingTransfer.total.toLocaleString()
                  }}
                />
              </GridItem>
            </GridContainer>
            <CustomInput
              labelText="Status"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disabled: true,
                value: GetAgentBookingTransferStatusDescription(
                  agentBookingTransfer.status
                )
              }}
            />
            {agentBookingTransfer.status === 0 && (
              <CustomInput
                labelText="Created At"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  value: formatDateTime(
                    new Date(agentBookingTransfer.transactionTime + "Z")
                  )
                }}
              />
            )}
            {agentBookingTransfer.status === 1 && (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Submitted At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(agentBookingTransfer.submittedAt + "Z")
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Submitted By"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: agentBookingTransfer.submittedBy
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
            {agentBookingTransfer.status === 2 && (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Approved At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(agentBookingTransfer.approvedAt + "Z")
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Approved By"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: agentBookingTransfer.approvedBy
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
            {agentBookingTransfer.status === 3 && (
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Rejected At"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: formatDateTime(
                        new Date(agentBookingTransfer.rejectedAt + "Z")
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Rejected By"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: agentBookingTransfer.rejectedBy
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    labelText="Rejected Reason"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: agentBookingTransfer.rejectedReason
                    }}
                  />
                </GridItem>
              </GridContainer>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

View.propTypes = {
  agentBookingTransfer: PropTypes.object,
  history: PropTypes.object,
  currency: PropTypes.string
};
