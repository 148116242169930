import React, { useEffect } from "react";
import { MailOutline } from "@material-ui/icons";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import CardBody from "components/Card/CardBody";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  makeStyles
} from "@material-ui/core";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import Datetime from "react-datetime";
import MerchantDialog from "components/MerchantDialog/MerchantDialog";
import { bookingApi } from "functions/apis";
import { Redirect } from "react-router-dom";
import RichTextComponent from "./SlateFormV2";
import { deserializeHtmlString } from "functions/Helper";

const useStyles = makeStyles(styles);

const emptyBody = {
  categoryID: "",
  merchantID: "",
  effectiveDate: null,
  expiryDate: null,
  type: null,
  generationEvent: null,
  title: "",
  description: "",
  termsAndConditions: "",
  usagePerUser: 0,
  limit: 0,
  claimableLimit: 0,
  banner: "",
  usagePeriodCalculationType: null,
  usageEffectiveDate: null,
  usageExpiryDate: null,
  usageValidityType: null,
  usageValidity: 0
};

const merchantPromoType = ["Claimable", "System Generated"];

const merchantPromoValidityType = ["Month", "Week", "Day"];

const merchantPromoGenerationEvent = ["New User", "First Booking", "Birthday"];

const usagePeriodCalculation = ["By ClaimDate", "Fixed"];

export default function CreateMerchantPromo(props) {
  const classes = useStyles();
  const [input, setInput] = React.useState({ ...emptyBody });
  const source = React.useRef(new AbortController());
  const [showModal, setShowModal] = React.useState(false);
  const [merchantName, setMerchantName] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [merchantID, setMerchantID] = React.useState(null);

  useEffect(() => {
    bookingApi
      .get("/MerchantPromoCategories", {
        signal: source.current.signal,
        params: {
          filter: {
            searchString: null
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCategoryList(data.records))
      .catch(error => console.log(error));
    return () => source.current.abort();
  }, []);

  const [categoryList, setCategoryList] = React.useState([]);

  const handleTitleChanged = e => {
    setInput({
      ...input,
      title: e.target.value === "" ? null : e.target.value
    });
  };

  const handleDescriptionChanged = e => {
    setInput({
      ...input,
      description: e.target.value === "" ? null : e.target.value
    });
  };

  const handleBannerChanged = e => {
    setInput({
      ...input,
      banner: e.target.value === "" ? null : e.target.value
    });
  };

  const handleLimitChanged = e => {
    setInput({
      ...input,
      limit: e.target.value === "" ? null : e.target.value
    });
  };

  const handleClaimableLimitChanged = e => {
    setInput({
      ...input,
      claimableLimit: e.target.value === "" ? null : e.target.value
    });
  };

  const handleUsagePerUserChanged = e =>
    setInput({
      ...input,
      usagePerUser: e.target.value === "" ? null : e.target.value
    });

  const handleEffectiveDateChanged = e =>
    setInput({
      ...input,
      effectiveDate: e === "" ? null : e
    });

  const handleExpiryDateChanged = e =>
    setInput({
      ...input,
      expiryDate: e === "" ? null : e
    });

  const handleUsageEffectiveDateChanged = e =>
    setInput({
      ...input,
      usageEffectiveDate: e === "" ? null : e
    });

  const handleUsageExpiryDateChanged = e =>
    setInput({
      ...input,
      usageExpiryDate: e === "" ? null : e
    });

  const handleCategoryIDChanged = e =>
    setInput({
      ...input,
      categoryID: e.target.value === "" ? null : e.target.value
    });

  const handleTermsAndConditionsChanged = e => {
    setInput({
      ...input,
      termsAndConditions: e === "" ? null : e
    });
  };

  const handleUsagePeriodCalculationTypeChanged = e =>
    setInput({
      ...input,
      usagePeriodCalculationType: e.target.value === "" ? null : e.target.value
    });

  const handleValidityTypeChanged = e =>
    setInput({
      ...input,
      usageValidityType: e.target.value === "" ? null : e.target.value
    });

  const handleUsageValidityChanged = e => {
    setInput({
      ...input,
      usageValidity: e.target.value === "" ? null : e.target.value
    });
  };

  const handleTypeChanged = e =>
    setInput({
      ...input,
      type: e.target.value === "" ? null : e.target.value
    });

  const handleGenerationEventChanged = e =>
    setInput({
      ...input,
      generationEvent: e.target.value === "" ? null : e.target.value
    });

  const handleMerchantIDChange = e => {
    setMerchantID(e.id);
    setMerchantName(e.name);
    setShowModal(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);

    bookingApi
      .post(
        "MerchantPromos",
        {
          ...input,
          merchantID: merchantID
        },
        {
          signal: source.current.signal
        }
      )
      .then(() => {
        setRedirect(true);
      })
      .catch(error => {
        console.log(error);
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/MerchantPromo/List"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Create Merchant Promo</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Title"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleTitleChanged,
                  value: input.title === null ? "" : input.title
                }}
              />
              <CustomInput
                labelText="Description"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleDescriptionChanged,
                  value: input.description === null ? "" : input.description
                }}
              />

              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCategory"
                  className={classes.selectLabel}
                >
                  Category
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={input.categoryID === null ? "" : input.categoryID}
                  onChange={handleCategoryIDChanged}
                >
                  {categoryList.map(record => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={record.id}
                        key={record.id}
                      >
                        <b>{record.name}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selAgent"
                      className={classes.selectLabel}
                    >
                      Merchant
                    </InputLabel>
                    <Input
                      classes={{
                        input: classes.input,
                        disabled: classes.active,
                        root: classes.active,
                        underline: classes.underline
                      }}
                      value={merchantName ?? ""}
                      onClick={() => setShowModal(true)}
                      fullWidth
                    />
                  </FormControl>
                  <MerchantDialog
                    openModal={showModal}
                    setOpenModal={val => setShowModal(val)}
                    onSelect={handleMerchantIDChange}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <p>Terms And Conditions</p>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridItem xs={12} md={12}>
                <div style={{ borderBottom: "1px solid #d6d6d6" }}>
                  <RichTextComponent
                    onChange={handleTermsAndConditionsChanged}
                    isCreate={true}
                    value={
                      input.termsAndConditions != null
                        ? deserializeHtmlString(input.termsAndConditions)
                        : null
                    }
                  />
                </div>
              </GridItem>
              <CustomInput
                labelText="Banner"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: handleBannerChanged,
                  value: input.banner === null ? "" : input.banner
                }}
              />
              <CustomInput
                labelText="Limit"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: handleClaimableLimitChanged,
                  value:
                    input.claimableLimit === null ? "" : input.claimableLimit
                }}
              />
              <CustomInput
                labelText="Claimable Limit"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: handleLimitChanged,
                  value: input.limit === null ? "" : input.limit
                }}
              />
              <CustomInput
                labelText="Usage per user"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: handleUsagePerUserChanged,
                  value: input.usagePerUser === null ? "" : input.usagePerUser
                }}
              />

              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={input.effectiveDate}
                      onChange={handleEffectiveDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Effective Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={input.expiryDate}
                      onChange={handleExpiryDateChanged}
                      timeFormat={true}
                      closeOnSelect={true}
                      inputProps={{
                        placeholder: "Expiry Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selCategory"
                      className={classes.selectLabel}
                    >
                      Usage Period Calculation Type
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={
                        input.usagePeriodCalculationType === null
                          ? ""
                          : input.usagePeriodCalculationType
                      }
                      onChange={handleUsagePeriodCalculationTypeChanged}
                    >
                      {usagePeriodCalculation.map((record, key) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={key}
                            key={key}
                          >
                            <b>{record}</b>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              {typeof input?.usagePeriodCalculationType === "number" &&
              input.usagePeriodCalculationType === 0 ? (
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="selCategory"
                        className={classes.selectLabel}
                      >
                        Validity Type
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={
                          input.usageValidityType === null
                            ? ""
                            : input.usageValidityType
                        }
                        onChange={handleValidityTypeChanged}
                      >
                        {merchantPromoValidityType.map((record, key) => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={key}
                              key={key}
                            >
                              <b>{record}</b>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      labelText="Validity"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: handleUsageValidityChanged,
                        value:
                          input.usageValidity === null
                            ? ""
                            : input.usageValidity
                      }}
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                input.usagePeriodCalculationType &&
                input.usagePeriodCalculationType === 1 && (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <Datetime
                          value={input.usageEffectiveDate}
                          onChange={handleUsageEffectiveDateChanged}
                          timeFormat={true}
                          closeOnSelect={true}
                          inputProps={{
                            placeholder: "Usage Effective Date"
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <Datetime
                          value={input.usageExpiryDate}
                          onChange={handleUsageExpiryDateChanged}
                          timeFormat={true}
                          closeOnSelect={true}
                          inputProps={{
                            placeholder: "Usage Expiry Date"
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                )
              )}
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="selCategory"
                  className={classes.selectLabel}
                >
                  Type
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={input.type === null ? "" : input.type}
                  onChange={handleTypeChanged}
                >
                  {merchantPromoType.map((record, key) => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={key}
                        key={key}
                      >
                        <b>{record}</b>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {input.type === 1 && (
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCategory"
                    className={classes.selectLabel}
                  >
                    Generation Event
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={
                      input.generationEvent === null
                        ? ""
                        : input.generationEvent
                    }
                    onChange={handleGenerationEventChanged}
                  >
                    {merchantPromoGenerationEvent.map((record, key) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={key}
                          key={key}
                        >
                          <b>{record}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CreateMerchantPromo.propTypes = {
  history: PropTypes.object
};
